import React, { useEffect, useState, useRef } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import chat from "../assets/images/chatpost.png";
import axios from "axios";
import { AlertDelete } from "../components/AlertDelete";
import image1 from "../assets/images/image1.jfif";
import image2 from "../assets/images/image2.jfif";
import image3 from "../assets/images/image4.jfif";
import image4 from "../assets/images/image5.jfif";
import image5 from "../assets/images/angular.jpg";
import image6 from "../assets/images/image3.png";
export const GroupPostComment = ({
  isGrouppost,
  LoginData,
  Isgroupfav,
  commentSectionRefresh,
  getpostCommentrefresh,
}) => {
  const apiUrl = "https://backend.amourette.no/api/groups/";
  const commentRefs = useRef([]); // Ref to manage scroll to the last comment
  const [commentInputs, setCommentInputs] = useState(
    Array(isGrouppost.length).fill("")
  );
  const [isPopupOpen_delete, setisPopupOpen_delete] = useState(false);
  const [deleteMessage, setdeleteMessage] = useState("");
  const [postId, setpostId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [restrictedStates, setRestrictedStates] = useState([]); // To track restricted word status for each input

  const [restr_message, setrestr_message] = useState(false);
  const [comments, setComments] = useState(Array(isGrouppost.length).fill([])); // Initialize comments state
  const images = [
    {
      src: image1,
      wideSrc: image1,
      alt: "Nature",
    },
    {
      src: image2,
      wideSrc: image3,
      alt: "Snow",
    },
    {
      src: image4,
      wideSrc: image5,
      alt: "Mountains",
    },
    {
      src: image6,
      wideSrc: image6,
      alt: "Lights",
    },
    {
      src: image1,
      wideSrc: image1,
      alt: "Nature",
    },
    {
      src: image2,
      wideSrc: image3,
      alt: "Snow",
    },
    {
      src: image4,
      wideSrc: image5,
      alt: "Mountains",
    },
    {
      src: image6,
      wideSrc: image6,
      alt: "Lights",
    },
  ];
  const showSlide = (n) => {
    if (n > images.length) setCurrentSlideIndex(1);
    else if (n < 1) setCurrentSlideIndex(images.length);
    else setCurrentSlideIndex(n);
  };
  useEffect(() => {
    if (modalOpen) {
      // Disable scrolling when modal is open
      document.body.style.overflowY = "hidden";
    } else {
      // Enable scrolling when modal is closed
      document.body.style.overflowY = "auto";
    }

    // Cleanup to restore the overflow style when the component unmounts
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [modalOpen]);
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log("New WebSocket message:", receivedMessage);

        if (receivedMessage.event === "groupComments") {
          commentSectionRefresh();
        }
        if (receivedMessage.event === "groupfav") {
          console.log("aaaaaa");

          commentSectionRefresh();
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [isGrouppost, LoginData]);

  const formateTime = (dateString) => {
    if (!dateString) return null;

    const postDate = new Date(dateString);
    const now = new Date();

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((now - postDate) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleChangeHeart = async (postid, group_id) => {
    let formData = {
      user_id: LoginData.id,
      post_id: postid,
      group_id: group_id,
    };

    try {
      const res = await axios.post(apiUrl + "GrouppostFavourite", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      //commentSectionRefresh();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleCommentChange = (e, index) => {
    const inputValue = e.target.value;
    const newInputs = [...commentInputs];
    newInputs[index] = inputValue;
    setCommentInputs(newInputs);

    // Check for restricted words for the specific input
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    // Update the restriction state for this input
    const newRestrictedStates = [...restrictedStates];
    newRestrictedStates[index] = hasRestrictedWord;
    setRestrictedStates(newRestrictedStates);
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  const handlePostComment = async (index, postId, group_id) => {
    const commentText = commentInputs[index]?.trim();

    if (commentText) {
      const formData = {
        post_id: postId,
        user_id: LoginData.id,
        group_id: group_id,
        comment: commentText, // Use trimmed comment
      };

      try {
        const res = await axios.post(
          apiUrl + "CreateGroupPostComment",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        // Scroll to the last comment after posting
        console.log(index);
        // Clear the comment input after posting
        const newComments = [...commentInputs];
        newComments[index] = "";
        setCommentInputs(newComments); // Update the local comment state
        setTimeout(() => {
          scrollToLastComment();
        }, 100);
        //Isgroupfav();
        // Refresh the comment section
      } catch (err) {
        console.error("Error posting comment:", err);
      }
    }
  };
  const scrollToLastComment = () => {
    // if (commentRefs.current.length > 0) {
    //   const lastCommentIndex = commentRefs.current.length - 1;
    //   const lastComment = commentRefs.current[lastCommentIndex];
    //   if (lastComment) {
    //     lastComment.scrollIntoView({ behavior: "smooth", block: "end" });
    //   }
    // }
  };
  const handlegroupPostDeleteconfirm = async (id) => {
    setpostId(id);
    setisPopupOpen_delete(true);
    setdeleteMessage("Are you sure? You want delete this post");
  };
  const closePopup_delete = () => {
    setisPopupOpen_delete(false);
  };
  const handlegroupPostDelete = async () => {
    let formData = {
      id: postId,
      user_id: LoginData.id,
    };
    try {
      const res = await axios.post(apiUrl + "grouppostDelete", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setisPopupOpen_delete(false);
      getpostCommentrefresh();
    } catch (err) {
      console.error("Error posting comment:", err);
    }
  };
  const refreshpopup = async () => {
    handlegroupPostDelete();
  };
  return (
    <div>
      <AlertDelete
        isOpen1={isPopupOpen_delete}
        onClose1={closePopup_delete}
        message={deleteMessage}
        onReturnSent={refreshpopup}
      ></AlertDelete>
      {isGrouppost.length > 0 ? (
        isGrouppost.map((post, index) => (
          <div className="evname d-block" key={index}>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2 align-items-center">
                <div className="flex-shrink-0">
                  <div className="evephoto ofit rounded-circle overflow-hidden">
                    {post.profile_image && post.makeImagePrivate === 0 ? (
                      <img src={post.profile_image} alt="Selected" />
                    ) : (
                      <img
                        src={require("../assets/images/thumb.jpg")}
                        alt="Profile"
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex flex-column gap-1 evettimeheaing">
                    <h4>{post.username} created a group</h4>
                    <h5>{formateTime(post.date)}</h5>
                  </div>
                </div>
                {LoginData.id === post.user_id && (
                  <div className="flex-shrink-0 posttopbtns">
                    <button
                      title="Delete"
                      type="button"
                      onClick={() => handlegroupPostDeleteconfirm(post.id)}
                      className="btn redbtn"
                      style={{
                        padding: "0px 0px",
                        textAlign: "center",
                        minWidth: "0px",
                        background: "red",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-trash"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              <div className="d-block contpost">
                <p>{post.description}</p>
              </div>
              {post.file && (
                <div className="postimgfull ofit rounded-2 overflow-hidden">
                  {post.file.match(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i) ? (
                    <img
                      src={post.file}
                      alt="img"
                      onClick={() => {
                        openModal();
                        showSlide(index + 1);
                      }}
                    />
                  ) : post.file.match(/\.(mp4|mov|avi)$/i) ? (
                    <video
                      controls
                      alt="video"
                      onClick={() => {
                        openModal();
                        showSlide(index + 1);
                      }}
                    >
                      <source src={post.file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              )}
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-3 postchatheart">
                  <div className="d-flex align-items-center gap-3">
                    <button type="button" className="btn fulw">
                      <img src={chat} alt="chatpic" />
                    </button>
                    <p>{post.post.length}</p>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <button
                      type="button"
                      onClick={() => handleChangeHeart(post.id, post.group_id)}
                      className="liked btn fulw"
                    >
                      {post.fav ? <FaHeart /> : <FaRegHeart />}
                    </button>
                    <p>{post.fav_count}</p>
                  </div>
                </div>
              </div>
              <div className="comment_input d-flex flex-column gap-3">
                <ul className="p-0 comment_section scroll_bar d-flex flex-column gap-2">
                  {/* Displaying comments from state */}
                  {post.post.length > 0 ? (
                    post.post.map((comm, index) => (
                      <li
                        className="d-flex align-items-start gap-2"
                        key={index}
                        ref={(el) => (commentRefs.current[index] = el)}
                      >
                        <div className="d-block user_profile">
                          {comm.comment_user_profile_image &&
                          comm.comment_makeImagePrivate === 0 ? (
                            <img
                              className="w-100 h-100"
                              src={comm.comment_user_profile_image}
                              alt="eventspic"
                            />
                          ) : (
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/thumb.jpg")}
                              alt="eventspic"
                            />
                          )}
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <div className="d-flex align-items-center gap-3">
                            <h5>{comm.comment_user_username}</h5>
                            <span className="comment_time">
                              {formateTime(comm.comment_date)}
                            </span>
                          </div>
                          <p className="comment_user scroll_bar">
                            {comm.description}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="fs-6">No comments available.</p>
                  )}
                </ul>
                {restrictedStates[index] && (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    Your message contains restricted words. Please remove them
                  </span>
                )}
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="text"
                    name="comment"
                    placeholder="Enter Your Comment"
                    value={commentInputs[index]} // Use the input state here
                    onChange={(e) => handleCommentChange(e, index)} // Update input change handler
                  />
                  <button
                    type="submit"
                    onClick={() =>
                      handlePostComment(index, post.id, post.group_id)
                    }
                    disabled={
                      !commentInputs[index]?.trim() || restrictedStates[index]
                    } // Disable button if input is empty or has restricted words
                    style={{
                      opacity:
                        !commentInputs[index]?.trim() || restrictedStates[index]
                          ? 0.1
                          : 1,
                      transition: "opacity 0.3s",
                    }}
                    className="comment_postbtn"
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}

      {modalOpen && (
        <div id="myModal" className="modalgg">
          <span className="close cursor" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-content">
            {isGrouppost.length > 0 ? (
              isGrouppost.map((post, index) => (
                <div
                  className="mySlides"
                  style={{
                    display: currentSlideIndex === index + 1 ? "block" : "none",
                  }}
                  key={index}
                >
                  <div className="numbertext">
                    {index + 1} / {isGrouppost.length}
                  </div>

                  {post.file.match(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i) ? (
                    <img src={post.file} alt="img" />
                  ) : post.file.match(/\.(mp4|mov|avi)$/i) ? (
                    <video controls alt="video">
                      <source src={post.file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              ))
            ) : (
              <p>No media available.</p>
            )}

            <div className="caption-container">
              {/* <p id="caption">{images[currentSlideIndex - 1]?.alt}</p> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupPostComment;
