import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminBar from "../../components/AdminBar";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import moment from "moment";
import {
  faEye,
  faPaperPlane,
  faEnvelope,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import Headeradmin from "../../components/Admin/HeaderAdmin";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import NavAdmin from "../../components/Admin/NavAdmin";
export default function Groups() {
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]); // Full list of records
  const [showModal, setShowModal] = useState(false);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [currentRecord, setCurrentRecord] = useState("");
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [showModalComment, setShowModalComment] = useState(false);
  const [showModalLike, setShowModalLike] = useState(false);
  const [groupsComments, setgroupsComments] = useState([]);
  const [groupLikes, setgroupLikes] = useState([]);
  const [showModalInter, setShowModalInter] = useState(false);
  const [groupintersetduser, setgroupintersetduser] = useState([]);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
      className: "age",
    },
    {
      name: "Total Post",
      selector: (row) => row.total_post,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          {/* <a
            className="dataedit_btn"
            title="View Comments"
            onClick={() => handleComment(row)}
          >
            <FontAwesomeIcon icon={faComment} />
          </a>
          <a
            className="dataedit_btn"
            title="View Likes"
            onClick={() => handleLike(row)}
          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </a> */}
          <a
            onClick={() => handleinterseted(row.id)}
            className="dataedit_btn text-info"
            title="Interested User"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faStar} />
          </a>
          <a
            href={`/admin/groups/viewgrouppost/${row.slug}`}
            className="dataedit_btn text-secondary"
            title="View Post"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            className="dataedit_btn"
            title="View Detail"
            style={{ fontSize: "16px" }}
            onClick={() => handleEdit(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
          <a
            className="dataedit_btn text-success"
            title="View Media"
            style={{ fontSize: "16px" }}
            onClick={() => handlemedia(row)}
          >
            <FontAwesomeIcon icon={faFile} />
          </a>

          <a
            className="datadelete_btn text-danger"
            title="Delete"
            style={{ fontSize: "16px" }}
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getallgroups();
  }, []);
  const getallgroups = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallgroups", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      setRecords(respo);
      setOriginalRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? You want to delete this group");
    setUserIdToDelete(id);
  };
  const handleLike = async (row) => {
    setShowModalLike(true);
    let formData = {
      id: row.id,
    };
    try {
      const res = await axios.post(apiUrl + "getallgroupLikes", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      setgroupLikes(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleComment = async (row) => {
    setShowModalComment(true);
    let formData = {
      id: row.id,
    };
    try {
      const res = await axios.post(apiUrl + "getallgroupsComments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      setgroupsComments(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleinterseted = async (id) => {
    setShowModalInter(true);
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(
        apiUrl + "getallgroupinteresteduser",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      var respo = res.data.results;
      setgroupintersetduser(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleEdit = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModal(true);
  };
  const handlemedia = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row.image);
    setShowModalMedia(true);
  };
  const handleModalCloseLike = () => {
    setShowModalLike(false);
    setShowModalInter(false);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalCloseMedia = () => {
    setShowModalMedia(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };

  const handleSaveChanges = () => {
    setRecords(
      records.map((record) =>
        record.id === currentRecord.id ? currentRecord : record
      )
    );
    setShowModal(false);
  };

  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    if (lowerCaseSearch.trim() === "") {
      // Reset to full list when the input is empty
      setRecords(originalRecords);
    } else {
      const filteredData = records.filter(
        (row) =>
          row.description.toLowerCase().includes(lowerCaseSearch) ||
          row.name.toLowerCase().includes(lowerCaseSearch) ||
          row.total_comments.toString().includes(lowerCaseSearch) ||
          row.username.toLowerCase().includes(lowerCaseSearch)
      );
      setRecords(filteredData);
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deletegroup", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getallgroups();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  setInterval(() => {
    getallgroups();
  }, 2500);
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleModalCloseComment = () => {
    setShowModalComment(false);
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Group List</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
                <Modal
                  show={showModal}
                  className="edit_admin"
                  onHide={handleModalClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>View Group</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          disabled
                          value={currentRecord.description}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Created Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={moment(currentRecord.date).format(
                            "DD-MM-YYYY"
                          )}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Total Post
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={currentRecord.total_post}
                        />
                      </div>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={showModalMedia}
                  className="edit_admin "
                  onHide={handleModalCloseMedia}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>View Group</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ width: "100%" }}>
                    <form>
                      <div className="group_upload mb-3">
                        <img src={currentRecord} className="w-100 h-100" />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleModalCloseMedia}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showModalComment}
                  className="edit_admin"
                  onHide={handleModalCloseComment}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-white ">
                      View Comments
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <ul className="comment_userlist scroll_bar">
                        {groupsComments.length === 0 ? (
                          <li>No comments found</li>
                        ) : (
                          groupsComments.map((com) => (
                            <li>
                              <div className="d-flex align-items-start gap-3">
                                <div className="d-block user_img">
                                  <img
                                    className="w-100 h-100 object-fit-cover"
                                    src={com.profile_image}
                                    alt="user_img"
                                  />
                                </div>
                                <div className="d-flex flex-column gap-2 justify-content-between ">
                                  <div className="d-flex align-items-center gap-2 justify-content-between">
                                    <h4>{com.username}</h4>
                                    <h5>{formateTime(com.date)}</h5>
                                  </div>
                                  <p className="scroll_bar">
                                    {com.description}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleModalCloseComment}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={showModalLike}
                  className="edit_admin"
                  onHide={handleModalCloseLike}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-white ">
                      View Likes
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <ul className="comment_userlist scroll_bar">
                        {groupLikes.length === 0 ? (
                          <li>No likes found</li>
                        ) : (
                          groupLikes.map((com) => (
                            <li>
                              <div className="d-flex align-items-start gap-3">
                                <div className="d-block user_img">
                                  <img
                                    className="w-100 h-100 object-fit-cover"
                                    src={com.profile_image}
                                    alt="user_img"
                                  />
                                </div>
                                <div className="d-flex flex-column gap-2 justify-content-between ">
                                  <div className="d-flex align-items-center gap-2 justify-content-between">
                                    <h4>{com.username}</h4>
                                    <h5>{formateTime(com.date)}</h5>
                                  </div>
                                  <p className="scroll_bar">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleModalCloseLike}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={showModalInter}
                  className="edit_admin"
                  onHide={handleModalCloseLike}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-white ">
                      View User Interested
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form>
                      <ul className="comment_userlist scroll_bar">
                        {groupintersetduser.length === 0 ? (
                          <li>No results found</li>
                        ) : (
                          groupintersetduser.map((com) => (
                            <li>
                              <div className="d-flex align-items-start gap-3">
                                <div className="d-block user_img">
                                  <img
                                    className="w-100 h-100 object-fit-cover"
                                    src={com.profile_image}
                                    alt="user_img"
                                  />
                                </div>
                                <div className="d-flex flex-column gap-2 justify-content-between ">
                                  <div className="d-flex align-items-center gap-2 justify-content-between">
                                    <h4>{com.username}</h4>
                                    <h5>{formateTime(com.date)}</h5>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleModalCloseLike}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
