import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
export default function ReportViolation() {
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]); // Full list of records

  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);
  const tableRef = useRef(null); // Reference to the table

  const columns = [
    {
      name: "Reported User", // The user who has been reported
      selector: (row) => row.reportedUser, // Using 'reportedUser' for the reported user's username
      sortable: true,
    },
    {
      name: "Reported By", // The user who made the report
      selector: (row) => row.reporterUser, // Using 'reporterUser' for the reporter's username
      sortable: true,
    },
    {
      name: "Violation Type", // Type of violation or report description
      selector: (row) => row.report, // Using 'report' for the violation description
      sortable: true,
      className: "age",
    },
    {
      name: "Other Report", // Additional details or comments on the report
      selector: (row) => row.otherReport, // Using 'otherReport' for additional report details
      sortable: true,
      className: "age",
    },
    {
      name: "Date", // Additional details or comments on the report
      selector: (row) => moment(row.date).format("YYYY-MM-DD"), // Using 'otherReport' for additional report details
      sortable: true,
      className: "age",
    },
  ];

  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getallusersreport();
  }, []);
  const getallusersreport = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallusersreport", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
      setOriginalRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? You want to delete this user");
    setUserIdToDelete(id);
  };

  const handleEdit = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };

  const handleSaveChanges = () => {
    setRecords(
      records.map((record) =>
        record.id === currentRecord.id ? currentRecord : record
      )
    );
    setShowModal(false);
  };

  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();

    if (lowerCaseSearch.trim() === "") {
      // Reset to full list when the input is empty
      setRecords(originalRecords);
    } else {
      const filteredData = originalRecords.filter(
        (row) =>
          row.username.toLowerCase().includes(lowerCaseSearch) ||
          row.email.toLowerCase().includes(lowerCaseSearch) ||
          row.status.toString().includes(lowerCaseSearch) ||
          row.start_date.toLowerCase().includes(lowerCaseSearch)
      );
      setRecords(filteredData);
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deleteusers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getallusersreport();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.status.value);

    let formData = {
      status: e.target.status.value,
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "editprofile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setShowModal(false);
      console.log(respo);
      setShowPopSuccess(true);
      setPopsuccessMessage(respo.message);
      getallusersreport();
      setTimeout(() => {
        setShowPopSuccess(false);
      }, 1000);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Report & Violation</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
                <Modal
                  show={showModal}
                  className="edit_admin"
                  onHide={handleModalClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Record</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          disabled
                          name="username"
                          value={currentRecord.username}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                          value={currentRecord.status}
                          onChange={handleInputChange}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Banned">Banned</option>
                        </select>
                      </div>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          className="bg-success border-color-none"
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
