import React, { useState, useRef, useEffect } from "react";

import ev1 from "../assets/images/in1.png";
import ev2 from "../assets/images/in2.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import ErrorPop from "../components/ErrorPop";
import Headertwo from "../components/Headertwo";
import SuccessPop from "../components/SuccessPop";
import axios from "axios";
import { format } from "date-fns";
import { Report } from "../components/Report";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function ReceivedMessagesChat() {
  const navigate = useNavigate(); // Correctly define the navigate function
  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null); // Ref to the chat container
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [userblock, setuserblock] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const apiUrl = "https://backend.amourette.no/api/members/";
  const apiUrlProfile = "https://backend.amourette.no/api/profile/";
  const [message, setMessage] = useState("");
  const [userdetail, setuserdetail] = useState("");
  const [messages, setmessages] = useState([]);
  const [allmessages, setmessagesall] = useState([]);
  const [LoginData, setLoginData] = useState(null);
  const [error, seterror] = useState("");
  const [spinner, setspinner] = useState(false);
  const [Isfile, setFiles] = useState([]);
  const [to_id, setto_id] = useState("");
  const { slug } = useParams();
  const [showPopError, setShowPopError] = useState(false);
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [isCheckfriend, setCheckfriend] = useState("");
  const [restr_message, setrestr_message] = useState(false);
  const [albumdata, setalbumdata] = useState([]);
  const [userper, setuserper] = useState(false);
  // State to track the active button
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Set the clicked button as active
  };
  const chatBoxRef = useRef(null);

  const isScrolledToBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  };

  const handleScroll = async () => {
    if (chatBoxRef.current && isScrolledToBottom(chatBoxRef.current)) {
      console.log("k");
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

      if (to_id !== "") {
        let formData = {
          user_id: userLogin.id,
          to_id: to_id,
        };
        console.log(formData);
        try {
          const res = await axios.post(apiUrl + "messageseen", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          console.log(res);
          if (res.data.status === "1") {
            chatsectionUser(to_id);
          }
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
            console.error("Error response:", err.response);
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      }
    }
  };
  const updateReadMessagesread = async () => {};
  const updateReadMessages = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

    const lastMessage = allmessages[allmessages.length - 1];

    if (lastMessage !== undefined && lastMessage !== null) {
      let formData = {
        user_id: userLogin.id,

        to_id: to_id,
      };
      try {
        const res = await axios.post(apiUrl + "messageseen", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        if (res.data.status === "1") {
          chatsectionUser(to_id);
        }
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Error response:", err.response);
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    }
  };
  useEffect(() => {
    updateReadMessagesread();
  }, [messages]);
  useEffect(() => {
    const chatBox = chatBoxRef.current;
    chatBox.addEventListener("scroll", handleScroll);

    return () => {
      chatBox.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    setLoginData(userLogin);
  }, []); // Remove LoginData from the dependency array to prevent unnecessary calls

  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        if (!receivedMessage) {
          console.error("Received invalid JSON:", event.data);
          return;
        }
        const senderId = Number(receivedMessage.user_id); // Sender's ID (e.g. 5)
        const receiverId = Number(receivedMessage.to_id); // Receiver's ID (e.g. 1)
        const loggedInUserId = Number(LoginData.id); // Currently logged-in user's ID (e.g. 1 or 5)
        var ttt = Number(to_id);
        console.log(receivedMessage.event);
        if (receivedMessage.event === "MessageseenScroll") {
          if (loggedInUserId === senderId || loggedInUserId === receiverId) {
            console.log("ggg");
            getSEndMessage(receivedMessage, senderId, receiverId);
          }
        }
        // Ensure message is only seen by the sender or receiver
        if (receivedMessage.event === "ChatMessage") {
          if (loggedInUserId === senderId || loggedInUserId === receiverId) {
            getSEndMessage(receivedMessage, senderId, receiverId);

            // If the logged-in user is the sender, auto-scroll

            if (loggedInUserId === receiverId) {
              //updateReadMessages();
            }
            if (loggedInUserId === senderId) {
              setTimeout(() => {
                chatEndRef.current.scrollIntoView();
              }, 200);
            } else {
              console.log("This is a receiver's message:", receivedMessage);
            }
          } else {
            // Ignore the message for any other users
            console.log("Message ignored for this user:", loggedInUserId);
          }
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [LoginData, to_id]);

  const getSEndMessage = async (form, n, t) => {
    let formData = {
      data: form,
    };

    try {
      const res = await axios.post(apiUrl + "getSEndMessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Correct content type for file uploads
        },
      });
      console.log("Response from server:", res.data);

      // Assuming res.data.results is the array of messages you want to append
      if (res.data && Array.isArray(res.data.results)) {
        // Update the messages state with new messages appended

        setmessages((prevMessages) => [...prevMessages, ...res.data.results]);
        setmessagesall(res.data.results);
      } else {
        console.error("No valid results found in the response:", res.data);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Response error:", err.response);
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("General error:", err.message);
      }
    }
  };

  const getUsercheckPermisson = async (toid) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = { slug: slug, user_id: userLogin.id, to_id: toid };
    try {
      const res = await axios.post(
        apiUrlProfile + "getUsercheckPermisson",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("kl", res.data.row);
      if (res.data.row.length > 0) {
        var respo = res.data.row[0];
        setuserdetail(respo);
      } else {
        // navigate("/receivedmessages");
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Error response:", err.response);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    if (slug !== undefined) {
      const getUserDetails = async (slug) => {
        const storedUsername = localStorage.getItem("userLogin");
        const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
        let formData = { slug: slug, user_id: userLogin.id };
        try {
          const res = await axios.post(
            apiUrlProfile + "getUserSlug",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.row.length > 0) {
            var respo = res.data.row[0];

            setto_id(respo.id);
            getUsercheckPermisson(respo.id);
            getCheckfriend(respo.id);
            chatsectionUser(respo.id);

            setTimeout(() => {
              if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView();
              }
            }, 100);
          } else {
            // navigate("/receivedmessages");
          }
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
            console.error("Error response:", err.response);
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      };
      getUserDetails(slug);
    }
  }, [slug, LoginData]);
  useEffect(() => {
    const checkuserblock = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        user_id: userLogin.id,
        to_id: to_id,
      };
      try {
        const res = await axios.post(apiUrl + "checkuserblock", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Correct content type for file uploads
          },
        });
        console.log("checkblock");
        console.log(res.data);
        if (res.data.result.length > 0) {
          setuserblock(true);
        } else {
          setuserblock(false);
        }
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response error:", err.response);
        } else if (err.request) {
          console.error("Request error:", err.request);
        } else {
          console.error("General error:", err.message);
        }
      }
    };
    checkuserblock();
  }, [to_id]);
  const chatsectionUser = async (id) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    // Ensure LoginData is not null
    if (!userLogin || !userLogin.id) {
      console.error("Login data is not available");
      return; // Exit the function if LoginData is null
    }

    let formData = {
      user_id: userLogin.id,
      to_id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getuserChatmessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      setmessages(respo);
      setmessagesall(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Error response:", err.response);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const MAX_FILE_SIZE_MB = 2;
  const getFileSrc = (fileUrl) => {
    // List of allowed file extensions for online files
    const allowedExtensions = [".png", ".jpg", ".jpeg", ".gif"];

    // Check if the file URL ends with any of the allowed extensions
    const isAllowedExtension = allowedExtensions.some((ext) =>
      fileUrl.endsWith(ext)
    );

    // If the file URL has an allowed extension, return the URL, else return the local image
    return isAllowedExtension
      ? fileUrl
      : require("../assets/images/images.png");
  };
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const errorFiles = [];

    selectedFiles.forEach((file) => {
      if (file.size / 1024 / 1024 <= MAX_FILE_SIZE_MB) {
        validFiles.push(file);
      } else {
        errorFiles.push(file);
      }
    });

    if (errorFiles.length > 0) {
      seterror(
        `Some files are larger than ${MAX_FILE_SIZE_MB} MB and were not added.`
      );
    } else {
      seterror("");
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const isButtonDisabled =
    (!message.trim() && Isfile.length === 0) || restr_message; // Enable button if either input is filled
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setMessage(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const removeImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove image by index
  };
  const MAX_WORDS = 2000;
  const countWords = (text) => {
    return text.trim() ? text.split(/\s+/).length : 0; // Count words by splitting on whitespace
  };
  const handleSend = async () => {
    console.log(userblock);
    if (userblock === true) {
      setShowPopError(true);
      setPoperrorMessage("User is blocked");
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1500);
    } else {
      const formData = new FormData();
      setspinner(true);
      var commentText = message?.trim();
      console.log(commentText);

      if (commentText !== "") {
        const currentWordCount = countWords(commentText);
        if (currentWordCount < 2000) {
          formData.append("message", message);
          formData.append("fileType", "chats");
          formData.append("user_id", LoginData.id);
          formData.append("to_id", to_id);
          Isfile.forEach((file) => {
            formData.append("files", file); // Use the key 'files' for your backend to handle multiple files
          });

          console.log("Message:", message);
          console.log("Files:", Isfile);

          try {
            const res = await axios.post(apiUrl + "saveUserChat", formData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data", // Correct content type for file uploads
              },
            });
            setspinner(false);
            var respo = res.data;
            //setMessages((prevMessages) => [...prevMessages, res.data]); // Update messages state

            // Scroll to the bottom of the chat
            if (chatEndRef.current) {
              chatEndRef.current.scrollIntoView();
            }
            //await getChatAfterSave();
            setTimeout(() => {
              if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView();
              }
            }, 500);
          } catch (err) {
            // Enhanced error handling
            if (err.response) {
              console.error("Response error:", err.response);
            } else if (err.request) {
              console.error("Request error:", err.request);
            } else {
              console.error("General error:", err.message);
            }
          }

          // Reset message and files after sending
          setMessage("");
          setFiles([]);
        } else {
          setspinner(false);
          setShowPopError(true);
          setPoperrorMessage(
            `Comment cannot exceed ${MAX_WORDS} words. Currently: ${currentWordCount} words.`
          );
          setTimeout(() => {
            setShowPopError(false);
            setPoperrorMessage("");
          }, 1500);
        }
      } else {
        formData.append("message", message);
        formData.append("fileType", "chats");
        formData.append("user_id", LoginData.id);
        formData.append("to_id", to_id);
        Isfile.forEach((file) => {
          formData.append("files", file); // Use the key 'files' for your backend to handle multiple files
        });

        console.log("Message:", message);
        console.log("Files:", Isfile);

        try {
          const res = await axios.post(apiUrl + "saveUserChat", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // Correct content type for file uploads
            },
          });
          setspinner(false);
          var respo = res.data;
          //setMessages((prevMessages) => [...prevMessages, res.data]); // Update messages state

          // Scroll to the bottom of the chat
          if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView();
          }
          //await getChatAfterSave();
          setTimeout(() => {
            if (chatEndRef.current) {
              chatEndRef.current.scrollIntoView();
            }
          }, 500);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
            console.error("Response error:", err.response);
          } else if (err.request) {
            console.error("Request error:", err.request);
          } else {
            console.error("General error:", err.message);
          }
        }

        // Reset message and files after sending
        setMessage("");
        setFiles([]);
      }
    }
  };
  const getCheckfriend = async (id) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getCheck_friendUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("c");
      console.log(res.data.results.length);
      if (res.data.results.length > 0) {
        setCheckfriend(true);
      } else {
        setCheckfriend(false);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const requestToview = async () => {
    if (isCheckfriend === true) {
      let formData = {
        user_id: LoginData.id,
        to_id: to_id,
      };
      console.log(formData);

      try {
        const res = await axios.post(apiUrl + "requestToview", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Correct content type for file uploads
          },
        });

        var respo = res.data;
        if (respo.status === "2") {
          setShowPopSuccess(true);
          setPopsuccessMessage("Request allready sent");
        }
        if (respo.status === "3") {
          setShowPopSuccess(true);
          setPopsuccessMessage("Request confirmed.");
        }
        if (respo.status === "1") {
          setShowPopSuccess(true);
          setPopsuccessMessage("Request successfully sent");
        }
        //getUserDetails(slug);
        console.log(respo);
        setTimeout(() => {
          setShowPopSuccess(false);
          setPopsuccessMessage("");
          setShowPopError(false);
          setPoperrorMessage("");
        }, 1000);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response error:", err.response);
        } else if (err.request) {
          console.error("Request error:", err.request);
        } else {
          console.error("General error:", err.message);
        }
      }
    } else {
      setPoperrorMessage(
        "You don’t have permission to send the request because you’re not friends with this user."
      );
      setShowPopError(true);

      setTimeout(() => {
        setPoperrorMessage("");
        setShowPopError(false);
      }, 2000);
    }
  };

  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const openPopup1 = () => {
    setIsPopupOpen1(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const handlereport = async () => {
    setIsPopupOpen1(true);
    console.log("d");
  };
  const lastMessage = messages[messages.length - 1];
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  var apiUrll = "https://backend.amourette.no/api/members/";
  useEffect(() => {
    const getalbumStatus = async () => {
      let formData = {
        user_id: userdetail.id,
        status: userdetail.album_setting_status,
      };
      console.log(formData, "fff");
      try {
        const res = await axios.post(apiUrll + "getalbumStatus", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });

        setalbumdata(res.data.results);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getalbumStatus();
  }, [userdetail]);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    console.log("*****************", userdetail.id);
    if (String(userLogin.id) === String(userdetail.id)) {
      setuserper(true);
    } else {
      setuserper(false);
    }
  }, [userper, userdetail]);
  return (
    <>
      <Headertwo />
      {showPopError && <ErrorPop message={errorPopMessage} />}
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      <section className="inboxblock d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>Inbox</h2>
                {/* <div className="filtersearch">
                  <form action="">
                    <input type="text" placeholder="search groups" />
                  </form>
                </div> */}
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/receivedmessages"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Received_messages")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/sendmessages"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "" : ""
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Sent_messages")}</h3>
                    </div>
                  </Link>

                  <hr className="linehr" />
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                <div className="col-md-8">
                  <div className="d-flex flex-column gap-0 chat_box">
                    <div className="d-flex align-items-center gap-3 mess_head">
                      <div className="d-block chat_profile">
                        {userdetail.profile_image ? ( // Display the image URL if available
                          <img
                            className="w-100 h-100"
                            src={userdetail.profile_image} // Use the URL from the member object
                            alt="userpic"
                          />
                        ) : (
                          <img
                            className="w-100 h-100"
                            src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                            alt="userpic"
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column gap-2 chat_name">
                        <div className="d-flex align-items-center gap-2">
                          <h3>{userdetail.username} ,</h3>
                          <h3>
                            {userdetail.birthday_date != null
                              ? `${calculateAge(userdetail.birthday_date)},`
                              : ""}
                          </h3>
                        </div>
                        <h5>{userdetail.location}</h5>
                      </div>
                    </div>
                    <div
                      className="mess_box_main"
                      ref={chatBoxRef}
                      style={{
                        height: "56vh",
                        overflow: "auto",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "end",
                      }}
                    >
                      <div className="mess_box d-flex flex-column gap-4 justify-content-end w-100">
                        {messages.length === 0 ? (
                          <div className="empty-chat-message">
                            <p>{t("No_messages_to_display")}</p>
                          </div>
                        ) : (
                          messages.map((chat, index) => {
                            const isLastMessage = messages.length - 1;

                            return (
                              <div key={index} className={`last_${index}`}>
                                {chat.user_id === LoginData.id ? (
                                  // Right-aligned messages (sent by the logged-in user)
                                  <div className="ri">
                                    <div className="main_mess right_mess d-flex align-items-end gap-2 flex-column">
                                      {/* Render the profile image only if there are files */}
                                      {Array.isArray(JSON.parse(chat.file)) &&
                                        JSON.parse(chat.file).length > 0 && (
                                          <>
                                            {/* Show profile image only for the first file */}
                                            {index === 0 && (
                                              <div className="d-block mess_profile">
                                                <img
                                                  className="w-100 h-100"
                                                  src={chat.user1_profile}
                                                  alt="profile"
                                                />
                                              </div>
                                            )}

                                            {JSON.parse(chat.file).map(
                                              (f, fileIndex) => (
                                                <div className="message d-block send_image">
                                                  <img
                                                    key={fileIndex}
                                                    className="w-100 h-100"
                                                    src={getFileSrc(f)} // Directly use the parsed URL
                                                    alt={`file-${fileIndex}`}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                    </div>
                                    {chat.message &&
                                      chat.message !== "null" && (
                                        <div className="main_mess right_mess d-flex flex-column align-items-end gap-1">
                                          <div className="d-flex align-items-center flex-row-reverse gap-2">
                                            <div className="d-block mess_profile">
                                              <img
                                                className="w-100 h-100"
                                                src={chat.user1_profile}
                                                alt="profile"
                                              />
                                            </div>
                                            <p className="message">
                                              {chat.message}
                                            </p>
                                          </div>
                                          {isLastMessage &&
                                            chat.read === "Yes" && (
                                              <div className="seen_profile">
                                                <img
                                                  className="w-100 h-100 object-fit-cover"
                                                  src={chat.user2_profile}
                                                  alt="profile"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  // Left-aligned messages (received from other users)
                                  <>
                                    <div className="main_mess left_mess d-flex align-items-start gap-2 flex-column">
                                      {/* Check if chat.file is valid and has files */}
                                      {Array.isArray(JSON.parse(chat.file)) &&
                                        JSON.parse(chat.file).length > 0 && (
                                          <>
                                            {/* Show profile image only for the first file */}
                                            {index === 0 && (
                                              <div className="d-block mess_profile">
                                                <img
                                                  className="w-100 h-100"
                                                  src={chat.user1_profile}
                                                  alt="profile"
                                                />
                                              </div>
                                            )}

                                            {JSON.parse(chat.file).map(
                                              (f, fileIndex) => (
                                                <div className="message d-block send_image">
                                                  <img
                                                    key={fileIndex}
                                                    className="w-100 h-100"
                                                    src={getFileSrc(f)} // Use the parsed URL directly
                                                    alt={`file-${fileIndex}`}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                    </div>
                                    {chat.message &&
                                      chat.message !== "null" && (
                                        <div className="main_mess left_mess d-flex align-items-end gap-2">
                                          <div className="d-block mess_profile">
                                            <img
                                              className="w-100 h-100"
                                              src={chat.user1_profile}
                                              alt="profile"
                                            />
                                          </div>
                                          <p className="message">
                                            {chat.message}
                                          </p>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          })
                        )}
                        <div ref={chatEndRef} />
                      </div>
                    </div>
                    <span className="text-danger">{error}</span>
                    {restr_message && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        Your message contains restricted words. Please remove
                        them
                      </span>
                    )}
                    <div className="message_type d-flex align-items-center gap-2">
                      <div className="d-block image_img">
                        <input
                          type="file"
                          onChange={handleFileChange}
                          multiple
                        />
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach2.png")}
                          alt="img"
                        />
                      </div>
                      <input
                        type="text"
                        value={message}
                        onChange={handleInputChange}
                        placeholder={t("Write_a_message")}
                      />
                      <button
                        className="d-block image_img"
                        type="button"
                        onClick={handleSend}
                        disabled={isButtonDisabled} // Disable button based on conditions
                        style={{ opacity: isButtonDisabled ? 0.1 : 1 }}
                      >
                        <img
                          className="w-50 h-50"
                          src={require("../assets/images/send.png")}
                          alt="img"
                        />
                      </button>
                      {spinner && (
                        <div
                          className="spinner-border text-success send_load"
                          role="status"
                        >
                          <span className="visually-hidden">
                            {t("loading")}...
                          </span>
                        </div>
                      )}
                      {Isfile.length > 0 && (
                        <div className="muti_images">
                          <div className="muti_main overflow-auto d-flex gap-2 mx-auto w-96">
                            {Isfile.map((file, index) => {
                              const isImage =
                                file.type.startsWith("image/") ||
                                file.type === "application/pdf"; // Check if it's an image or a PDF
                              const imageURL = isImage
                                ? URL.createObjectURL(file)
                                : require("../assets/images/images.png");

                              return (
                                <div key={index} className="d-block muti_image">
                                  <img
                                    className="w-100 h-100"
                                    src={imageURL}
                                    alt="img"
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop
                                      e.target.src = require("../assets/images/images.png"); // Set to default image on error
                                    }}
                                  />
                                  <button
                                    className="delete_img"
                                    onClick={() => removeImage(index)}
                                  >
                                    ×
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column gap-4">
                    <div className="recive_main">
                      <div className="row gy-5">
                        <div className="col-12">
                          <div className="row gy-3 scroll_40">
                            {!userper && (
                              <>
                                {userdetail.album_setting_status ===
                                "Public_visible" ? (
                                  <>
                                    {Array.isArray(albumdata) &&
                                      albumdata.map((item, index) => {
                                        const images = JSON.parse(item.images); // Parse the JSON string into an array
                                        return images.map((image, imgIndex) => (
                                          <div
                                            className="col-lg-4 col-6 px-1"
                                            key={`${index}-${imgIndex}`}
                                          >
                                            <div className="d-block recive_two">
                                              <img
                                                className="w-100 h-100 object-fit-cover"
                                                src={image}
                                                alt={`Profile ${
                                                  index + 1
                                                } - Image ${imgIndex + 1}`}
                                              />
                                            </div>
                                          </div>
                                        ));
                                      })}
                                  </>
                                ) : (
                                  <>
                                    {userdetail.uStatus === "Yes" ? (
                                      <>
                                        {Array.isArray(albumdata) &&
                                          albumdata.map((item, index) => {
                                            const images = JSON.parse(
                                              item.images
                                            ); // Parse the JSON string into an array
                                            return images.map(
                                              (image, imgIndex) => (
                                                <div
                                                  className="col-lg-4 col-6 px-1"
                                                  key={`${index}-${imgIndex}`}
                                                >
                                                  <div className="d-block recive_two">
                                                    <img
                                                      className="w-100 h-100 object-fit-cover"
                                                      src={image}
                                                      alt={`Profile ${
                                                        index + 1
                                                      } - Image ${
                                                        imgIndex + 1
                                                      }`}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            );
                                          })}
                                      </>
                                    ) : (
                                      <>
                                        {(userdetail.uStatus === "No" ||
                                          userdetail.uStatus === null ||
                                          userdetail.uStatus === "null") && (
                                          <>
                                            {Array.from({ length: 4 }).map(
                                              (_, index) => (
                                                <div className="col-sm-6">
                                                  <div
                                                    className="dd d-flex flex-column gap-3 justify-content-center align-items-center search_twoinfo"
                                                    onClick={
                                                      !userper
                                                        ? requestToview
                                                        : null
                                                    }
                                                  >
                                                    <Link to="javascript:void(0)">
                                                      {t("PRIVATE_ALBUM")}
                                                    </Link>
                                                    <div className="d-block search_twoinfoicon">
                                                      <img
                                                        className="w-100 h-100"
                                                        src={require("../assets/images/ms3.png")}
                                                        alt="img"
                                                      />
                                                    </div>
                                                    <p>
                                                      {t("REQUEST_TO_VIEW")}
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {userper && (
                              <>
                                {Array.isArray(albumdata) &&
                                  albumdata.map((item, index) => {
                                    const images = JSON.parse(item.images); // Parse the JSON string into an array
                                    return images.map((image, imgIndex) => (
                                      <div
                                        className="col-lg-4 col-6 px-1"
                                        key={`${index}-${imgIndex}`}
                                      >
                                        <div className="d-block recive_two">
                                          <img
                                            className="w-100 h-100 object-fit-cover"
                                            src={image}
                                            alt={`Profile ${
                                              index + 1
                                            } - Image ${imgIndex + 1}`}
                                          />
                                        </div>
                                      </div>
                                    ));
                                  })}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <ul className="revice_type d-flex flex-column gap-3 p-0">
                            <li>
                              <h6>{t("Looking_for")}</h6>
                              <p>
                                {userdetail.looking_for !== "null"
                                  ? userdetail.looking_for
                                  : ""}
                              </p>{" "}
                              {/* Check if not 'null' */}
                            </li>
                            <li>
                              <h6>{t("Nationality")}</h6>
                              <p>
                                {userdetail.nationality !== "null"
                                  ? userdetail.nationality
                                  : ""}
                              </p>{" "}
                              {/* Check if not 'null' */}
                            </li>
                            <li>
                              <h6>{t("Bodytype")}</h6>
                              <p>
                                {userdetail.bodytype !== "null"
                                  ? userdetail.bodytype
                                  : ""}
                              </p>{" "}
                              {/* Check if not 'null' */}
                            </li>
                            <li>
                              <h6>{t("Height")}</h6>
                              <p>
                                {userdetail.height_feet !== "null"
                                  ? userdetail.height_feet
                                  : ""}{" "}
                                {userdetail.height_inches !== "null"
                                  ? userdetail.height_inches
                                  : ""}{" "}
                                {/* Check if not 'null' */}
                              </p>
                            </li>

                            <li>
                              <h6>{t("Sexual_orientation")}</h6>
                              <p>
                                {userdetail.sexual_orientation !== "null"
                                  ? userdetail.sexual_orientation
                                  : ""}
                              </p>{" "}
                            </li>
                            <li>
                              <h6>{t("Relationship_status")}</h6>
                              <p>
                                {userdetail.relationship_status !== "null"
                                  ? userdetail.relationship_status
                                  : ""}
                              </p>{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="col-12">
                          <ul className="revice_type d-flex flex-column gap-3 p-0">
                            <li>
                              <h6> {t("Member_since")}</h6>
                              {userdetail.created_at &&
                              userdetail.created_at !== ""
                                ? new Date(
                                    userdetail.created_at
                                  ).toLocaleDateString("en-GB")
                                : ""}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center recive_main_btn align-items-center">
                      <button className="report" onClick={handlereport}>
                        {t("REPORT_USER")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Report isOpen={isPopupOpen1} onClose={closePopup1}></Report>
      </section>
    </>
  );
}

export default ReceivedMessagesChat;
