import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsCloudUpload } from "react-icons/bs";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
function Register() {
  // State to track visibility
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [Valuedate, setValuedate] = useState("");
  const [Valuemonth, setValuemonth] = useState("");
  const [Valueyear, setValueyear] = useState("");
  const [email, setEmail] = useState("");
  const [answer, setanswer] = useState("");
  const [question, setquestion] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err_date, seterr_date] = useState("");
  const [err_email, seterr_email] = useState("");
  const [err_username, seterr_username] = useState("");
  const [err_pass, seterr_pass] = useState("");
  const [spinner, setspinner] = useState(false);
  const [err_gender, seterr_gender] = useState("");
  const [gender, setGender] = useState("");
  const [genders, setGenders] = useState("");
  const [LocationData, setLocationData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [selectedFilecrop, setSelectedFilecrop] = useState(null);
  const [sexuality, setSexuality] = useState([]);
  const [IpAddress, setIpAddress] = useState("");
  // Function to toggle visibility
  //Image Validation
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file is an image
      setSelectedFilecrop(URL.createObjectURL(file));
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
    }
  };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const maleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Gay", label: "Gay" },
    { value: "Bull", label: "Bull" },
  ];

  const femaleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Lesbian", label: "Lesbian" },
    { value: "Hotwife", label: "Hotwife" },
  ];

  const coupleOptions_select = [
    { value: "Full swap", label: "Full swap" },
    { value: "Soft swap", label: "Soft swap" },
    { value: "Cuckold", label: "Cuckold" },
    { value: "Exhibitionist", label: "Exhibitionist" },
    { value: "Voyeur", label: "Voyeur" },
    { value: "Still Exploring", label: "Still Exploring" },
    { value: "HotWife", label: "HotWife" },
  ];
  const isValidPassword = (password) => {
    // Check minimum length and strong password criteria
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasDigits &&
      hasSpecialChar
    );
  };
  const toggleVisibility = () => {
    let isValid = true; // Flag to track overall validity

    // Validate email
    if (!email || !isValidEmail(email)) {
      seterr_email("Please enter a valid email address.");
      isValid = false; // Set isValid to false if email validation fails
    } else {
      seterr_email(""); // Clear error if valid
    }
    if (!username) {
      seterr_username("Please enter a name.");
      isValid = false; // Set isValid to false if email validation fails
    } else {
      seterr_username(""); // Clear error if valid
    }

    // Validate password
    if (!password || !isValidPassword(password)) {
      seterr_pass(
        "Password must be at least 8 characters long and include uppercase letters, lowercase letters, digits, and special characters."
      );
      isValid = false; // Set isValid to false if password is missing or invalid
    } else {
      seterr_pass(""); // Clear error if valid
    }

    // Validate date, month, and year
    if (!Valuedate || !Valuemonth || !Valueyear) {
      seterr_date("Please fill out all date fields.");

      isValid = false; // Set isValid to false if any date field is missing
    } else {
      const date = parseInt(Valuedate);
      const month = parseInt(Valuemonth);
      const year = parseInt(Valueyear);

      // Get the current date
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
      const currentDay = currentDate.getDate();

      // Check if the date, month, and year are valid
      if (
        date < 1 ||
        date > 31 ||
        month < 1 ||
        month > 12 ||
        year < 1900 ||
        year > currentYear
      ) {
        seterr_date("Please enter a valid date, month, and year.");
        isValid = false;
      } else {
        // Check if the user is at least 18 years old
        const age = currentYear - year;
        const isBeforeBirthday =
          month > currentMonth || (month === currentMonth && date > currentDay);

        if (age < 18 || (age === 18 && isBeforeBirthday)) {
          seterr_date("You must be at least 18 years old.");
          isValid = false;
        } else {
          seterr_date(""); // Clear error if valid
        }
      }
    }

    // If all validations passed, toggle visibility
    if (isValid) {
      setIsVisible(!isVisible);
    }
  };
  const setGenderr = (e) => {
    setGender(e);
    setSexuality([]);
  };
  const handleChangedate = (e) => {
    const newValue = e.target.value;
    // Check if the new value is an integer or empty
    if (/^\d{0,2}$/.test(newValue)) {
      setValuedate(newValue);
    }
  };

  const handleChangemonth = (e) => {
    const newValue = e.target.value;
    // Check if the new value is an integer or empty
    if (/^\d{0,2}$/.test(newValue)) {
      setValuemonth(newValue);
    }
  };

  const handleChangeyear = (e) => {
    const newValue = e.target.value;
    // Check if the new value is an integer or empty
    if (/^\d{0,4}$/.test(newValue)) {
      setValueyear(newValue);
    }
  };
  useEffect(() => {
    // Fetch IP address from ipify API
    const getIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        console.log(response.data.ip);
        console.log("u");
        setIpAddress(response.data.ip); // Update state with IP address
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    getIpAddress();
  }, []);
  // Register Form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validate email and password
    if (!email || !password) {
      alert("Please fill out all required fields.");
      return;
    }

    // Validate date, month, and year

    // if (genders === 0) {
    //   seterr_gender("Please select a gender.");
    //   return;
    // } else {
    //   seterr_gender(""); // Clear error if valid
    // }

    setspinner(true);
    console.log(LocationData);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("username", username);
    formData.append("question", question);
    formData.append("answer", answer);
    formData.append("password", password);
    formData.append("birth_date", Valuedate);
    formData.append("birth_month", Valuemonth);
    formData.append("birth_year", Valueyear);
    formData.append("profile_type", event.target.profile_type.value);
    formData.append("genders", gender);
    formData.append("IpAddress", IpAddress);
    formData.append("city", LocationData.city);
    formData.append("country_code", LocationData.country_code);
    formData.append("country_name", LocationData.country_name);
    formData.append("region", LocationData.region);
    formData.append("postal", LocationData.postal);
    formData.append("timezone", LocationData.timezone);
    formData.append("latitude", LocationData.latitude);
    formData.append("longitude", LocationData.longitude);
    sexuality.forEach((item) => {
      formData.append("sexuality", item.value); // Append only the "value" field
    });
    formData.append("profile_image", selectedFile);
    formData.append("preferences", event.target.preferences.value);
    try {
      const res = await axios.post(
        "https://backend.amourette.no/api/register",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setspinner(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(res.data.message);
      var userResp = res.data.user;
      let userData = {
        id: userResp.id,
        email: userResp.email,
        status: userResp.status,
        token: userResp.token,
      };
      localStorage.setItem("userLogin", JSON.stringify(userData));

      navigate("/dashboard");
      // Handle success (e.g., redirect or show a success message)
    } catch (err) {
      console.log("dd");
      console.error(err.response);
      setspinner(false);
      setIsVisible(true);
      if (err.response && err.response.data && err.response.data.message) {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage(err.response.data.message); // Store the error message in state
      } else {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage("An unknown error occurred.");
      }
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
      }, 3500);
    }
  };
  const backbtn = async () => {
    setspinner(false);
    setIsVisible(true);
  };
  const handleSexualityChange = (selectedOptions) => {
    setSexuality(selectedOptions); // Handle the selected multiple options
  };
  const getLocationDetails = async () => {
    try {
      // Step 1: Get the user's public IP address
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ip = ipResponse.data.ip;

      // Step 2: Use ipapi to get latitude and longitude based on IP address
      const geoResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
      console.log(geoResponse.data);
      setLocationData(geoResponse.data);

      // Set the location details
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  useEffect(() => {
    getLocationDetails(); // Fetch location details on component mount
  }, []);
  return (
    <>
      <Header />

      <section className="regblock d-block">
        <div className="container-lg">
          <div className="row justify-content-end">
            <div className="col-md-6">
              {showPopSuccess && <SuccessPop message={successPopMessage} />}
              {showPopError && <ErrorPop message={errorPopMessage} />}
              <div className="regform d-flex flex-column gap-4">
                <form onSubmit={handleSubmit} method="post">
                  {/* 1st step */}
                  <div
                    className="stepbox"
                    style={{ display: isVisible ? "block" : "none" }}
                  >
                    <div className="d-flex flex-column gap-3 regtitle mb-5">
                      <div className="d-flex justify-content-between">
                        <h2>Register</h2>
                        <h2>1/2</h2>
                      </div>
                      <p>
                        Ad et deserunt ea fugiat exercitation. Dolor veniam anim
                        labore exercitation incididunt excepteur mollit occaecat
                        eu irure officia occaecat.
                      </p>
                    </div>
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="username">
                            Username <span className="text-white">*</span>
                          </label>
                          <input
                            required
                            type="text"
                            placeholder="Your name"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <span className="text-danger">{err_username}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="email">
                            E-mail <span className="text-white">*</span>
                          </label>
                          <input
                            required
                            type="email"
                            placeholder="You e-mail"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="text-danger">{err_email}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="password">
                            Password <span className="text-white">*</span>
                          </label>
                          <input
                            type="password"
                            placeholder="You password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <span className="text-danger">{err_pass}</span>
                        </div>
                      </div>
                      <div className="col-12 col-md-7">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="birthday">
                            Birthday <span className="text-white">*</span>
                          </label>
                          <div className="d-flex gap-1 dmybox">
                            <input
                              type="text"
                              placeholder="Date"
                              name="text"
                              minLength="2"
                              maxLength="2"
                              value={Valuedate}
                              onChange={handleChangedate}
                              required
                            />
                            <input
                              type="text"
                              placeholder="Month"
                              name="month"
                              minLength="2"
                              maxLength="2"
                              value={Valuemonth}
                              onChange={handleChangemonth}
                              required
                            />
                            <input
                              type="text"
                              placeholder="Year"
                              name="year"
                              minLength="4"
                              maxLength="4"
                              value={Valueyear}
                              onChange={handleChangeyear}
                              required
                            />
                          </div>
                          <span class="text-danger">{err_date}</span>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="button"
                          onClick={toggleVisibility}
                          className="regbtnsubmit"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* 2nd step form */}
                  <div
                    className="stepbox"
                    style={{ display: isVisible ? "none" : "block" }}
                  >
                    <div className="d-flex flex-column gap-3 regtitle mb-5">
                      <div className="d-flex justify-content-between">
                        <h2>Register</h2>
                        <h2>2/2</h2>
                      </div>
                    </div>
                    <div className="row gy-4">
                      <div className="col-12">
                        <div
                          className="uploadfile rel bgload"
                          style={{ width: "200px" }}
                        >
                          <input
                            type="file"
                            required
                            name="profile_image"
                            onChange={handleFileChange}
                            className="fileload"
                          />
                          <div className="upload_image">
                            {selectedFile && (
                              <img
                                className="w-100 h-100"
                                src={URL.createObjectURL(selectedFile)} // Create a URL for the selected image
                                alt="Selected"
                              />
                            )}
                          </div>
                          <div className="inload" style={{ height: "100px" }}>
                            <div>
                              <BsCloudUpload style={{ fontSize: "24px" }} />
                              <h3 style={{ fontSize: "14px" }}>
                                Upload image *
                              </h3>
                            </div>
                          </div>
                          {errorMessage && (
                            <p className="text-danger">{errorMessage}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="gender">
                            Gender <span className="text-white">*</span>
                          </label>
                          <select
                            required
                            name="gender"
                            value={gender}
                            onChange={(e) => setGenderr(e.target.value)}
                          >
                            <option value="">Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Trans">Trans</option>
                            <option value="Trans (MTF)">Trans (MTF)</option>
                            <option value="Trans (FTM)">Trans (FTM)</option>
                            <option value="Couple">Couple</option>{" "}
                            {/* Added Couple option */}
                            <option value="Non-binary">Non-binary</option>
                          </select>
                          {err_gender && (
                            <span className="text-danger">{err_gender}</span>
                          )}{" "}
                          {gender === "Male" && (
                            <Select
                              isMulti
                              options={maleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select sexuality"
                            />
                          )}
                          {gender === "Female" && (
                            <Select
                              isMulti
                              options={femaleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select sexuality"
                            />
                          )}
                          {gender === "Couple" && (
                            <Select
                              isMulti
                              options={coupleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select sexuality"
                            />
                          )}
                          {/* Display error message */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="preferences">Preferences</label>
                          <input
                            type="text"
                            name="preferences"
                            placeholder="Preferences"
                          />
                          {/* <select name="preferences">
                            <option value="">--Select--</option>
                            <option value="Preferences">Preferences</option>
                            <option value="Preferences">Preferences</option>
                          </select> */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="profile_type">Profile type</label>
                          <div className="listbox">
                            <label className="cbox">
                              Single profile
                              <input
                                type="radio"
                                name="profile_type"
                                value="Single profile"
                              />
                              <span className="checkmark"></span>
                            </label>

                            <label className="cbox">
                              Couples profile
                              <input
                                type="radio"
                                name="profile_type"
                                value="Couples profile"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="first_pet">
                            Choose a security question? (Optional){" "}
                          </label>
                          <select
                            value={question}
                            name="security_question"
                            onChange={(e) => setquestion(e.target.value)}
                          >
                            <option value="">Select a question</option>

                            <option value="first_pet">
                              What is the name of your first pet?
                            </option>
                            <option value="mothers_maiden_name">
                              What is your mother's maiden name?
                            </option>
                            <option value="middle_school">
                              What school did you attend in middle school?
                            </option>
                            <option value="first_teacher">
                              What was the name of your first teacher?
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="Answer">Your Answer</label>
                          <input
                            type="text"
                            placeholder="Your Answer"
                            name="answer"
                            value={answer} // Assuming 'answer' is a state variable
                            onChange={(e) => setanswer(e.target.value)}
                            required={question !== ""} // Only required if a question is selected
                          />
                          <span className="text-danger">{err_pass}</span>
                        </div>
                      </div>
                      <div className="col-12 rel ">
                        <div className="d-flex justify-content-center align-items-center reg_form_spin">
                          {spinner && (
                            <div
                              className="spinner-border text-success"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <button
                            type="button"
                            onClick={backbtn}
                            className="regbtnsubmit"
                          >
                            Back
                          </button>
                          <button type="submit" className="regbtnsubmit">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
