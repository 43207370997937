import React, { useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import axios from "axios";
export const Preference = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();
  var apiUrl = "https://backend.amourette.no/api/notifications/";
  var apiUrl1 = "https://backend.amourette.no/api/";
  const [isCheckedfriend_request, setisCheckedfriend_request] = useState(false);
  const [isCheckednew_message, setisCheckednew_message] = useState(false);
  const [isCheckedevent_group, setisCheckedevent_group] = useState(false);
  const [isCheckednew_created, setisCheckednew_created] = useState(false);
  const [isCheckednews_update, setisCheckednews_update] = useState(false);
  const [isCheckednews_profile, setisCheckednews_profile] = useState(false);
  const [isactive_album, setisactive_album] = useState("");
  const [isCheckednews_special_offers, setisCheckedspecial_offers] =
    useState(false);
  const [isCheckednews_other_users, setisCheckednews_other_users] =
    useState(false);
  const [isCheckednews_other_usersno, setisCheckednews_other_usersno] =
    useState(false);
  const [isCheckednews_active_status, setisCheckednews_active_status] =
    useState(false);
  const [isCheckednews_active_statusno, setisCheckednews_active_statusno] =
    useState(false);
  const [UserData, setUserData] = useState("");
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };
  const { t } = useTranslation();
  useEffect(() => {
    const checkuser = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        id: userLogin.id,
        email: userLogin.email,
      };
      try {
        const res = await axios.post(apiUrl1 + "getUserDetail", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        console.log("sd");
        if (res.data.result === "") {
          localStorage.removeItem("userLogin");
          navigate("/login");
        }

        var checknoti = res.data.result;
        console.log(checknoti);
        console.log("fg");
        if (checknoti.notification_friend_request === "Yes") {
          setisCheckedfriend_request(true);
        } else {
          setisCheckedfriend_request(false);
        }
        if (checknoti.notification_message === "Yes") {
          setisCheckednew_message(true);
        } else {
          setisCheckednew_message(false);
        }
        if (checknoti.notification_group_event === "Yes") {
          setisCheckedevent_group(true);
        } else {
          setisCheckedevent_group(false);
        }

        if (checknoti.setisCheckednew_created === "Yes") {
          setisCheckednew_created(true);
        } else {
          setisCheckednew_created(false);
        }

        if (checknoti.notification_news_update === "Yes") {
          setisCheckednews_update(true);
        } else {
          setisCheckednews_update(false);
        }

        if (checknoti.notification_news_profile === "Yes") {
          setisCheckednews_profile(true);
        } else {
          setisCheckednews_profile(false);
        }
        if (checknoti.notification_special_offers === "Yes") {
          setisCheckedspecial_offers(true);
        } else {
          setisCheckedspecial_offers(false);
        }

        if (checknoti.notification_show_activity === "Yes") {
          setisCheckednews_active_status(true);
          setisCheckednews_active_statusno(false);
        }
        if (checknoti.notification_show_activity === "No") {
          setisCheckednews_active_status(false);
          setisCheckednews_active_statusno(true);
        }

        if (checknoti.notification_other_users === "No") {
          setisCheckednews_other_users(false);
          setisCheckednews_other_usersno(true);
        }
        if (checknoti.notification_other_users === "Yes") {
          setisCheckednews_other_users(true);
          setisCheckednews_other_usersno(false);
        }
        setUserData(res.data.result);
        setisactive_album(checknoti.album_setting_status);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    checkuser();
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  const handleFriendRequest = async (event) => {
    setisCheckedfriend_request(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationfriend_request",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handlenew_message = async (event) => {
    setisCheckednew_message(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationnew_message",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleevent_group = async (event) => {
    setisCheckedevent_group(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationevent_group",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handlenew_created = async (event) => {
    setisCheckednew_created(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationnew_created",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handlenews_update = async (event) => {
    setisCheckednews_update(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    console.log(formData);
    try {
      const res = await axios.post(
        apiUrl + "notificationnews_update",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handlenews_profile = async (event) => {
    setisCheckednews_profile(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationnews_profile",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handlespecial_offers = async (event) => {
    setisCheckedspecial_offers(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
    } else {
      var ch = "No";
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationspecial_offers",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleother_users = async (event) => {
    setisCheckednews_other_users(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
      setisCheckednews_other_usersno(false);
      setisCheckednews_other_users(true);
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationother_users",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleother_usersno = async (event) => {
    // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "No";
      setisCheckednews_other_usersno(true);
      setisCheckednews_other_users(false);
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationother_users",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleactive_status = async (event) => {
    setisCheckednews_active_status(event.target.checked); // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "Yes";
      setisCheckednews_active_statusno(false);
      setisCheckednews_active_status(true);
    }
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationactive_users",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleactive_statusno = async (event) => {
    // Updates the state with the checkbox's checked value
    if (event.target.checked === true) {
      var ch = "No";
      setisCheckednews_active_statusno(true);
      setisCheckednews_active_status(false);
    }
    console.log(event.target.checked);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: ch,
    };
    try {
      const res = await axios.post(
        apiUrl + "notificationactive_users",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleactive_status_album = async (e) => {
    var al = e.target.value;
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      check: al,
    };
    console.log(al);
    setisactive_album(al);
    try {
      const res = await axios.post(apiUrl + "album_statussave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="d-flex flex-column gap-4 mship">
        <h1 className="shiptitle">{t("FriendSearchPage_Prefrences")}</h1>
        <div className="d-flex flex-column gap-3">
          <div className="blockv d-flex flex-column gap-4">
            <h3>{t("E-mail_notifications")}</h3>
            <div className="d-flex flex-column gap-3">
              <h4>{t("Profile_activity")}</h4>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  {t("When_receiving_a_friend_request")}
                  <input
                    type="checkbox"
                    checked={isCheckedfriend_request} // This binds the state to the input
                    onChange={handleFriendRequest}
                    name="notification_friend_request"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("When_receiving_a_new_message")}
                  <input
                    type="checkbox"
                    checked={isCheckednew_message} // This binds the state to the input
                    onChange={handlenew_message}
                    name="notification_new_message"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>{t("Groups_and_events")}</h4>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  {t("When_receiving_orevent")}
                  <input
                    type="checkbox"
                    checked={isCheckedevent_group} // This binds the state to the input
                    onChange={handleevent_group}
                    name="notification_event_group"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("When_a_new_created")}
                  <input
                    type="checkbox"
                    checked={isCheckednew_created} // This binds the state to the input
                    onChange={handlenew_created}
                    name="notification_new_created"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>{t("Other")}</h4>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  {t("When_a_new_profile")}

                  <input
                    type="checkbox"
                    checked={isCheckednews_profile} // This binds the state to the input
                    onChange={handlenews_profile}
                    name="notification_news_profile"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("News_and_updates")}
                  <input
                    type="checkbox"
                    checked={isCheckednews_update} // This binds the state to the input
                    onChange={handlenews_update}
                    name="notification_news_update"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("Promotions_or_special_offers")}
                  <input
                    type="checkbox"
                    checked={isCheckednews_special_offers} // This binds the state to the input
                    onChange={handlespecial_offers}
                    name="notification_special_offers"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <h3>{t("Privacy_Policy")}</h3>
            <div className="d-flex flex-column gap-3">
              <h4>{t("Show_activity_status")}</h4>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  {t("Yes")}
                  <input
                    type="radio"
                    checked={isCheckednews_active_status} // This binds the state to the input
                    onChange={handleactive_status}
                    name="notification_active_status"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("No")}
                  <input
                    type="radio"
                    checked={isCheckednews_active_statusno} // This binds the state to the input
                    onChange={handleactive_statusno}
                    name="notification_active_status"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>{t("Show_my_friends_to_other_users")}</h4>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  {t("Yes")}
                  <input
                    type="radio"
                    checked={isCheckednews_other_users} // This binds the state to the input
                    onChange={handleother_users}
                    name="notification_other_users"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  {t("No")}
                  <input
                    type="radio"
                    checked={isCheckednews_other_usersno} // This binds the state to the input
                    onChange={handleother_usersno}
                    name="notification_other_users"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <h3>Privacy Setting</h3>
            <div className="d-flex flex-column gap-3">
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Public
                  <input
                    type="radio"
                    checked={isactive_album === "Public"} // This binds the state to the input
                    onChange={handleactive_status_album}
                    name="isactive_album"
                    value="Public"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  Friends Only
                  <input
                    type="radio"
                    checked={isactive_album === "Friends_only"} // This binds the state to the input
                    onChange={handleactive_status_album}
                    name="isactive_album"
                    value="Friends_only"
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  Private
                  <input
                    type="radio"
                    checked={isactive_album === "Private"} // This binds the state to the input
                    onChange={handleactive_status_album}
                    name="isactive_album"
                    value="Private"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
