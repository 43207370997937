import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
import Chart from "chart.js/auto"; // Import Chart.js
export default function UsersDetail() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [UserDetail, setUserDetail] = useState("");
  const [SaleRevenuelable, setSaleRevenuelable] = useState([]);
  const [Saledata, setSale] = useState([]);
  const [Revenuedata, setRevenue] = useState([]);
  const [saleRevenuelableyear, setSaleRevenuelableyear] = useState([]);
  const [saleYear, setSaleYear] = useState([]);
  const [revenueYear, setRevenueYear] = useState([]);
  const [totalgroups, settotalgroups] = useState([]);
  const [totalevents, settotalevents] = useState([]);
  const [totalforum, settotalforum] = useState([]);
  const [totalspeeddate, settotalspeeddate] = useState([]);
  const [totaljoinedgroup, settotaljoinedgroup] = useState([]);
  const [totaljoinedevents, settotaljoinedevents] = useState([]);
  const [totalsentmessage, settotalsentmessage] = useState([]);
  const [totalreceivermessage, settotalreceivermessage] = useState([]);
  const [sdatalabelYear, setsdatalabelYear] = useState([]);
  const [sdataYear, setsdataYear] = useState([]);
  const [datachartYear, setdatachartYear] = useState([]);
  const [datachartYearl, setdatachartYearl] = useState([]);
  const [datachartYearDoug, setdatachartYearDoug] = useState([]);
  const [datachartYearlDoug, setdatachartYearlDoug] = useState([]);
  const [LatestMessagesent, setLatestMessagesent] = useState([]);
  const [totalgallery, settotalgallery] = useState([]);
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  const canvasRef3 = useRef(null);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  useEffect(() => {
    const script66 = document.createElement("script");
    script66.src = "https://cdn.jsdelivr.net/npm/chart.js";
    script66.async = true;
    document.body.appendChild(script66);

    script66.onload = () => {
      const ctx1 = canvasRef.current.getContext("2d");
      const ctx2 = canvasRef2.current.getContext("2d");

      // Optionally, destroy the existing chart if needed
      if (window.myChart1) {
        window.myChart1.destroy();
      }
      if (window.myChart2) {
        window.myChart2.destroy();
      }

      // Create the new chart for canvasRef (myChart1)
      window.myChart1 = new Chart(ctx1, {
        type: "bar",
        data: {
          labels: sdatalabelYear,
          datasets: sdataYear,
        },
        options: {
          responsive: true,
        },
      });

      // Create the new chart for canvasRef2 (myChart2)
      window.myChart2 = new Chart(ctx2, {
        type: "bar",
        data: {
          labels: datachartYearl,
          datasets: datachartYear,
        },
        options: {
          responsive: true,
        },
      });
    };

    return () => {
      document.body.removeChild(script66);
      // Clean up and destroy charts on component unmount
      if (window.myChart1) {
        window.myChart1.destroy();
      }
      if (window.myChart2) {
        window.myChart2.destroy();
      }
      if (window.myChart3) {
        window.myChart3.destroy();
      }
    };
  }, [
    Saledata,
    Revenuedata,
    sdatalabelYear,
    sdataYear,
    datachartYearl,
    datachartYear,
    datachartYearlDoug,
    datachartYearDoug,
  ]);

  // Create an array of numbers from min to max
  useEffect(() => {
    const getuserchartgraph = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getuserchartgraph", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        var respo = res.data.result;
        setsdataYear(respo.datasets);
        setsdatalabelYear(respo.labels);
        console.log("hj", respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getuserchartgraph();
  }, [UserDetail]);
  useEffect(() => {
    const getuserchartgraphYear = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(
          apiUrl + "getuserchartgraphYear",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        var respo = res.data.result;
        console.log("kjj");
        setdatachartYear(respo.datasets);
        setdatachartYearl(respo.labels);
        console.log(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getuserchartgraphYear();
  }, [UserDetail]);
  useEffect(() => {
    const getuserchartgraphDoughnut = async () => {
      let formData = {
        user_id: UserDetail.id,
      };

      try {
        const res = await axios.post(
          apiUrl + "getuserchartgraphDoughnut",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        // Extract datasets and labels from the response
        const { labels, datasets } = res.data.result;

        console.log("Chart Data:", res.data);
        console.log("Labels:", labels);
        console.log("Datasets:", datasets);

        // Set state
        setdatachartYearlDoug(labels);
        setdatachartYearDoug(datasets);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response Error:", err.response);
        } else if (err.request) {
          console.error("Request Error:", err.request);
        } else {
          console.error("Error Message:", err.message);
        }
      }
    };

    getuserchartgraphDoughnut();
  }, [UserDetail]);
  useEffect(() => {
    if (datachartYearDoug && datachartYearlDoug) {
      const ctx3 = canvasRef3.current.getContext("2d");
      // const ctx3 = document.getElementById("myChart3").getContext("2d");

      // Create a Doughnut Chart
      window.myChart3 = new Chart(ctx3, {
        type: "doughnut",
        data: {
          labels: datachartYearlDoug,
          datasets: datachartYearDoug, // Datasets dynamically set
        },
        options: {
          responsive: true,
        },
      });
    }
  }, [datachartYearDoug, datachartYearlDoug]);
  useEffect(() => {
    const getusertotalgroups = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getusertotalgroups", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalgroups(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getusertotalgroups();
  }, [UserDetail]);

  useEffect(() => {
    const getusertotalevents = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getusertotalevents", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalevents(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getusertotalevents();
  }, [UserDetail]);
  useEffect(() => {
    const getusertotalforum = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getusertotalforum", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        settotalforum(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getusertotalforum();
  }, [UserDetail]);
  useEffect(() => {
    const getusertotalgallery = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getusertotalgallery", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        settotalgallery(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getusertotalgallery();
  }, [UserDetail]);

  useEffect(() => {
    const getusertotalspeeddate = async () => {
      let formData = {
        user_id: UserDetail.id,
      };
      try {
        const res = await axios.post(
          apiUrl + "getusertotalspeeddate",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          }
        );
        var respo = res.data.result;
        console.log(respo);
        settotalspeeddate(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getusertotalspeeddate();
  }, [UserDetail]);
  useEffect(() => {
    const getJoinedGroups = async () => {
      let formData = {
        id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getJoinedGroups", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo.length);
        console.log("joi", UserDetail.id);
        settotaljoinedgroup(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getJoinedGroups();
  }, [UserDetail]);
  useEffect(() => {
    const getJoinedevents = async () => {
      let formData = {
        id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "getJoinedevents", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo.length);

        settotaljoinedevents(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getJoinedevents();
  }, [UserDetail]);
  useEffect(() => {
    const sentmessage = async () => {
      let formData = {
        id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "sentmessage", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo.length);

        settotalsentmessage(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    sentmessage();
  }, [UserDetail]);
  useEffect(() => {
    const receivemessage = async () => {
      let formData = {
        id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "receivemessage", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log("k", respo);
        console.log(respo.length);

        settotalreceivermessage(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    receivemessage();
  }, [UserDetail]);
  useEffect(() => {
    const lastestmessagesent = async () => {
      let formData = {
        id: UserDetail.id,
      };
      try {
        const res = await axios.post(apiUrl + "lastestmessagesent", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;

        setLatestMessagesent(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    lastestmessagesent();
  }, [UserDetail]);
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4 speed_txt">
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users-cog fa-3x text-primary"></i>
                  <div className="ms-3">
                    <p className="mb-2">Total Groups</p>
                    <h6 className="mb-0">{totalgroups.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-calendar-alt fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Events</p>
                    <h6 className="mb-0">{totalevents.length}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-comments fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Forum</p>
                    <h6 className="mb-0">{totalforum.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-images fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Gallery</p>
                    <h6 className="mb-0">{totalgallery.length}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-heart fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Speed date</p>
                    <h6 className="mb-0">{totalspeeddate.length}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4 speed_txt">
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users-cog fa-3x text-primary"></i>
                  <div className="ms-3">
                    <p className="mb-2">Total Joined Groups</p>
                    <h6 className="mb-0">{totaljoinedgroup.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-calendar-alt fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Joined Events</p>
                    <h6 className="mb-0">{totaljoinedevents.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-paper-plane fa-3x text-primary"></i>
                  <div className="ms-3">
                    <p className="mb-2">Total Sent Messages</p>
                    <h6 className="mb-0">{totalsentmessage.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-inbox fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Receive Messages</p>
                    <h6 className="mb-0">{totalreceivermessage.length}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                      }).format(new Date())}{" "}
                    </h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef} id="worldwide-sales"></canvas>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Sale & Revenue</h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef2} id="sales-revenue"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Latest Message Sent</h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <div className="table-responsive">
                    <table className="table text-start align-middle table-bordered table-hover mb-0">
                      <thead>
                        <tr className="text-dark">
                          <th scope="col">Sender</th>
                          <th scope="col">Receiver</th>
                          <th scope="col">Message</th>
                          <th scope="col">Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LatestMessagesent.length > 0 ? (
                          LatestMessagesent.map((msg, index) => (
                            <tr key={index}>
                              <td>{msg.sender}</td>
                              <td>{msg.receiver}</td>
                              <td>{msg.message}</td>
                              <td>{formateTime(msg.date)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No result found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Total</h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef3} id="doughnut-chart"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
