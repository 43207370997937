import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ColorToggle from "../components/ColorToggle";
import { UpgradeMembership } from "../components/UpgradeMembership";
import Notification from "../components/Notification";
import axios from "axios";
import logo from "../assets/images/logo.png";
import icon1 from "../assets/images/menuicon.png";
import icon2 from "../assets/images/bell.png";
import icon3 from "../assets/images/chat.png";
import me1 from "../assets/images/me1.png";
import me2 from "../assets/images/me2.png";
import me3 from "../assets/images/me3.png";
import me4 from "../assets/images/me4.png";
import me5 from "../assets/images/me5.png";
import me6 from "../assets/images/me6.png";
import me7 from "../assets/images/me7.png";
import pe1 from "../assets/images/pic.png";
import se1 from "../assets/images/se1.png";
import se2 from "../assets/images/se2.png";
import se3 from "../assets/images/se3.png";
import { useNavigate } from "react-router-dom";
import ErrorPop from "../components/ErrorPop";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Headertwosetting({ refreshNotifications }) {
  var apiUrl2 = "https://backend.amourette.no/api/profile/";
  var apiUrl3 = "https://backend.amourette.no/api/members/";
  // State to toggle menubox visibility
  const [isReceivedMessage, setReceivedMessage] = useState([]);
  const [searchInbox, setSearchInbox] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isshowOnline, setshowOnline] = useState("");
  const [showPopErrorr, setShowPopErrorr] = useState(false);
  const [errorPopMessagee, setPoperrorMessagee] = useState("");
  // Function to toggle menu visibility
  const toggleMenu = (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
    getReceivedMessage();
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const [UserData, setUserData] = useState("");
  const [activeMenu, setActiveMenu] = useState(null);

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true); // Set sticky if scrolled down
    } else {
      setIsSticky(false); // Remove sticky if at the top
    }
  };
  const navigate = useNavigate();
  var apiUrl = "https://backend.amourette.no/api/";
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no");

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log(receivedMessage);
        const storedUsername = localStorage.getItem("userLogin");
        const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

        if (receivedMessage.event === "friendrequestacceptnotification") {
          if (
            Array.isArray(receivedMessage.user_id) &&
            receivedMessage.user_id.some((user) => user.id === userLogin.id)
          ) {
            // Trigger notification on new message

            // Trigger refresh notifications function
            refreshNotifications();
          }
        }
        if (receivedMessage.event === "gallerynotification") {
          // Check if user_id is an array and contains a matching userLogin.id
          if (
            Array.isArray(receivedMessage.user_id) &&
            receivedMessage.user_id.some((user) => user.id === userLogin.id)
          ) {
            // If a match is found, trigger the notification
            refreshNotifications();
          }
        }
        if (receivedMessage.event === "grouprequest_acceptnotification") {
          // Check if user_id is an array and contains a matching userLogin.id
          if (
            Array.isArray(receivedMessage.user_id) &&
            receivedMessage.user_id.some((user) => user.id === userLogin.id)
          ) {
            // If a match is found, trigger the notification
            refreshNotifications();
          }
        }
        if (receivedMessage.event === "eventrequest_acceptnotification") {
          // Check if user_id is an array and contains a matching userLogin.id
          if (
            Array.isArray(receivedMessage.user_id) &&
            receivedMessage.user_id.some((user) => user.id === userLogin.id)
          ) {
            // If a match is found, trigger the notification
            refreshNotifications();
          }
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
      console.log("WebSocket connection closed");
    };
  }, [refreshNotifications]);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log("dff");
    console.log(userLogin);

    if (userLogin === null || userLogin === "null") {
      localStorage.removeItem("userLogin");
      navigate("/login");
    }
    checkuser();
    getReceivedMessage();
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const refreshpage = async () => {};
  const setOnlineStatus = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    if (storedUsername) {
      const userLogin = JSON.parse(storedUsername);
      const formData = { user_id: userLogin.id };

      try {
        const res = await axios.post(apiUrl2 + "/setonline", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        setshowOnline(res.data.onlineCount);
      } catch (err) {
        console.error(
          "Error setting user online:",
          err.response ? err.response.data : err.message
        );
      }
    }
  };

  // Function to call API when user goes offline
  const setOfflineStatus = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    if (storedUsername) {
      const userLogin = JSON.parse(storedUsername);
      const formData = { user_id: userLogin.id };

      try {
        await axios.post(apiUrl2 + "/setoffline", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      } catch (err) {
        console.error(
          "Error setting user offline:",
          err.response ? err.response.data : err.message
        );
      }
    }
  };

  useEffect(() => {
    // Set the initial online status when the component mounts
    if (isOnline) {
      setOnlineStatus();
    } else {
      setOfflineStatus();
    }

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []); // Empty dependency array to run only on mount

  useEffect(() => {
    // Call the respective status function when online status changes
    if (isOnline) {
      setOnlineStatus();
    } else {
      setOfflineStatus();
    }
  }, [isOnline]);
  const getReceivedMessage = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      user_id: userLogin.id,
    };

    try {
      const res = await axios.post(apiUrl2 + "getReceivedMessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setReceivedMessage(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const handleChangeInbox = async (event) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    setSearchInbox(event.target.value);
    let formData = {
      search: event.target.value,
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(
        apiUrl2 + "getReceivedMessageSearch",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
      setReceivedMessage(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const checkuser = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      email: userLogin.email,
      id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getUserDetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("sd");
      if (res.data.result === "") {
        localStorage.removeItem("userLogin");
        navigate("/login");
      }
      console.log(res.data);
      setUserData(res.data.result);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  return (
    <>
      {showPopErrorr && <ErrorPop message={errorPopMessagee} />}
      <header className={`header ${isSticky ? "sticky" : ""}`}>
        <div className="container-lg">
          <div className="row gy-3 align-items-center">
            <div className="col-md-3">
              <Link to="/" className="toplogo d-inline-block fulw">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="col-md-3">
              <div className="d-block topsearch">
                <form action="">
                  <input type="text" placeholder={t("headerSearch")} />
                </form>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex justify-content-center justify-content-md-end">
                <div className="d-flex gap-4 align-items-center">
                  <div className="d-flex gap-3 rel">
                    <div className="">
                      <button
                        type="button"
                        className={`topmenu ${
                          activeMenu === "menu1" ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("menu1")}
                      >
                        <img src={icon1} alt="userp" />
                      </button>
                      <div
                        className="menubox"
                        style={{
                          display: activeMenu === "menu1" ? "block" : "none",
                        }}
                      >
                        <div className="d-flex flex-column gap-4 ">
                          <h3>{t("Menu")}</h3>
                          <div className="d-flex flex-column gap-3">
                            <Link to="/friendsearch" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me1} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("LeftMenu_Members")}</h4>
                                <p>{t("view_and_browser")}</p>
                              </div>
                            </Link>
                            <Link
                              to="/receivedmessages"
                              className="d-flex gap-3"
                            >
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me2} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("Messages")}</h4>
                                <p>{t("view_received_and_sent")}</p>
                              </div>
                            </Link>
                            <Link to="/gallery" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me3} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("Gallery")}</h4>
                                <p>{t("view_and_upload_images")}</p>
                              </div>
                            </Link>
                            <Link to="/groups" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me4} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("Groups")}</h4>
                                <p>{t("Browse_and_create_groups")}</p>
                              </div>
                            </Link>
                            <Link to="/events" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me5} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("LeftMenu_Events")}</h4>
                                <p>{t("Browse_and_create_events")}</p>
                              </div>
                            </Link>
                            <Link to="/" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me6} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("LeftMenu_Speedating")}</h4>
                                <p>{t("Meet_new_people")}</p>
                              </div>
                            </Link>
                            <Link to="/" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu fulw">
                                  <img src={me7} alt="userpic" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{t("Forum")}</h4>
                                <p>{t("Browse_and_create_forum_threads")}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Notification refresh={refreshNotifications} />
                    <div className="">
                      <button
                        type="button"
                        className={`topmenu ${
                          activeMenu === "menu3" ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("menu3")}
                      >
                        <img src={icon3} alt="userp" />
                      </button>
                      <div
                        className="menubox"
                        style={{
                          display: activeMenu === "menu3" ? "block" : "none",
                        }}
                      >
                        <div className="d-flex flex-column gap-4 ">
                          <h3>{t("Inbox")}</h3>
                          <div className="d-block inboxser">
                            <form action="">
                              <input
                                type="text"
                                value={searchInbox}
                                onChange={handleChangeInbox}
                                placeholder="Search inbox..."
                              />
                            </form>
                          </div>
                          <div className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column gap-3 scroll_bar scrollyover">
                              {isReceivedMessage.length === 0 ? (
                                <p>{t("No_inbox_found")}</p>
                              ) : (
                                isReceivedMessage.map((message) => (
                                  <Link
                                    to="javascript:void(0)"
                                    className="d-flex gap-3"
                                    key={message.id}
                                  >
                                    <div className="flex-shrink-0">
                                      <div className="iconmenu ofit rounded-circle overflow-hidden">
                                        {message.sender_profile ? (
                                          <img
                                            src={message.sender_profile}
                                            alt="userpic"
                                          />
                                        ) : (
                                          <img
                                            src={require("../assets/images/thumb.jpg")}
                                            alt="userpic"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <div className="d-flex justify-content-between">
                                        <h4>{message.sender_username}</h4>
                                        <p className="">
                                          {formateTime(message.date)}
                                        </p>
                                      </div>
                                      <h6 className="minimsg">
                                        {message.message}
                                      </h6>
                                    </div>
                                  </Link>
                                ))
                              )}
                            </div>

                            {/* <div className="d-block morebtn">
                              <Link to="/">View more</Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className={`topmenu rounded-circle overflow-hidden p-0 ${
                          activeMenu === "menu4" ? "active" : ""
                        }`}
                        onClick={() => toggleMenu("menu4")}
                      >
                        <img
                          src={UserData.profile_image}
                          alt="userp"
                          className="rounded-circle overflow-hidden"
                        />
                      </button>
                      <div
                        className="menubox"
                        style={{
                          display: activeMenu === "menu4" ? "block" : "none",
                        }}
                      >
                        <div className="d-flex flex-column gap-4 ">
                          <h3>{t("Profile_and_settings")}</h3>
                          <div className="d-flex flex-column gap-3">
                            <Link to="/editprofile" className="d-flex gap-3">
                              <div className="flex-shrink-0">
                                <div className="iconmenu ofit rounded-circle overflow-hidden">
                                  {UserData.profile_image ? ( // Display the image URL if available
                                    <img
                                      src={UserData.profile_image} // Use the URL created from the file
                                      alt="userpic"
                                    />
                                  ) : (
                                    <img
                                      src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                      alt="userpic"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h4>{UserData.username}</h4>
                                <p>{t("Edit_your_profile")}</p>
                              </div>
                            </Link>

                            <div className="d-block morebtn">
                              <button type="button" onClick={openPopup}>
                                {t("UPGRADE_MEMBERSHIP")}
                              </button>
                            </div>
                            <div className="d-flex flex-column gap-3 helpset pt-3">
                              <Link to="/setting" className="d-flex gap-3">
                                <div className="flex-shrink-0">
                                  <div className="iconmenu fulw">
                                    <img src={se1} alt="userpic" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Settings")}</h4>
                                  <p>{t("Site_and_profile_settings")}</p>
                                </div>
                              </Link>
                              <Link to="/helpsupport" className="d-flex gap-3">
                                <div className="flex-shrink-0">
                                  <div className="iconmenu fulw">
                                    <img src={se3} alt="userpic" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Help_and_support")}</h4>
                                  <p>{t("Contact_us_FAQ_and_more")}</p>
                                </div>
                              </Link>
                              <Link to="/logout" className="d-flex gap-3">
                                <div className="flex-shrink-0">
                                  <div className="iconmenu fulw">
                                    <img src={se2} alt="userpic" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Log_out")}</h4>
                                  <p>{t("See_you_later")}</p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <select
                        onClick={changeLanguage}
                        defaultValue={i18n.language}
                      >
                        <option value="en">English</option>
                        <option value="no">Norwegian</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <UpgradeMembership
        isOpen={isPopupOpen}
        onClose={closePopup}
      ></UpgradeMembership>
      <ColorToggle />
    </>
  );
}

export default Headertwosetting;
