import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../assets/images/pic.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../i18n"; // Make sure i18n is imported properly
export const Rightsidebar = () => {
  var apiUrl2 = "https://backend.amourette.no/api/profile/";
  const [isshowOnline, setshowOnline] = useState("0");
  const [isshowMember, setshowMember] = useState("0");
  const [isshowImages, setshowImages] = useState("0");
  const [isshowGroup, setshowGroups] = useState("0");
  const [isshowEvent, setshowEvents] = useState("0");
  const [isshowVisit, setshowVisit] = useState([]);

  useEffect(() => {
    const gettotalGroups = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        user_id: userLogin.id,
      };

      try {
        const res = await axios.post(apiUrl2 + "gettotalGroups", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data;
        console.log(respo);
        setshowGroups(respo.groupsCount);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          //console.error("Request data:", err.request);
        } else {
          // console.error("Error message:", err.message);
        }
      }
    };
    const gettotalEvents = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        user_id: userLogin.id,
      };

      try {
        const res = await axios.post(apiUrl2 + "gettotalEvents", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data;
        console.log(respo);
        setshowEvents(respo.eventsCount);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          //console.error("Request data:", err.request);
        } else {
          // console.error("Error message:", err.message);
        }
      }
    };
    const gettotalImages = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        user_id: userLogin.id,
      };

      try {
        const res = await axios.post(apiUrl2 + "gettotalImages", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data;
        console.log(respo);
        setshowImages(respo.imagesCount);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          //console.error("Request data:", err.request);
        } else {
          // console.error("Error message:", err.message);
        }
      }
    };
    const getvisitprofile = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        user_id: userLogin.id,
      };

      try {
        const res = await axios.post(apiUrl2 + "getvisitprofile", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        setshowVisit(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          //console.error("Request data:", err.request);
        } else {
          // console.error("Error message:", err.message);
        }
      }
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(async () => {
      gettotalOnline();
    }, 1000); // Run every 60 seconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const gettotalOnline = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      user_id: userLogin.id,
    };

    try {
      const res = await axios.post(apiUrl2 + "gettotalOnline", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setshowOnline(respo.onlineCount);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const gettotalMembers = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      user_id: userLogin.id,
    };

    try {
      const res = await axios.post(apiUrl2 + "gettotalMembers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setshowMember(respo.onlineCount);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };

  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  return (
    <>
      <div className="d-flex flex-column leftbar">
        <div className="d-flex flex-column  visitedlast">
          <h5 className="grouptitle mb-4">{t("RightMenu_LastVisited")}</h5>
          <div className="d-flex flex-column gap-3 scroll_bar visitscroll">
            {isshowVisit.length === 0 ? (
              <p>{t("RightMenu_NovisitFound")}.</p>
            ) : (
              isshowVisit.map((visit, index) => (
                <Link
                  to="/"
                  key={index}
                  className="d-flex gap-3 align-items-center listoficons"
                >
                  <div className="flex-shrink-0">
                    <div className="userpic ofit rounded-circle overflow-hidden">
                      <img
                        src={
                          visit.profile_image
                            ? visit.profile_image
                            : require("../assets/images/thumb.jpg")
                        }
                        alt="userpic"
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h4>{visit.username} visited your profile.</h4>
                    <h6>{formateTime(visit.date)}</h6>
                  </div>
                </Link>
              ))
            )}
          </div>
          {/* <div className="viewmore d-block mt-2">
            <Link to="/">View more</Link>
          </div> */}
        </div>
        <hr className="linehr" />
        <div className="d-flex flex-column gap-2">
          <h5 className="grouptitle mb-4">{t("RightMenu_Statistics")}</h5>
          <div className="d-flex flex-column gap-3 listnumber">
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Onlinenow")}</h4>
              <h4>{isshowOnline}</h4>
            </div>
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Newmembers")}</h4>
              <h4>0</h4>
            </div>
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Images")}</h4>
              <h4>{isshowImages}</h4>
            </div>
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Groups")}</h4>
              <h4>{isshowGroup}</h4>
            </div>
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Eventsandparties")}</h4>
              <h4>{isshowEvent}</h4>
            </div>
            <div className="d-flex justify-content-between">
              <h4>{t("RightMenu_Membersintotal")}</h4>
              <h4>{isshowMember}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
