import React, { useState, useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { FFmpeg } from "@ffmpeg/ffmpeg";
export const Createnewgallery = ({
  isOpen,
  onClose,
  children,
  LoginData,
  notification,
}) => {
  var apiUrl = "https://backend.amourette.no/api/members/";
  const [image, setImage] = useState(null);
  const [comments, setComments] = useState([]);
  const [UserData, setUserData] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [makeImageUse, setmakeImageUse] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [videoUrl, setVideoUrl] = useState(""); // for resized video
  const [fileType, setFileType] = useState("");
  const [restr_message, setrestr_message] = useState("");
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };
  var apiUrll = "https://backend.amourette.no/api/";
  // Empty dependency array to run this effect only once when the component mounts
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);

    const checkuser = async () => {
      let formData = {
        id: userLogin.id,
        email: userLogin.email,
      };

      try {
        const res = await axios.post(apiUrll + "getUserDetail", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });

        //console.log(res.data);
        setUserData(res.data.result);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    checkuser();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setspinner(true);
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("name", name);
      formData.append("description", description);
      formData.append("makeImageUse", makeImageUse);
      formData.append("user_id", LoginData.id);
      formData.append("fileType", "gallery");
      if (makeImageUse === true) {
        formData.append("image", UserData.profile_image);
      } else {
        formData.append("image", selectedFile);
      }

      const response = await axios.post(apiUrl + "gallerysave", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setspinner(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(response.data.message);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        window.location.reload();
        onClose();
      }, 800);
      //console.log("Event created:", response.data);
      setImage(null);
      setName("");
      setDescription("");
      setmakeImageUse(false);
      setSelectedFile(null);
      // Close the popup after submission
    } catch (error) {
      setspinner(false);
      setShowPopError(true);
      setTimeout(() => {
        setShowPopError(false);
        onClose();
      }, 800);
      setPoperrorMessage("Error creating esssvent:", error);
    }
  };
  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 2 MB
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image or video
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (!isImage && !isVideo) {
        setErrorMessage("Please upload a valid image or video file.");
        setSelectedFile(null);
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 5 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear previous error messages
      setErrorMessage("");
      setSelectedFile(file);
      setFileType(isImage ? "image" : "video");

      if (isVideo) {
        //const resizedVideoUrl = await resizeVideo(file);
        // setVideoUrl(resizedVideoUrl);
      }
    }
  };

  const resizeVideo = async (file) => {
    const ffmpeg = new FFmpeg({ log: true });

    // Load the ffmpeg library
    await ffmpeg.load();

    // Write the video to the ffmpeg virtual file system
    const videoFilePath = URL.createObjectURL(file);
    ffmpeg.FS(
      "writeFile",
      "input.mp4",
      await fetch(videoFilePath).then((res) => res.arrayBuffer())
    );

    // Resize video (you can adjust the width/height as per your requirement)
    await ffmpeg.run("-i", "input.mp4", "-vf", "scale=640:360", "output.mp4");

    // Read the output file from the virtual file system
    const data = ffmpeg.FS("readFile", "output.mp4");
    const videoBlob = new Blob([data.buffer], { type: "video/mp4" });
    const videoUrl = URL.createObjectURL(videoBlob);

    return videoUrl;
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const handleInputChangedescription = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  const setmakeImageUsechoose = (e) => {
    console.log(e);
    setmakeImageUse(e);
  };
  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        {showPopError && <ErrorPop message={errorPopMessage} />}
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop  d-flex flex-column gap-4">
            <h3>{t("Upload_new_images")}</h3>
            <form
              onSubmit={handleSubmit}
              action="javascript:void(0)"
              method="post"
            >
              <div className="row gy-3 align-items-end">
                <div className="col-md-6">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      required
                      name="file"
                      onChange={handleFileChange}
                      className="fileload"
                      disabled={false} // Modify this based on your condition
                    />
                    <div className="upload_image">
                      {/* Show image preview */}
                      {fileType === "image" && selectedFile && (
                        <img
                          className="w-100 h-100"
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected Image"
                        />
                      )}

                      {/* Show video preview */}
                      {fileType === "video" && selectedFile && (
                        <video className="w-100 h-100" controls>
                          <source
                            src={videoUrl || URL.createObjectURL(selectedFile)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>
                          Upload Image or Video *
                        </h3>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                {fileType === "image" && selectedFile && (
                  <div className="col-md-6">
                    <div className="listbox">
                      <label className="cbox">
                        Use profile picture
                        <input
                          type="checkbox"
                          checked={makeImageUse}
                          onChange={(e) =>
                            setmakeImageUsechoose(e.target.checked)
                          }
                          name="makeImagePrivate"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <input
                    type="text"
                    name="name"
                    required
                    onChange={handleInputChange}
                    placeholder={`${t("image_name")} *`}
                    value={name}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    required
                    name="description"
                    rows="8"
                    placeholder={`${t("image_description")} *`}
                    value={description}
                    onChange={handleInputChangedescription}
                  ></textarea>
                </div>
                {restr_message && (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    Your message contains restricted words. Please remove them
                  </span>
                )}
                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">{t("Loading")}...</span>
                    </div>
                  )}
                  <button type="submit">{t("Create_new_date")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
