import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import Accordion from "react-bootstrap/Accordion";
import { Footer } from "../components/Footer";
import Button from "react-bootstrap/Button";
import ChatBox from "../components/ChatBox";
import axios from "axios";
import { formatDate } from "date-fns";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "nouislider/dist/nouislider.css"; // Import the styles for noUiSlider
import noUiSlider from "nouislider";
export default function Friendsearch() {
  const navigate = useNavigate();
  const [searchTerm, setsearchTerm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [LoginData, setLoginData] = useState("");
  const [toid, settoid] = useState("");
  const [AllMembers, setAllMembers] = useState([]);
  const [birthdd, setbirthdd] = useState("");
  const [UserDetail, setUserDetail] = useState([]);
  const [AllEvents, setAllEvents] = useState([]);
  const [IsuserDetailForChat, setuserDetailForChat] = useState("");
  const [IsuserChatDetail, setuserChatDetail] = useState([]);
  const [to_id, setto_id] = useState("");
  const [selectedAgee, setSelectedAgee] = useState(18); // Initial age value
  const [selectedSubRegion, setSelectedSubRegion] = React.useState("");
  const [selectedTowns, setSelectedTowns] = useState([]);
  const [selectedSubRegions, setSelectedSubRegions] = useState({});
  const [selectedAge, setSelectedAge] = useState([18, 55]);
  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  var apiUrl = "https://backend.amourette.no/api/members/";
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    setLoginData(userLogin); // Update LoginData from localStorage
  }, []);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const getAllMembers = async () => {
      let formData = {
        user_id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrl + "getAllMembers", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.results;
        setAllMembers(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getAllMembers();
  }, [LoginData]);

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  useEffect(() => {
    const slider = document.getElementById("ageSlider");

    // Initialize noUiSlider
    noUiSlider.create(slider, {
      start: selectedAge, // Start values from the selectedAge state
      connect: true, // Connect the handles
      range: {
        min: 18,
        max: 59,
      },
      step: 1, // Step for each move
      tooltips: true,
      format: {
        to: function (value) {
          // Format value as an integer (no decimal places)
          return Math.round(value); // No decimals
        },
        from: function (value) {
          // Parse the value back to a number (for internal use)
          return parseInt(value);
        },
      },
    });

    // Update the state whenever the slider values change
    slider.noUiSlider.on("update", function (values) {
      // Map the values to integers and update state
      console.log(parseInt(values));
      setSelectedAge(values.map((val) => parseInt(val)));
    });

    return () => {
      // Clean up the slider when the component unmounts
      slider.noUiSlider.destroy();
    };
  }, []);
  const chatsectionUser = async (id) => {
    let formData = {
      user_id: LoginData.id,
      to_id: id,
    };
    settoid(id);
    getUserDetailForChat(id);
    try {
      const res = await axios.post(apiUrl + "getuserChatmessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log("getchat");
      setuserChatDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };
  const getUserDetailForChat = async (id) => {
    let formData = {
      id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getUserDetailMember", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      if (res.data.row.length > 0) {
        var respo = res.data.row[0];
        setuserDetailForChat(respo);
        console.log(respo);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };
  const getChatAfterSave = async () => {
    chatsectionUser(to_id);
  };
  const setSearchTerm = async (e) => {
    setsearchTerm(e);

    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      search: e,
      user_id: userLogin.id, // Sending as a string for the query
    };
    try {
      const res = await axios.post(apiUrl + "membersearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setAllMembers(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [selectedRegion, setSelectedRegion] = useState(null);
  const locations = {
    "Eastern Norway (Østlandet)": {
      Viken: [
        "Drammen",
        "Bærum",
        "Asker",
        "Moss",
        "Fredrikstad",
        "Sarpsborg",
        "Halden",
        "Lillestrøm",
        "Jessheim",
        "Kongsberg",
      ],
      Innlandet: [
        "Hamar",
        "Lillehammer",
        "Gjøvik",
        "Elverum",
        "Kongsvinger",
        "Tynset",
        "Trysil",
        "Rena",
      ],
      "Vestfold and Telemark": [
        "Tønsberg",
        "Sandefjord",
        "Larvik",
        "Skien",
        "Porsgrunn",
        "Notodden",
        "Rjukan",
        "Kragerø",
        "Bø in Telemark",
      ],
      Oslo: ["Oslo"],
    },
    "Western Norway (Vestlandet)": {
      Hordaland: ["Bergen", "Voss", "Odda", "Stord", "Os", "Leirvik"],
      "Sogn og Fjordane": [
        "Førde",
        "Sogndal",
        "Florø",
        "Årdal",
        "Stryn",
        "Nordfjordeid",
      ],
      Rogaland: [
        "Stavanger",
        "Sandnes",
        "Haugesund",
        "Egersund",
        "Bryne",
        "Sauda",
        "Jørpeland",
      ],
      "Møre og Romsdal": [
        "Ålesund",
        "Molde",
        "Kristiansund",
        "Åndalsnes",
        "Sunndalsøra",
        "Volda",
        "Fosnavåg",
        "Ørsta",
      ],
    },
    Trøndelag: {
      Trøndelag: [
        "Trondheim",
        "Steinkjer",
        "Levanger",
        "Stjørdal",
        "Namsos",
        "Orkanger",
        "Røros",
        "Verdal",
        "Oppdal",
        "Malvik",
        "Melhus",
      ],
    },
    "Northern Norway (Nord-Norge)": {
      Nordland: [
        "Bodø",
        "Narvik",
        "Mo i Rana",
        "Svolvær (Lofoten)",
        "Leknes",
        "Sortland",
        "Mosjøen",
        "Brønnøysund",
        "Fauske",
      ],
      "Troms and Finnmark": [
        "Tromsø",
        "Alta",
        "Hammerfest",
        "Harstad",
        "Vadsø",
        "Kirkenes",
        "Skjervøy",
        "Nordkapp",
        "Lakselv",
        "Kautokeino",
      ],
    },
  };
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedage, setSelectedage] = useState([]);

  // Handle region change
  // const handleRegionChange = (region) => {
  //   // If the region is already selected, remove it; otherwise, add it
  //   setSelectedRegions((prevSelectedRegions) => {
  //     if (prevSelectedRegions.includes(region)) {
  //       return prevSelectedRegions.filter((item) => item !== region); // Remove region
  //     } else {
  //       return [...prevSelectedRegions, region]; // Add region
  //     }
  //   });
  // };

  const handleRegionChange = (region) => {
    setSelectedRegion(region); // Set the new region
    setSelectedSubRegion(null); // Clear subregion
    setSelectedTowns([]); // Clear towns
  };

  const handleSubRegionChange = (subRegion) => {
    setSelectedSubRegion(subRegion); // Set the new subregion
    setSelectedTowns([]); // Clear towns
  };

  const handleTownChange = (town) => {
    setSelectedTowns(
      (prev) =>
        prev.includes(town)
          ? prev.filter((t) => t !== town) // Uncheck town
          : [...prev, town] // Check town
    );
  };

  const countDigits = (num) => {
    return num.toString().length; // Convert number to string and count the length
  };

  const areafilter = async (
    selectedRegion,
    selectedSubRegion,
    selectedTowns
  ) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    var fromage = selectedAge[0];
    var toage = selectedAge[1];

    const currentYearInNorway = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Oslo",
      year: "numeric",
    });
    //console.log("Current Year in Norway:", currentYearInNorway);

    const frombirthDates = calculateDateOfBirth(fromage, currentYearInNorway);
    const tobirthDates = calculateDateOfBirth(toage, currentYearInNorway);
    let formData = {
      location: selectedRegion,
      selectedSubRegion: selectedSubRegion,
      selectedTowns: selectedTowns,
      sexual_orientation: Selectedsex,
      fromage: frombirthDates,
      toage: tobirthDates,
      user_id: userLogin.id, // Sending as a string for the query
    };

    try {
      const res = await axios.post(apiUrl + "areafilter", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      if (respo !== undefined) {
        setAllMembers(respo);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleage = async (ag) => {
    setSelectedage((prevSelectedRegions) => {
      if (prevSelectedRegions.includes(ag)) {
        return prevSelectedRegions.filter((item) => item !== ag); // Remove region
      } else {
        return [...prevSelectedRegions, ag]; // Add region
      }
    });
  };
  const getbirth = (bb) => {
    const currentYearInNorway = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Oslo",
      year: "numeric",
    });
    //console.log("Current Year in Norway:", currentYearInNorway);

    const birthDates = bb.map((age) =>
      calculateDateOfBirth(age, currentYearInNorway)
    );
  };
  useEffect(() => {
    console.log(selectedAge);
    if (selectedAge.length > 0) {
      var fromage = selectedAge[0];
      var toage = selectedAge[1];

      const currentYearInNorway = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Oslo",
        year: "numeric",
      });
      //console.log("Current Year in Norway:", currentYearInNorway);

      const frombirthDates = calculateDateOfBirth(fromage, currentYearInNorway);
      const tobirthDates = calculateDateOfBirth(toage, currentYearInNorway);

      //setbirthdd(birthDates);
      // console.log("Calculated Dates of Birth:", birthDates);
      agefilter(frombirthDates, tobirthDates);
    }
  }, [selectedAgee, selectedAge]);
  const calculateDateOfBirth = (age, currentYear) => {
    const birthYear = currentYear - age; // Subtract age from the current year (Norwegian timezone)
    //const birthDate = new Date(birthYear, 0, 1); // Use January 1st as the DOB
    //return birthDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    return birthYear.toString();
  };
  const agefilter = async (frombirthDates, tobirthDates) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

    let formData = {
      location: selectedRegion,
      selectedSubRegion: selectedSubRegion,
      selectedTowns: selectedTowns,
      sexual_orientation: Selectedsex,
      fromage: frombirthDates,
      toage: tobirthDates,
      user_id: userLogin.id, // Sending as a string for the query
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "areafilter", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      setAllMembers(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [Selectedsex, setSelectedsex] = useState([]);
  const handlesexual = async (sex) => {
    setSelectedsex((prevSelectedRegions) => {
      if (prevSelectedRegions.includes(sex)) {
        return prevSelectedRegions.filter((item) => item !== sex); // Remove sex
      } else {
        return [...prevSelectedRegions, sex]; // Add sex
      }
    });
  };
  useEffect(() => {
    sexfilter(Selectedsex);
  }, [Selectedsex]);

  const handleAgeChange = (event) => {
    setSelectedAgee(event.target.value); // Update the age value
  };
  const sexfilter = async (search) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    var fromage = selectedAge[0];
    var toage = selectedAge[1];

    const currentYearInNorway = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Oslo",
      year: "numeric",
    });
    //console.log("Current Year in Norway:", currentYearInNorway);

    const frombirthDates = calculateDateOfBirth(fromage, currentYearInNorway);
    const tobirthDates = calculateDateOfBirth(toage, currentYearInNorway);
    let formData = {
      location: selectedRegion,
      selectedSubRegion: selectedSubRegion,
      selectedTowns: selectedTowns,
      sexual_orientation: search,
      fromage: frombirthDates,
      toage: tobirthDates,
      user_id: userLogin.id, // Sending as a string for the query
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "areafilter", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      setAllMembers(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const ages = Array.from({ length: 43 }, (_, index) => index + 18);
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);

  useEffect(() => {
    areafilter(selectedRegion, selectedSubRegion, selectedTowns);
  }, [selectedSubRegion, selectedTowns, selectedRegion]);

  return (
    <>
      <Headertwo />
      <section className="d-block searchmain gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3">
              <div className="searchmenu d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-1 searchinput">
                  <label>{t("Search")}</label>
                  <input
                    type="text"
                    value={searchTerm}
                    onInput={(e) => setSearchTerm(e.target.value)}
                    placeholder="...search"
                  />
                  <span>{t("Filters")}</span>
                </div>
                <div className="d-flex flex-column gap-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="serachdrop d-flex align-items-center  gap-3">
                          <div className="searchicon">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/serach1.png")}
                              alt="img"
                            />
                          </div>
                          <h3>{t("FriendSearchPage_Member")}</h3>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="memberdrop">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {t("FriendSearchPage_Area")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="memberdrop_listbox ">
                                {Object.entries(locations).map(
                                  ([region, subRegions]) => (
                                    <div
                                      key={region}
                                      className="memberdrop_cbox scroll_bar"
                                    >
                                      {/* Region Checkbox */}
                                      <label className="cbox d-flex align-items-center gap-3">
                                        <input
                                          type="radio"
                                          name="region"
                                          value={region}
                                          checked={selectedRegion === region}
                                          onChange={() =>
                                            handleRegionChange(region)
                                          }
                                        />
                                        {region}
                                      </label>

                                      {/* Subregions */}
                                      {selectedRegion === region && (
                                        <div className="subregions-list">
                                          {Object.entries(subRegions).map(
                                            ([subRegion, towns]) => (
                                              <div key={subRegion}>
                                                {/* Subregion Checkbox */}
                                                <label className="cbox d-flex align-items-center gap-3">
                                                  <input
                                                    type="radio"
                                                    name="subregion"
                                                    value={subRegion}
                                                    checked={
                                                      selectedSubRegion ===
                                                      subRegion
                                                    }
                                                    onChange={() =>
                                                      handleSubRegionChange(
                                                        subRegion
                                                      )
                                                    }
                                                  />
                                                  {subRegion}
                                                </label>

                                                {/* Towns */}
                                                {selectedSubRegion ===
                                                  subRegion && (
                                                  <div className="towns-list ms-4">
                                                    {towns.map(
                                                      (town, index) => (
                                                        <label
                                                          key={index}
                                                          className="cbox d-flex align-items-center gap-3"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            name="town"
                                                            value={town}
                                                            checked={selectedTowns.includes(
                                                              town
                                                            )}
                                                            onChange={() =>
                                                              handleTownChange(
                                                                town
                                                              )
                                                            }
                                                          />
                                                          {town}
                                                        </label>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              {t("FriendSearchPage_Prefrences")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="memberdrop_listbox">
                                <div className="memberdrop_cbox">
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input type="checkbox" name="nationality" />
                                    Preferences
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input type="checkbox" name="nationality" />
                                    Preferences
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input type="checkbox" name="nationality" />
                                    Preferences
                                  </label>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              {t("FriendSearchPage_Age")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="memberdrop_listbox">
                                <div id="ageSlider">
                                  {/* <input
                                      type="range"
                                      id="ageSlider"
                                      min="18"
                                      max="59"
                                      
                                      Value={selectedAgee}
                                      onChange={handleAgeChange}
                                      className="slider"
                                    /> */}
                                  <p className="age-range">
                                    Age: {selectedAge[0]} - {selectedAge[1]}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              {t("FriendSearchPage_SexualOrientation")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="memberdrop_listbox">
                                <div className="memberdrop_cbox">
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Heterosexual"
                                      onClick={() =>
                                        handlesexual("Heterosexual")
                                      }
                                    />
                                    {t("FriendSearchPage_SexualHeterosexual")}
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Homosexual"
                                      onClick={() => handlesexual("Homosexual")}
                                    />
                                    {t("FriendSearchPage_SexualHomosexual")}
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Bisexual"
                                      onClick={() => handlesexual("Bisexual")}
                                    />
                                    {t("FriendSearchPage_SexualBisexual")}
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Pansexual"
                                      onClick={() => handlesexual("Pansexual")}
                                    />
                                    {t("FriendSearchPage_SexualPansexual")}
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Asexual"
                                      onClick={() => handlesexual("Asexual")}
                                    />
                                    {t("FriendSearchPage_SexualAsexual")}
                                  </label>
                                  <label className="cbox d-flex align-items-center gap-3">
                                    <input
                                      type="checkbox"
                                      name="sexual"
                                      value="Other"
                                      onClick={() => handlesexual("Other")}
                                    />
                                    {t("FriendSearchPage_SexualOther")}
                                  </label>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Link to="/gallery" className="seacrh_btns">
                    <div className="serachdrop d-flex align-items-center  gap-3">
                      <div className="searchicon">
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach2.png")}
                          alt="img"
                        />
                      </div>
                      <h3>{t("Photos")} </h3>
                    </div>
                  </Link>
                  <Link to="/groups" className="seacrh_btns">
                    <div className="serachdrop d-flex align-items-center  gap-3">
                      <div className="searchicon">
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach3.png")}
                          alt="img"
                        />
                      </div>
                      <h3>{t("Groups")}</h3>
                    </div>
                  </Link>
                  <Link to="/events" className="seacrh_btns">
                    <div className="serachdrop d-flex align-items-center  gap-3">
                      <div className="searchicon">
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach4.png")}
                          alt="img"
                        />
                      </div>
                      <h3>{t("Events")}</h3>
                    </div>
                  </Link>
                  <Link to="/speeddate" className="seacrh_btns">
                    <div className="serachdrop d-flex align-items-center  gap-3">
                      <div className="searchicon">
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach5.png")}
                          alt="img"
                        />
                      </div>
                      <h3>{t("Speedating")}</h3>
                    </div>
                  </Link>
                  <Link to="/forums" className="seacrh_btns">
                    <div className="serachdrop d-flex align-items-center  gap-3">
                      <div className="searchicon">
                        <img
                          className="w-100 h-100"
                          src={require("../assets/images/serach6.png")}
                          alt="img"
                        />
                      </div>
                      <h3>{t("Forum")}</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                {AllMembers.length === 0 ? (
                  <div className="col-12 text-center">
                    <h4>{t("No_member_found")}</h4>
                  </div>
                ) : (
                  AllMembers.map((member) => (
                    <div className="col-sm-6 col-xl-3" key={member.id}>
                      <div className="position-relative search_content">
                        <Link
                          to={`/friend/${member.id}`}
                          className="d-block searchimg h-100"
                        >
                          {member.profile_image ? ( // Display the image URL if available
                            <img
                              className="w-100 h-100 object-fit-cover"
                              src={member.profile_image} // Use the URL from the member object
                              alt="userpic"
                            />
                          ) : (
                            <img
                              className="w-100 h-100 object-fit-cover"
                              src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                              alt="userpic"
                            />
                          )}
                        </Link>

                        <div className="d-flex align-items-center gap-2 searchgender_main">
                          <div className="d-block searchwomen flex-shrink-0">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/woman.png")}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-between px-3 searchusere">
                          <Link className="d-flex flex-column gap-0">
                            <h4>{member.username}</h4>
                            <div className="d-flex align-items-center gap-1">
                              <p>
                                {member.birthday_date != null
                                  ? `${calculateAge(member.birthday_date)},`
                                  : ""}
                              </p>
                              <p>{member.location}</p>
                            </div>
                          </Link>

                          {member.friend_status === "Yes" && (
                            <Button
                              onClick={() => chatsectionUser(member.id)}
                              className="d-block searchmessage flex-shrink-0"
                            >
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/message.png")}
                                alt="img"
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ChatBox
        isOpen={isPopupOpen}
        onClose={closePopup}
        userdetail={IsuserDetailForChat}
        userChatDetail={IsuserChatDetail}
        LoginData={LoginData}
        to_id={toid}
        getChatAfterSave={getChatAfterSave}
      ></ChatBox>
      <Footer />
    </>
  );
}
