import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
import { BsCloudUpload } from "react-icons/bs";
import Select from "react-select";
export default function UsersProfile() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [UserDetail, setUserDetail] = useState("");
  const [selectedSubRegion, setSelectedSubRegion] = React.useState("");
  const [nationality, setNationality] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [heightFeet, setHeightFeet] = useState("");
  const [genders, setgenders] = useState("");
  const [sexuality, setSexuality] = useState([]);
  const [Singel, setSingel] = useState("");
  const [sexual_orientation, setsexual_orientation] = useState([]);
  const [InterstedIn, setInterstedIn] = useState("");
  const [relationship_status, setrelationship_status] = useState("");
  const [search_looking_for, setsearch_looking_for] = useState("");
  const [degree, setDegree] = useState("");
  const [drinkerValue, setdrinkerValue] = useState("");
  const [smokerValue, setsmokerValue] = useState("");
  const [tattosValue, settattosValue] = useState("");
  const [body_piercingsValue, setbody_piercingsValue] = useState("");
  const [fetishValue, setfetishValue] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      if (respo === undefined) {
        navigate("/admin/users");
      }
      console.log("jk");
      setUserDetail(respo);
      setBodyType(respo.bodytype);
      setHeightFeet(respo.height_feet);
      setSingel(respo.connectwith);
      if (respo.location !== "null") {
        setSelectedRegion(respo.location);
      }

      if (respo.town !== null && respo.town !== "") {
        const parsedtown = JSON.parse(respo.town);

        // Now, you can update the state with the parsed array
        setSelectedTown(parsedtown);
      }
      if (respo.subregion !== "null") {
        setSelectedSubRegion(respo.subregion);
      }
      if (
        respo.sexual_orientation !== null &&
        respo.sexual_orientation !== ""
      ) {
        const parsedSexualOrientation = JSON.parse(respo.sexual_orientation);

        // Now, you can update the state with the parsed array
        setsexual_orientation(parsedSexualOrientation);
      }

      setgenders(respo.gender);
      if (respo.sexuality !== null && respo.sexuality !== "") {
        const inputString = JSON.parse(respo.sexuality);
        const optionss = inputString.map((item) => ({
          value: item,
          label: item,
        }));
        // Now, you can update the state with the parsed array
        setSexuality(optionss);
      }
      setInterstedIn(respo.interstedin);
      setrelationship_status(respo.relationship_status);
      setsearch_looking_for(respo.search_looking_for);
      setDegree(respo.degree);
      setdrinkerValue(respo.drinker);
      setsmokerValue(respo.smoker);
      settattosValue(respo.tattos);
      setbody_piercingsValue(respo.body_piercings);
      setfetishValue(respo.fetish);
      setImageUrl(respo.profile_image);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  //Location
  const locations = {
    "Eastern Norway (Østlandet)": {
      Viken: [
        "Drammen",
        "Bærum",
        "Asker",
        "Moss",
        "Fredrikstad",
        "Sarpsborg",
        "Halden",
        "Lillestrøm",
        "Jessheim",
        "Kongsberg",
      ],
      Innlandet: [
        "Hamar",
        "Lillehammer",
        "Gjøvik",
        "Elverum",
        "Kongsvinger",
        "Tynset",
        "Trysil",
        "Rena",
      ],
      "Vestfold and Telemark": [
        "Tønsberg",
        "Sandefjord",
        "Larvik",
        "Skien",
        "Porsgrunn",
        "Notodden",
        "Rjukan",
        "Kragerø",
        "Bø in Telemark",
      ],
      Oslo: ["Oslo"],
    },
    "Western Norway (Vestlandet)": {
      Hordaland: ["Bergen", "Voss", "Odda", "Stord", "Os", "Leirvik"],
      "Sogn og Fjordane": [
        "Førde",
        "Sogndal",
        "Florø",
        "Årdal",
        "Stryn",
        "Nordfjordeid",
      ],
      Rogaland: [
        "Stavanger",
        "Sandnes",
        "Haugesund",
        "Egersund",
        "Bryne",
        "Sauda",
        "Jørpeland",
      ],
      "Møre og Romsdal": [
        "Ålesund",
        "Molde",
        "Kristiansund",
        "Åndalsnes",
        "Sunndalsøra",
        "Volda",
        "Fosnavåg",
        "Ørsta",
      ],
    },
    Trøndelag: {
      Trøndelag: [
        "Trondheim",
        "Steinkjer",
        "Levanger",
        "Stjørdal",
        "Namsos",
        "Orkanger",
        "Røros",
        "Verdal",
        "Oppdal",
        "Malvik",
        "Melhus",
      ],
    },
    "Northern Norway (Nord-Norge)": {
      Nordland: [
        "Bodø",
        "Narvik",
        "Mo i Rana",
        "Svolvær (Lofoten)",
        "Leknes",
        "Sortland",
        "Mosjøen",
        "Brønnøysund",
        "Fauske",
      ],
      "Troms and Finnmark": [
        "Tromsø",
        "Alta",
        "Hammerfest",
        "Harstad",
        "Vadsø",
        "Kirkenes",
        "Skjervøy",
        "Nordkapp",
        "Lakselv",
        "Kautokeino",
      ],
    },
  };

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedTown, setSelectedTown] = useState([]);

  // Handle region selection
  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    setSelectedSubRegion("");
    setSelectedTown([]); // Reset town when region changes
  };
  const handleSubRegionChange = (e) => {
    setSelectedSubRegion(e.target.value);
    setSelectedTown([]);
  };

  // Handle town selection
  const handleTownChange = (event) => {
    const { value, checked } = event.target;

    setSelectedTown((prevSelectedTowns) => {
      if (checked) {
        // Add the town to the selected list
        return [...prevSelectedTowns, value];
      } else {
        // Remove the town from the selected list
        return prevSelectedTowns.filter((town) => town !== value);
      }
    });
  };
  //Location

  const handleNationalityChange = (event) => {
    setNationality(event.target.value); // Update state with the selected value
  };
  const handleBodyTypeChange = (event) => {
    setBodyType(event.target.value); // Update state with the selected value
  };
  const handleFeetChange = (event) => {
    setHeightFeet(event.target.value);
  };
  const min = 15; // Start value
  const max = 350; // End value
  const step = 1; // Step size

  // Create an array of numbers from min to max
  const optionsheight = Array.from(
    { length: (max - min) / step + 1 },
    (_, i) => min + i * step
  );
  const handleSexualOrientationChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setsexual_orientation((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setsexual_orientation((prev) =>
        prev.filter((orientation) => orientation !== value)
      );
    }
  };
  const handlegendersChange = (event) => {
    setgenders(event.target.value); // Update state with the selected value
    setSexuality([]);
  };
  const maleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Gay", label: "Gay" },
    { value: "Bull", label: "Bull" },
  ];

  const femaleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Lesbian", label: "Lesbian" },
    { value: "Hotwife", label: "Hotwife" },
  ];

  const coupleOptions_select = [
    { value: "Full swap", label: "Full swap" },
    { value: "Soft swap", label: "Soft swap" },
    { value: "Cuckold", label: "Cuckold" },
    { value: "Exhibitionist", label: "Exhibitionist" },
    { value: "Voyeur", label: "Voyeur" },
    { value: "Still Exploring", label: "Still Exploring" },
    { value: "HotWife", label: "HotWife" },
  ];
  const handleSexualityChange = (selectedOptions) => {
    setSexuality(selectedOptions); // Handle the selected multiple options
  };
  const handleSingelChange = (event) => {
    setSingel(event.target.value); // Update state with the selected value
  };
  const handleInterstedInChange = (event) => {
    setInterstedIn(event.target.value); // Update state with the selected value
  };
  const handleRelationStatusChange = (event) => {
    setrelationship_status(event.target.value); // Update state with the selected value
  };
  const handleSearchLookingforChange = (event) => {
    setsearch_looking_for(event.target.value); // Update state with the selected value
  };
  const handleDegreeChange = (event) => {
    setDegree(event.target.value);
  };
  const handledrinkerChange = (event) => {
    setdrinkerValue(event.target.value);
  };
  const handlesmokerChange = (event) => {
    setsmokerValue(event.target.value);
  };
  const handletattosChange = (event) => {
    settattosValue(event.target.value);
  };
  const handlebody_piercingsChange = (event) => {
    setbody_piercingsValue(event.target.value);
  };
  const handlefetishChange = (event) => {
    setfetishValue(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    var vl = event.target;
    const formData = new FormData();

    formData.append(
      "looking_for",
      vl.looking_for.value !== "" ? vl.looking_for.value : ""
    );

    // Append 'username' with a check
    formData.append(
      "username",
      vl.username.value !== "" ? vl.username.value : ""
    );

    // Append other fields with checks
    formData.append("location", selectedRegion !== "" ? selectedRegion : "");
    //formData.append("town", selectedTown !== "" ? selectedTown : "");
    formData.append(
      "preferences",
      vl.preferences.value !== "" ? vl.preferences.value : ""
    );
    formData.append(
      "nationality",
      vl.nationality.value !== "" ? vl.nationality.value : ""
    );
    formData.append(
      "bodytype",
      vl.bodytype.value !== "" ? vl.bodytype.value : ""
    );
    formData.append("height_feet", heightFeet !== "" ? heightFeet : "");
    formData.append("connectwith", Singel !== "" ? Singel : "");
    formData.append("interstedin", InterstedIn !== "" ? InterstedIn : "");

    if (sexual_orientation.length > 0) {
      formData.append(
        "sexual_orientation",
        JSON.stringify(sexual_orientation) // Ensure it's saved as a JSON string
      );
    } else {
      formData.append("sexual_orientation", ""); // Append an empty string if no selection
    }
    formData.append(
      "subregion",
      selectedSubRegion !== "" ? selectedSubRegion : ""
    );
    if (selectedTown.length > 0) {
      formData.append(
        "town",
        JSON.stringify(selectedTown) // Ensure it's saved as a JSON string
      );
    } else {
      formData.append("town", ""); // Append an empty string if no selection
    }
    formData.append("genders", genders !== "" ? genders : "");
    formData.append(
      "relationship_status",
      relationship_status !== "" ? relationship_status : ""
    );
    formData.append(
      "search_looking_for",
      search_looking_for !== "" ? search_looking_for : ""
    );
    formData.append("degree", degree !== "" ? degree : "");
    formData.append("fileType", "profileImage");
    formData.append("drinker", drinkerValue !== "" ? drinkerValue : "");
    formData.append("smoker", smokerValue !== "" ? smokerValue : "");
    formData.append("tattos", tattosValue !== "" ? tattosValue : "");
    formData.append(
      "body_piercings",
      body_piercingsValue !== "" ? body_piercingsValue : ""
    );
    formData.append("fetish", fetishValue !== "" ? fetishValue : "");
    formData.append("token", UserDetail.token);
    formData.append("email", UserDetail.email);

    sexuality.forEach((item) => {
      formData.append("sexuality", item.value); // Append only the "value" field
    });
    formData.append("profile_image", selectedFile);
    try {
      const res = await axios.post(apiURL + "updateProfile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      setShowPopSuccess(true);
      setPopsuccessMessage(res.data.message);
      var sl = res.data.slug;
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        navigate(`/admin/usersprofile/${sl}`);
      }, 2500);

      // Handle success (e.g., redirect or show a success message)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage(err.response.data.message); // Store the error message in state
      } else {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage("An unknown error occurred.");
      }
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
      }, 3500);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        setImageUrl(""); // Clear the image URL
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        setImageUrl(""); // Clear the image URL
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file)); // Create a URL for the selected image
    }
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          {showPopError && <ErrorPop message={errorPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form
                onSubmit={handleSubmit}
                action="javascript:void(0)"
                method="post"
              >
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <div className="d-flex justify-content-between">
                      <h6 className="mb-4">
                        Update Profile ({UserDetail.username})
                      </h6>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>

                    {/* Start of row */}
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3">
                          <div className="col-12">
                            <div className="uploadfile rel bgload mb-3">
                              <input
                                type="file"
                                name="profile_image"
                                accept="image/*" // Accept only image files
                                onChange={handleFileChange}
                                className="fileload"
                              />
                              <div className="upload_image">
                                {imageUrl ? ( // Display the image URL if available
                                  <img
                                    className="w-100 h-100"
                                    src={imageUrl} // Use the URL created from the file
                                    alt="Selected"
                                  />
                                ) : (
                                  selectedFile && ( // Fallback to show the previously fetched image
                                    <img
                                      className="w-100 h-100"
                                      src={selectedFile} // If it's an image URL from the profile
                                      alt="Profile"
                                    />
                                  )
                                )}
                              </div>
                              <div className="inload">
                                <div>
                                  <BsCloudUpload />
                                  <h3 className="text-dark">Upload Image</h3>
                                </div>
                              </div>
                              {errorMessage && (
                                <p className="text-danger">{errorMessage}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            I'm looking for
                          </label>
                          <input
                            type="text"
                            name="looking_for"
                            className="form-control"
                            id="exampleInputEmail1"
                            defaultValue={UserDetail.looking_for}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail2"
                            className="form-label"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            id="exampleInputEmail2"
                            defaultValue={UserDetail.username}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail2"
                            className="form-label"
                          >
                            Location
                          </label>
                          <select
                            name="region"
                            className="mb-3"
                            value={selectedRegion}
                            onChange={handleRegionChange}
                          >
                            <option value="">--Select Region--</option>
                            {Object.keys(locations).map((region) => (
                              <option key={region} value={region}>
                                {region}
                              </option>
                            ))}
                          </select>

                          {selectedRegion &&
                            typeof locations[selectedRegion] === "object" && (
                              <>
                                <label htmlFor="subregion">City</label>
                                <select
                                  id="subregion"
                                  name="subregion"
                                  className="mb-3"
                                  value={selectedSubRegion}
                                  onChange={handleSubRegionChange}
                                >
                                  <option value="">--Select City--</option>
                                  {Object.keys(locations[selectedRegion]).map(
                                    (subRegion) => (
                                      <option key={subRegion} value={subRegion}>
                                        {subRegion}
                                      </option>
                                    )
                                  )}
                                </select>
                              </>
                            )}

                          {selectedSubRegion &&
                            locations[selectedRegion][selectedSubRegion] && (
                              <>
                                <label htmlFor="town">Town</label>
                                <div
                                  id="town"
                                  className="d-flex flex-wrap mb-3"
                                >
                                  {locations[selectedRegion][
                                    selectedSubRegion
                                  ].map((town, index) => (
                                    <div
                                      key={index}
                                      className="form-check me-3"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`town-${index}`}
                                        value={town}
                                        checked={selectedTown.includes(town)}
                                        onChange={handleTownChange}
                                        className="form-check-input"
                                      />
                                      <label
                                        htmlFor={`town-${index}`}
                                        className="form-check-label"
                                      >
                                        {town}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Preferences
                          </label>
                          <input
                            type="text"
                            name="preferences"
                            className="form-control"
                            id="exampleInputEmail1"
                            defaultValue={UserDetail.preferences}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Degree
                          </label>
                          <select
                            id="degree"
                            onChange={handleDegreeChange}
                            value={degree}
                            name="degree"
                          >
                            <option value="">--Select your degree--</option>
                            <option value="BachelorDegree">
                              Bachelor's Degree
                            </option>
                            <option value="MasterDegree">
                              Master's Degree
                            </option>
                            <option value="DoctorateDegree">
                              Doctorate's Degree
                            </option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Drinker
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="drinker"
                                checked={drinkerValue === "Yes"} // Set checked based on state
                                onChange={handledrinkerChange}
                                value="Yes"
                                id="flexCheckYes"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="drinker"
                                checked={drinkerValue === "No"} // Set checked based on state
                                onChange={handledrinkerChange}
                                value="No"
                                id="flexCheckYNo"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Smoker
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="smoker"
                                checked={smokerValue === "Yes"} // Set checked based on state
                                onChange={handlesmokerChange}
                                value="Yes"
                                id="flexCheckYess"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYess"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="smoker"
                                checked={smokerValue === "No"} // Set checked based on state
                                onChange={handlesmokerChange}
                                value="No"
                                id="flexCheckYNos"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYNos"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Tattoos
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="tattos"
                                checked={tattosValue === "Yes"} // Set checked based on state
                                onChange={handletattosChange}
                                value="Yes"
                                id="flexCheckYests"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYests"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="tattos"
                                checked={tattosValue === "No"} // Set checked based on state
                                onChange={handletattosChange}
                                value="No"
                                id="flexCheckNots"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckNots"
                              >
                                No
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={tattosValue === "Few"} // Set checked based on state
                                onChange={handletattosChange}
                                name="tattos"
                                value="Few"
                                id="flexCheckNotf"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckNotf"
                              >
                                Few
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Body piercings
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="body_piercings"
                                checked={body_piercingsValue === "Yes"} // Set checked based on state
                                onChange={handlebody_piercingsChange}
                                value="Yes"
                                id="flexCheckYestss"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckYestss"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="body_piercings"
                                checked={body_piercingsValue === "No"} // Set checked based on state
                                onChange={handlebody_piercingsChange}
                                value="No"
                                id="flexCheckNotsa"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckNotsa"
                              >
                                No
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="body_piercings"
                                checked={body_piercingsValue === "Few"} // Set checked based on state
                                onChange={handlebody_piercingsChange}
                                value="Few"
                                id="flexCheckNotsaf"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckNotsaf"
                              >
                                Few
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Fetish
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "None"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="None"
                                id="flexCheckNone"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckNone"
                              >
                                None
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "BDSM"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="BDSM"
                                id="flexCheckBDSM"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckBDSM"
                              >
                                BDSM
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Bondage"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Bondage"
                                id="flexCheckBondage"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckBondage"
                              >
                                Bondage
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Dominant"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Dominant"
                                id="flexCheckDominant"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDominant"
                              >
                                Dominant
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Latex & Leather"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Latex & Leather"
                                id="flexCheckLatexLeather"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckLatexLeather"
                              >
                                Latex & Leather
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Roleplay"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Roleplay"
                                id="flexCheckRoleplay"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckRoleplay"
                              >
                                Role play
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Spanking"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Spanking"
                                id="flexCheckSpanking"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckSpanking"
                              >
                                Spanking
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Switch"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Switch"
                                id="flexCheckSwitch"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckSwitch"
                              >
                                Switch
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Submissive"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Submissive"
                                id="flexCheckSubmissive"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckSubmissive"
                              >
                                Submissive
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Lingerie"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Lingerie"
                                id="flexCheckLingerie"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckLingerie"
                              >
                                Lingerie
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Wet"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Wet"
                                id="flexCheckWet"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckWet"
                              >
                                Wet
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="fetish"
                                checked={fetishValue === "Other"} // Set checked based on state
                                onChange={handlefetishChange}
                                value="Other"
                                id="flexCheckOther"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckOther"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail3"
                            className="form-label"
                          >
                            Nationality
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Caucasian"
                                checked={nationality === "Caucasian"}
                                onChange={handleNationalityChange}
                                id="flexCheckCaucasian"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckCaucasian"
                              >
                                Caucasian
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Hispanic"
                                name="nationality"
                                checked={nationality === "Hispanic"}
                                onChange={handleNationalityChange}
                                id="flexCheckHispanic"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckHispanic"
                              >
                                Hispanic
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nationality"
                                value="African"
                                checked={nationality === "African"}
                                onChange={handleNationalityChange}
                                id="flexCheckAfrican"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckAfrican"
                              >
                                African
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nationality"
                                value="Indian"
                                checked={nationality === "Indian"}
                                onChange={handleNationalityChange}
                                id="flexCheckIndian"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckIndian"
                              >
                                Indian
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nationality"
                                value="Middle Eastern"
                                checked={nationality === "Middle Eastern"}
                                onChange={handleNationalityChange}
                                id="flexCheckm"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckm"
                              >
                                Middle Eastern
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nationality"
                                value="Jewish"
                                checked={nationality === "Jewish"}
                                onChange={handleNationalityChange}
                                id="flexCheckJewish"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckJewish"
                              >
                                Jewish
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nationality"
                                value="Other"
                                checked={nationality === "Other"}
                                onChange={handleNationalityChange}
                                id="flexCheckOther"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckOther"
                              >
                                Other
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Asian"
                                name="nationality"
                                checked={nationality === "Asian"}
                                onChange={handleNationalityChange}
                                id="flexCheckAsian"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckAsian"
                              >
                                Asian
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Bodytype
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="bodytype"
                                value="slim"
                                checked={bodyType === "slim"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksAsian"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksAsian"
                              >
                                Slim
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="athletic"
                                name="bodytype"
                                checked={bodyType === "athletic"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksathletic"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksathletic"
                              >
                                Athletic
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="average"
                                name="bodytype"
                                checked={bodyType === "average"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksaverage"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksaverage"
                              >
                                Average
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="bodytype"
                                value="curvy"
                                checked={bodyType === "curvy"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexCheckscurvy"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckscurvy"
                              >
                                Average
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="bodytype"
                                value="full-figured"
                                checked={bodyType === "full-figured"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksfull-figured"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksfull-figured"
                              >
                                Full-figured
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="bodytype"
                                value="plus-size"
                                checked={bodyType === "plus-size"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksplus-size"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksplus-size"
                              >
                                Plus-size
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="muscular"
                                name="bodytype"
                                checked={bodyType === "muscular"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksmuscular"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksmuscular"
                              >
                                Muscular
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="petite"
                                name="bodytype"
                                checked={bodyType === "petite"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexCheckspetite"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckspetite"
                              >
                                Petite
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="bodytype"
                                value="tall"
                                checked={bodyType === "tall"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexCheckstall"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckstall"
                              >
                                Tall
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="other"
                                name="bodytype"
                                checked={bodyType === "other"} // Check if this option is selected
                                onChange={handleBodyTypeChange}
                                id="flexChecksother"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksother"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Height
                          </label>
                          <select
                            id="height-feet"
                            onChange={handleFeetChange}
                            value={heightFeet}
                            name="height_feet"
                          >
                            <option value="">--Select Feet</option>
                            {optionsheight.map((value) => (
                              <option key={value} value={value}>
                                {value} cm
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Sexual Orientation
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            {[
                              "Heterosexual",
                              "Homosexual",
                              "Bisexual",
                              "Pansexual",
                              "Asexual",
                              "Other",
                            ].map((label) => (
                              <div className="form-check me-3" key={label}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="sexual_orientation"
                                  value={label}
                                  checked={sexual_orientation.includes(label)}
                                  onChange={handleSexualOrientationChange}
                                  id="flexChecksAsian"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexChecksAsian"
                                >
                                  {label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmailg"
                            className="form-label"
                          >
                            Gender
                          </label>
                          <div className="d-flex flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Male"}
                                onChange={handlegendersChange}
                                value="Male"
                                id="flexChecksMale"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksMale"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Female"}
                                onChange={handlegendersChange}
                                value="Female"
                                id="flexChecksFemale"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksFemale"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Trans"}
                                onChange={handlegendersChange}
                                value="Trans"
                                id="flexChecksTrans"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksTrans"
                              >
                                Trans
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Trans (MTF)"}
                                onChange={handlegendersChange}
                                value="Trans (MTF)"
                                id="flexChecksTranss"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksTranss"
                              >
                                Trans (MTF)
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Trans (FTM)"}
                                onChange={handlegendersChange}
                                value="Trans (FTM)"
                                id="flexChecksTranssf"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksTranssf"
                              >
                                Trans (FTM)
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Couple"}
                                onChange={handlegendersChange}
                                value="Couple"
                                id="flexChecksCouple"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksCouple"
                              >
                                Couple
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="genders"
                                checked={genders === "Ikke-binær"}
                                onChange={handlegendersChange}
                                value="Ikke-binær"
                                id="flexChecksIkke-binær"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksIkke-binær"
                              >
                                Non Binary
                              </label>
                            </div>
                          </div>
                          {genders === "Male" && (
                            <Select
                              isMulti
                              options={maleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select Male"
                            />
                          )}
                          {genders === "Female" && (
                            <Select
                              isMulti
                              options={femaleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select Female"
                            />
                          )}

                          {genders === "Couple" && (
                            <Select
                              isMulti
                              options={coupleOptions_select}
                              value={sexuality}
                              onChange={handleSexualityChange}
                              placeholder="Select Couple"
                            />
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmailco"
                            className="form-label"
                          >
                            Connect With
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Singel"}
                                onChange={handleSingelChange}
                                value="Singel"
                                id="flexChecksSingel"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksSingel"
                              >
                                Single
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Mann"}
                                onChange={handleSingelChange}
                                value="Mann"
                                id="flexChecksMann"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksMann"
                              >
                                Man
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Kvinne"}
                                onChange={handleSingelChange}
                                value="Kvinne"
                                id="flexChecksManns"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManns"
                              >
                                Woman
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Trans"}
                                onChange={handleSingelChange}
                                value="Trans"
                                id="flexChecksMannt"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksMannt"
                              >
                                Trans
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Trans (MTF)"}
                                onChange={handleSingelChange}
                                value="Trans (MTF)"
                                id="flexChecksManntm"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntm"
                              >
                                Trans (MTF)
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Trans (FTM)"}
                                onChange={handleSingelChange}
                                value="Trans (FTM)"
                                id="flexChecksManntms"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntms"
                              >
                                Trans (FTM)
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Ikke-binær"}
                                onChange={handleSingelChange}
                                value="Ikke-binær"
                                id="flexChecksManntmsb"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntmsb"
                              >
                                Non Binary
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Heteropar"}
                                onChange={handleSingelChange}
                                value="Heteropar"
                                id="flexChecksManntmsbs"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntmsbs"
                              >
                                Heterocouple
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Homopar"}
                                onChange={handleSingelChange}
                                value="Homopar"
                                id="flexChecksManntmsbs"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntmsbs"
                              >
                                Gay Couple
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Lesbepar"}
                                onChange={handleSingelChange}
                                value="Lesbepar"
                                id="flexChecksManntmsbsa"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntmsbsa"
                              >
                                Reading Guide
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="connectwith"
                                checked={Singel === "Flexipar"}
                                onChange={handleSingelChange}
                                value="Flexipar"
                                id="flexChecksManntmsbsas"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksManntmsbsas"
                              >
                                Flexi Pair
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmailco"
                            className="form-label"
                          >
                            Intersted In
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Diskusjon"}
                                onChange={handleInterstedInChange}
                                value="Diskusjon"
                                id="flexChecksints1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints1"
                              >
                                Discussion
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Inspirasjon"}
                                onChange={handleInterstedInChange}
                                value="Inspirasjon"
                                id="flexChecksints2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints2"
                              >
                                Inspiration
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Flørt"}
                                onChange={handleInterstedInChange}
                                value="Flørt"
                                id="flexChecksints3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints3"
                              >
                                Inspiration
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Date"}
                                onChange={handleInterstedInChange}
                                value="Date"
                                id="flexChecksints4"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints4"
                              >
                                Date
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Vennskap"}
                                onChange={handleInterstedInChange}
                                value="Vennskap"
                                id="flexChecksints5"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints5"
                              >
                                Friendship
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Forhold"}
                                onChange={handleInterstedInChange}
                                value="Forhold"
                                id="flexChecksints6"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints6"
                              >
                                Relationship
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Forhold"}
                                onChange={handleInterstedInChange}
                                value="Forhold"
                                id="flexChecksints6"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints6"
                              >
                                For Hold
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Kjærlighet"}
                                onChange={handleInterstedInChange}
                                value="Kjærlighet"
                                id="flexChecksints7"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints7"
                              >
                                Love
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Chat"}
                                onChange={handleInterstedInChange}
                                value="Chat"
                                id="flexChecksints8"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints8"
                              >
                                Chat
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Bare nettkontakt"}
                                onChange={handleInterstedInChange}
                                value="Bare nettkontakt"
                                id="flexChecksints9"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints9"
                              >
                                Online Contact Only
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Treff"}
                                onChange={handleInterstedInChange}
                                value="Treff"
                                id="flexChecksints10"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints10"
                              >
                                Hit
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="interstedin"
                                checked={InterstedIn === "Sosialt samvær"}
                                onChange={handleInterstedInChange}
                                value="Sosialt samvær"
                                id="flexChecksints10"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksints10"
                              >
                                Social Gathering
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmailco"
                            className="form-label"
                          >
                            Relationship Status
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Single"
                                checked={relationship_status === "Single"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksSingle"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksSingle"
                              >
                                Single
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="InaRelationship"
                                checked={
                                  relationship_status === "InaRelationship"
                                }
                                onChange={handleRelationStatusChange}
                                id="flexChecksInaRelationship"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksInaRelationship"
                              >
                                In a Relationship
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="engaged"
                                checked={relationship_status === "engaged"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksEngaged"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksEngaged"
                              >
                                Engaged
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Married"
                                checked={relationship_status === "Married"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksMarried"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksMarried"
                              >
                                Married
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="ItsComplicated"
                                checked={
                                  relationship_status === "ItsComplicated"
                                }
                                onChange={handleRelationStatusChange}
                                id="flexChecksItsComplicated"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksItsComplicated"
                              >
                                It's Complicated
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Separated"
                                checked={relationship_status === "Separated"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksSeparated"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksSeparated"
                              >
                                Separated
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Divorced"
                                checked={relationship_status === "Divorced"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksDivorced"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksDivorced"
                              >
                                Divorced
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Widowed"
                                checked={relationship_status === "Widowed"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksWidowed"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksWidowed"
                              >
                                Widowed
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Polyamorous"
                                checked={relationship_status === "Polyamorous"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksPolyamorous"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksPolyamorous"
                              >
                                Polyamorous
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="relationship_status"
                                value="Other"
                                checked={relationship_status === "Other"}
                                onChange={handleRelationStatusChange}
                                id="flexChecksOther"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexChecksOther"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmailco"
                            className="form-label"
                          >
                            Looking for
                          </label>
                          <div className="d-flex  flex-wrap mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Friends"
                                checked={search_looking_for === "Friends"}
                                onChange={handleSearchLookingforChange}
                                id="look1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look1"
                              >
                                Friends
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Dating"
                                checked={search_looking_for === "Dating"}
                                onChange={handleSearchLookingforChange}
                                id="look2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look2"
                              >
                                Dating
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Parties"
                                checked={search_looking_for === "Parties"}
                                onChange={handleSearchLookingforChange}
                                id="look3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look3"
                              >
                                Parties
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Flirting"
                                checked={search_looking_for === "Flirting"}
                                onChange={handleSearchLookingforChange}
                                id="look4"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look4"
                              >
                                Flirting
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Relationships"
                                checked={search_looking_for === "Relationships"}
                                onChange={handleSearchLookingforChange}
                                id="look5"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look5"
                              >
                                Relationships
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Fun"
                                checked={search_looking_for === "Fun"}
                                onChange={handleSearchLookingforChange}
                                id="look6"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look6"
                              >
                                Fun
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Serious"
                                checked={search_looking_for === "Serious"}
                                onChange={handleSearchLookingforChange}
                                id="look7"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look7"
                              >
                                Serious
                              </label>
                            </div>

                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="search_looking_for"
                                value="Hookups"
                                checked={search_looking_for === "Hookups"}
                                onChange={handleSearchLookingforChange}
                                id="look8"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="look8"
                              >
                                Hookups
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
