import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faCalendarAlt,
  faUserPlus,
  faComment,
  faStar,
  faEye,
  faUsersCog,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
export default function UsersGroups() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [spinner, setspinner] = useState(false);
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [UserDetail, setUserDetail] = useState("");
  const [showModalInterested, setShowModalInterested] = useState(false);
  const [Interestedgroups, setInterestedgroups] = useState([]);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [Invitegroups, setInvitegroups] = useState([]);
  const [showModalJoin, setShowModalJoin] = useState(false);
  const [Joingroups, setJoingroups] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [messageupdate, setmessageupdate] = useState(false);
  const [makeImageUse, setMakeImagePrivate] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    name: "",
    id: "",
    description: "",
    image: null,
  });
  const [groupIdToDelete, setgroupIdToDelete] = useState("");
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const apiUrl = "https://backend.amourette.no/api/admin/";

  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  useEffect(() => {
    if (UserDetail !== "") {
      //const intervalId = setInterval(() => {
      //  getusergrouplist(); // Call your function
      //}, 3500);
      getusergrouplist();
      // Clear the interval when the component unmounts or UserDetail changes
      //return () => clearInterval(intervalId);
    }
  }, [UserDetail]);
  const getusergrouplist = async () => {
    let formData = {
      user_id: UserDetail.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getusergrouplist", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    ,
    {
      name: "Make Private",
      selector: (row) => row.makeImageUse,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            href="javascript:void(0)"
            className="dataedit_btn text-info"
            title="Interested User"
            onClick={() => handleinterestedUser(row)}
          >
            <FontAwesomeIcon icon={faStar} />
          </a>
          <a
            href="javascript:void(0)"
            className="dataedit_btn text-secondary"
            title="Invite User"
            onClick={() => handleInviteUser(row)}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => handleeditgroup(row)}
            className="dataedit_btn text-primary"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </a>
          <a
            target="_blank"
            href={`/admin/usergroups/${slug}/${row.id}`}
            className="dataedit_btn"
            title="View Post Detail"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
          <a
            href="javascript:void(0)"
            className="dataedit_btn text-success"
            title="Total Join Group"
            onClick={() => handleJoinUser(row)}
          >
            <FontAwesomeIcon icon={faUsersCog} />
          </a>
          <a
            href="#"
            onClick={() => handledeletegroup(row)}
            className="dataedit_btn text-danger"
            title="Delete Group"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  //Location
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  const handledeletegroup = async (row) => {
    setgroupIdToDelete(row.id);
    setalertpopup(true); // Close the popup
    setmessageset("Are you sure? You want to delete this group");
  };
  const handleInviteUser = async (row) => {
    setShowModalInvite(true);
    setInvitegroups([]);
    let formData = {
      user_id: UserDetail.id,
      group_id: row.id,
    };

    try {
      const res = await axios.post(apiUrl + "getusergroupinvite", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      setInvitegroups(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleinterestedUser = async (row) => {
    setShowModalInterested(true);
    let formData = {
      user_id: UserDetail.id,
      group_id: row.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(
        apiUrl + "getusergroupinterested",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
      console.log(respo);
      setInterestedgroups(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleJoinUser = async (row) => {
    setShowModalJoin(true);
    let formData = {
      user_id: UserDetail.id,
      group_id: row.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getuserjoinGroup", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      setJoingroups(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleModalCloseJoin = () => {
    setShowModalInterested(false);
    setShowModalInvite(false);
    setShowModalJoin(false);
    setShowModalEdit(false);
  };
  const handleeditgroup = (row) => {
    setShowModalEdit(true);
    setCurrentRecord(row);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setspinner(true);
    var vl = e.target;
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("makeImagePrivatee", makeImageUse);
      formData.append("fileType", "groups");
      formData.append("idd", vl.id.value);
      formData.append("image", selectedFile);
      for (const key in currentRecord) {
        formData.append(key, currentRecord[key]);
      }
      const response = await axios.post(apiUrl + "adminEditgroup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.status === 200) {
        setmessageupdate(true);
        getusergrouplist();
        setTimeout(() => {
          setspinner(false);
          setShowModalEdit(false);
          setmessageupdate(false);
        }, 1500);
      }
      // Close the popup after submission
    } catch (error) {}
  };
  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 2 MB
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image or video
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (!isImage && !isVideo) {
        setErrorMessage("Please upload a valid image or video file.");
        setSelectedFile(null);
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 5 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear previous error messages
      setErrorMessage("");
      setSelectedFile(file);
      setFileType(isImage ? "image" : "video");

      if (isVideo) {
        //const resizedVideoUrl = await resizeVideo(file);
        // setVideoUrl(resizedVideoUrl);
      }
    }
  };
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setMakeImagePrivate(checked);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: groupIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deletegroups", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getusergrouplist();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form action="javascript:void(0)" method="post">
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <Link
                      to="/admin/users"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                      className="mb-4"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      Back
                    </Link>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h6 className="mb-4">
                          Group List ({UserDetail.username})
                        </h6>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Start of row */}
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredRecords}
                        pagination
                        highlightOnHover
                        noDataComponent="No records found."
                      />
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Interested Groups Modal*/}
      <Modal
        show={showModalInterested}
        className="edit_admin"
        onHide={handleModalCloseJoin}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">
            Interested User List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ul className="comment_userlist scroll_bar">
              {Interestedgroups.length === 0 ? (
                <li>No result found</li>
              ) : (
                Interestedgroups.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <h4>{com.username}</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseJoin}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {/* Interested Groups Modal*/}

      {/* Invite Groups Modal*/}
      <Modal
        show={showModalInvite}
        className="edit_admin"
        onHide={handleModalCloseJoin}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">Invite User List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ul className="comment_userlist scroll_bar">
              {Invitegroups.length === 0 ? (
                <li>No result found</li>
              ) : (
                Invitegroups.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <h4>{com.username}</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseJoin}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {/* Invite Groups Modal*/}
      {/* Invite Groups Modal*/}
      <Modal
        show={showModalJoin}
        className="edit_admin"
        onHide={handleModalCloseJoin}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">
            Group Join User List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ul className="comment_userlist scroll_bar">
              {Joingroups.length === 0 ? (
                <li>No result found</li>
              ) : (
                Joingroups.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <h4>{com.username}</h4>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseJoin}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {/* Invite Groups Modal*/}

      <Modal
        show={showModalEdit}
        className="edit_admin"
        onHide={handleModalCloseJoin}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">
            Edit Group ({currentRecord.name})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {messageupdate && (
            <span className="text-success mb-4">Updated successfully</span>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="uploadfile rel bgload">
                <input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  className="fileload"
                />

                <input type="hidden" name="id" value={currentRecord.id} />
                <div className="upload_image">
                  {fileType === "image" && selectedFile ? (
                    <img
                      className="w-100 h-100"
                      src={URL.createObjectURL(selectedFile)}
                      alt="Selected"
                    />
                  ) : fileType === "video" && selectedFile ? (
                    <video controls className="w-100 h-100" alt="video">
                      <source
                        src={URL.createObjectURL(selectedFile)}
                        type={selectedFile.type}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    currentRecord.image &&
                    (currentRecord.image.match(
                      /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                    ) ? (
                      <img
                        src={currentRecord.image}
                        alt="img"
                        className="w-100 h-100"
                      />
                    ) : currentRecord.image.match(/\.(mp4|mov|avi)$/i) ? (
                      <video controls className="w-100 h-100" alt="video">
                        <source src={currentRecord.image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : null)
                  )}
                </div>
                <div className="inload" style={{ height: "100px" }}>
                  <div>
                    <BsCloudUpload style={{ fontSize: "24px" }} />
                    <h3 className="text-dark" style={{ fontSize: "14px" }}>
                      Upload image
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentRecord.name || ""} // Ensure it works if the value is initially undefined
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                name="description"
                className="form-control"
                value={currentRecord.description || ""} // Bind value
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="makeImageUse"
                  name="makeImageUse"
                  checked={makeImageUse} // Use the state directly
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="makeImageUse">
                  Make Private
                </label>
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseJoin}>
                Close
              </Button>

              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
              {spinner && (
                <div
                  className="spinner-border text-success date-load"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
