import React, { useState, useRef, useEffect } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
export const Usergallery = ({
  isOpen1,
  onClose1,
  children,
  seperateGallerydetail,
  UserDetail,
  LoginData,
  getUserfav,
  GalleryCommets,
  getCommentPost,
  GalleryIdDetailGet,
}) => {
  console.log(seperateGallerydetail.image);
  const chatEndRef = useRef(null);
  const [isparamVl, setparamVl] = useState(seperateGallerydetail);
  const [isgallid, setgallid] = useState(GalleryIdDetailGet);
  const [restr_message, setrestr_message] = useState(false);
  const [GalleryCommetss, setGalleryCommetss] = useState(GalleryCommets);
  useEffect(() => {
    setGalleryCommetss(GalleryCommets);
    setparamVl(seperateGallerydetail);
    setgallid(GalleryIdDetailGet);
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView();
    }
  }, [GalleryCommets, seperateGallerydetail, GalleryIdDetailGet]);

  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);

        const gid = String(receivedMessage.gallery_id); // Convert to string
        const galleryIdDetail = String(GalleryIdDetailGet); // Convert to string as well

        const log = LoginData.id;
        console.log(typeof galleryIdDetail);
        console.log(typeof GalleryIdDetailGet);
        console.log(gid);
        // Check if gid matches the expected GalleryIdDetailGet
        if (galleryIdDetail === gid) {
          if (receivedMessage.event === "GalleryLike") {
            console.log(receivedMessage);
            // Update the local state based on the received message
            setparamVl((prev) => {
              if (prev.id === receivedMessage.gallery_id) {
                return {
                  ...prev,
                  total_favourites: receivedMessage.total_favourites,
                  user_favourited:
                    log === receivedMessage.user_id
                      ? receivedMessage.user_favourited // Update user_favourited only if IDs match
                      : prev.user_favourited, // Toggle the favourited state
                };
              }
              return prev;
            });
          }
          if (receivedMessage.event === "GalleryPost") {
            // Update state with the new comment
            setGalleryCommetss((prevComments) => [
              ...prevComments,
              {
                user_id: receivedMessage.user_id,
                username: receivedMessage.username,
                description: receivedMessage.message,
                profile_image: receivedMessage.profile_image,
                makeImagePrivate: receivedMessage.makeImagePrivate,
                date: receivedMessage.date,
                lastInsertId: receivedMessage.lastInsertId,
              },
            ]);
          }
        } else {
          console.log(
            `gallery_id does not match: ${gid} !== ${galleryIdDetail}`
          );
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [LoginData, GalleryIdDetailGet]);
  //console.log(GalleryCommets);
  // Ref to the chat container
  var apiUrl = "https://backend.amourette.no/api/members/";

  const [comments, setComments] = useState("");
  const [spinner, setspinner] = useState(false);
  if (!isOpen1) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose1(); // Close the popup if the overlay is clicked
    }
  };

  const formateTime = (dateString) => {
    if (!dateString) return null;

    const postDate = new Date(dateString);
    const now = new Date();

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((now - postDate) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const isButtonDisabled = !comments.trim() || restr_message;
  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    setComments(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    setspinner(true);
    let formData = {
      description: e.target.description.value,
      gallery_id: e.target.gallery_id.value,
      user_id: LoginData.id,
    };
    try {
      const res = await axios.post(apiUrl + "GalleryPostSave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      //console.log(res.data);
      setspinner(false);
      setComments("");
      getCommentPost();
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const galleryPostLike = async (id) => {
    let formData = {
      user_id: LoginData.id,
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "galleryPostLike", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-5">
          <button
            className="close-button"
            style={{ zIndex: "999" }}
            onClick={onClose1}
          >
            &times; {/* Close button */}
          </button>
          <div className="usergallery_pop  d-flex flex-column gap-1">
            <div className="d-block gallery_im1g">
              {isparamVl.image && typeof isparamVl.image === "string" ? (
                // Check if the URL ends with a valid image extension
                isparamVl.image.match(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i) ? (
                  <img
                    className="w-100 h-100"
                    src={isparamVl.image}
                    alt="img"
                  />
                ) : // Check if the URL ends with a valid video extension
                isparamVl.image.match(/\.(mp4|mov|avi)$/i) ? (
                  <video className="w-100 h-100" controls>
                    <source src={isparamVl.image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  // Fallback for unsupported formats
                  <p>Media format not supported</p>
                )
              ) : (
                // Fallback when `item.image` is undefined or invalid
                <p>No valid media available</p>
              )}
            </div>
            <div className="d-flex flex-column gap-1 p-3 bordr-bt">
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <h3>{isparamVl.username}</h3>
                <h6>{formateTime(isparamVl.date)}</h6>
              </div>
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <p>{isparamVl.description}</p>
                <div className="d-flex align-items-center gap-4">
                  <div className="d-flex align-items-center gap-1">
                    <button
                      type="button"
                      onClick={() => galleryPostLike(isparamVl.id)}
                      className="liked  fulw"
                    >
                      {isparamVl.user_favourited ? <FaHeart /> : <FaRegHeart />}
                    </button>
                    <p className="likecount">{isparamVl.total_favourites}</p>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <div className="d-block like_icon">
                      <img
                        className="w-100 h-100"
                        src={require("../assets/images/comment.png")}
                        alt="img"
                      />
                    </div>
                    <p className="likecount">{GalleryCommetss.length}</p>
                  </div>
                </div>
              </div>
            </div>
            <ul className="usergallery_msg scroll_bar">
              {GalleryCommetss.length === 0 ? (
                <li>No comments yet</li>
              ) : (
                GalleryCommetss.map((comment, index) => (
                  <li className="gap-3" key={index}>
                    <div className="profile_img">
                      <img
                        className="w-100 h-100"
                        src={
                          comment.makeImagePrivate === 1
                            ? require("../assets/images/thumb.jpg") // Default image if the profile image is private
                            : comment.profile_image // Use user's profile image if available
                            ? comment.profile_image
                            : require("../assets/images/image3.png") // Default image if no profile image
                        }
                        alt="img"
                      />
                    </div>
                    <div className="comment_text d-flex flex-column gap-1">
                      <h6>{comment.username}</h6>{" "}
                      {/* Display the commenter's username */}
                      <p>{comment.description}</p>{" "}
                      {/* Display the comment text */}
                    </div>
                  </li>
                ))
              )}
              <li ref={chatEndRef} />
            </ul>
            <form
              action="javascript:void(0)"
              method="post"
              onSubmit={handlePostSubmit}
            >
              {restr_message && (
                <span className="text-danger" style={{ fontSize: "14px" }}>
                  Your message contains restricted words. Please remove them
                </span>
              )}
              <div className="d-flex align-items-center gap-3 w-100 usergallery_from">
                <div className="profile_img">
                  <img
                    className="w-100 h-100"
                    src={
                      UserDetail.makeImagePrivate === 1
                        ? require("../assets/images/thumb.jpg") // Default image if image is private
                        : UserDetail.profile_image === "null" ||
                          !UserDetail.profile_image
                        ? require("../assets/images/images.png") // Fallback image if profile image is not available
                        : UserDetail.profile_image // Use the actual profile image
                    }
                    alt="Profile"
                  />
                </div>

                <div className="comment_input w-100 d-flex align-items-center gap-1">
                  {spinner && (
                    <div
                      className="spinner-border text-success postloader"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <input
                    type="hidden"
                    value={seperateGallerydetail.id}
                    name="gallery_id"
                  />
                  <input
                    type="text"
                    required
                    name="description"
                    value={comments} // Controlled input
                    onChange={handleCommentChange} // Simplified
                    placeholder="Write a comment..."
                  />

                  <button
                    type="submit"
                    disabled={isButtonDisabled} // Disable button if comment is empty
                    style={{ opacity: isButtonDisabled ? 0.1 : 1 }}
                    className="comment_postbtn"
                  >
                    Post
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
