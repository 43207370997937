import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import pe1 from "../assets/images/pic.png";
import axios from "axios";
import icon2 from "../assets/images/bell.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n"; // Make sure i18n is imported properly
function Notification({ refresh }) {
  var apiUrlnoti = "https://backend.amourette.no/api/notifications/";
  var apiUrl = "https://backend.amourette.no/api/members/";
  const [AllfriendId, setAllfriendId] = useState([]);
  const [totalcount, settotalcount] = useState("");
  const [notificationsdetail, setnotificationsdetail] = useState([]);
  useEffect(() => {
    if (notificationsdetail) {
      getAllfriends();
    }
  }, [refresh, notificationsdetail]);
  const getAllfriends = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      const friendUserIds = respo.map((friend) => friend.id);
      setAllfriendId(friendUserIds);
      const galleries = await getnotifications(friendUserIds);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getnotifications = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

    const userIdsWithSelf = [...userIds, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      user_ids: userIdsString, // Sending as a string for the query
      user_id: userLogin.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrlnoti + "getnotifications", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      const totalUnreadCount = respo.filter(
        (item) => item.read === "No"
      ).length;
      // console.log(totalUnreadCount);
      // console.log(notificationsdetail);
      // console.log(totalUnreadCount);
      //if (totalUnreadCount !== 0) {
      settotalcount(totalUnreadCount);
      // }
      setnotificationsdetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const [activeMenu, setActiveMenu] = useState(null);
  const toggleMenu = async (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

    const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      user_ids: userIdsString, // Sending as a string for the query
      user_id: userLogin.id,
    };
    //console.log(formData);
    try {
      const res = await axios.post(
        apiUrlnoti + "updatenotifications",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;

      getAllfriends();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  return (
    <>
      <div className="">
        <button
          type="button"
          className={`topmenu ${activeMenu === "menu2" ? "active" : ""}`}
          onClick={() => toggleMenu("menu2")}
        >
          <img src={icon2} alt="userp" />
          {totalcount > 0 && (
            <p className="noitifiaction-count text-success">{totalcount}</p>
          )}
        </button>
        <div
          className="menubox"
          style={{
            display: activeMenu === "menu2" ? "block" : "none",
          }}
        >
          <div className="d-flex flex-column gap-4 ">
            <h3>{t("Notification")}</h3>
            <div className="d-flex flex-column gap-3">
              {notificationsdetail.length === 0 ? ( // Check if there are no notifications
                <p>{t("No_result_found")}</p>
              ) : (
                <div className="d-flex flex-column gap-3 scroll_bar scrollyover">
                  {notificationsdetail.map((notification, index) => (
                    <Link to="#" className="d-flex gap-3" key={index}>
                      <div className="flex-shrink-0">
                        <div className="iconmenu ofit rounded-circle overflow-hidden">
                          <img
                            src={notification.profile_image || pe1}
                            alt="userpic"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>
                          {notification.status === "User" &&
                            notification.username}{" "}
                          {notification.message}
                        </h4>
                        <p className="timeago">
                          {formateTime(notification.date)}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
