import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import image1 from "../assets/images/image1.jfif";
import image2 from "../assets/images/image2.jfif";
import image3 from "../assets/images/image4.jfif";
import image4 from "../assets/images/image5.jfif";
import image5 from "../assets/images/angular.jpg";
import image6 from "../assets/images/image3.png";
import axios from "axios";
export default function Groupimages() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const [isGrouppost, setGrouppost] = useState([]);
  const [groupId, setgroupId] = useState("");
  const [isGrouppostvideo, setGrouppostvideo] = useState([]);
  const [LoginData, setLoginData] = useState(null);
  const [Isslug, setSlug] = useState("");
  const { slug } = useParams();
  const [groupdetail, setgroupdetail] = useState("");
  const images = [
    {
      src: image1,
      wideSrc: image1,
      alt: "Nature",
    },
    {
      src: image2,
      wideSrc: image3,
      alt: "Snow",
    },
    {
      src: image4,
      wideSrc: image5,
      alt: "Mountains",
    },
    {
      src: image6,
      wideSrc: image6,
      alt: "Lights",
    },
    {
      src: image1,
      wideSrc: image1,
      alt: "Nature",
    },
    {
      src: image2,
      wideSrc: image3,
      alt: "Snow",
    },
    {
      src: image4,
      wideSrc: image5,
      alt: "Mountains",
    },
    {
      src: image6,
      wideSrc: image6,
      alt: "Lights",
    },
  ];

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const showSlide = (n) => {
    if (n > images.length) setCurrentSlideIndex(1);
    else if (n < 1) setCurrentSlideIndex(images.length);
    else setCurrentSlideIndex(n);
  };
  useEffect(() => {
    if (modalOpen) {
      // Disable scrolling when modal is open
      document.body.style.overflowY = "hidden";
    } else {
      // Enable scrolling when modal is closed
      document.body.style.overflowY = "auto";
    }

    // Cleanup to restore the overflow style when the component unmounts
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [modalOpen]);
  var apiUrl = "https://backend.amourette.no/api/groups/";
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    setLoginData(userLogin);
  }, []);
  useEffect(() => {
    if (slug !== undefined) {
      setSlug(slug); // Ensure setSlug is defined and does not cause issues
      getDetails(slug);
    }
  }, [LoginData, slug]);
  useEffect(() => {
    if (LoginData && groupId) {
      getpostComment(groupId);
    }
  }, [LoginData, groupId]);
  const getDetails = async (slug) => {
    let formData = {
      slug: slug,
    };

    try {
      const res = await axios.post(apiUrl + "getGroupDetailSlug", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.group;
      console.log(respo);
      console.log("vh");
      setgroupdetail(respo);

      if (res.data.group === "" || res.data.group === undefined) {
        navigate("/groups");
      } else {
        console.log(LoginData);
      }
      setgroupId(respo.id);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const getpostComment = async (idd) => {
    let formData = {
      group_id: idd,
      user_id: LoginData.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "get_postComment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("res.data");
      const images = [];
      const videos = [];
      if (res.data.results.length > 0) {
        res.data.results.forEach((item) => {
          const file = item.file; // Assuming 'file' contains the file URL
          if (file.match(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i)) {
            images.push(item);
          } else if (file.match(/\.(mp4|mov|avi|webm)$/i)) {
            videos.push(item);
          }
        });
      }

      setGrouppost(images);
      setGrouppostvideo(videos);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  console.log(isGrouppost);
  return (
    <>
      <Headertwo />
      <section className="ggboxes d-block gapy">
        <div className="container-lg">
          <div className="row gy-4 mb-5">
            <div className="col-md-6">
              <h2 className="pagetitle">Photos</h2>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-md-end">
                {/* <div className="d-flex gap-4 align-items-center ggboxbtn">
                  <Link href="#">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 5l0 14" />
                      <path d="M5 12l14 0" />
                    </svg>{" "}
                    Opprett album
                  </Link>
                  <Link href="#">Legg till bilder/video</Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Images</Tab>
                  <Tab>Videos</Tab>
                  <Tab>Albums</Tab>
                </TabList>

                <TabPanel>
                  <div className="row gy-4">
                    {isGrouppost.length > 0 ? (
                      isGrouppost.map((post, index) => (
                        <div className="col-md-3" key={index}>
                          <div className="d-flex flex-column gap-2">
                            <div className="ggimagebox ofit">
                              <img
                                src={post.file}
                                alt="Media"
                                onClick={() => {
                                  openModal();
                                  showSlide(index + 1);
                                }}
                                className="hover-shadow"
                              />
                            </div>
                            {/* <h6 className="urnamgg">{post.name}</h6> */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No media available.</p>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row gy-4">
                    {isGrouppost.length > 0 ? (
                      isGrouppost.map((post, index) => (
                        <div className="col-md-3" key={index}>
                          <div className="d-flex flex-column gap-2">
                            <div className="ggimagebox ofit">
                              <video
                                onClick={() => {
                                  openModal();
                                  showSlide(index + 1);
                                }}
                                controls
                                alt="video"
                              >
                                <source src={post.file} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            {/* <h6 className="urnamgg">{post.name}</h6> */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No media available.</p>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <h2>Any content 3</h2>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {/* pop up code */}
      {modalOpen && (
        <div id="myModal" className="modalgg">
          <span className="close cursor" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-content">
            {isGrouppost.length > 0 ? (
              isGrouppost.map((post, index) => (
                <div
                  className="mySlides"
                  style={{
                    display: currentSlideIndex === index + 1 ? "block" : "none",
                  }}
                  key={index}
                >
                  <div className="numbertext">
                    {index + 1} / {isGrouppost.length}
                  </div>

                  {post.file.match(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i) ? (
                    <img src={post.file} alt="img" />
                  ) : post.file.match(/\.(mp4|mov|avi)$/i) ? (
                    <video controls alt="video">
                      <source src={post.file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              ))
            ) : (
              <p>No media available.</p>
            )}

            <a
              className="prev"
              onClick={() => showSlide(currentSlideIndex - 1)}
            >
              &#10094;
            </a>
            <a
              className="next"
              onClick={() => showSlide(currentSlideIndex + 1)}
            >
              &#10095;
            </a>
            <div className="caption-container">
              {/* <p id="caption">{images[currentSlideIndex - 1]?.alt}</p> */}
            </div>
            {/* <div className="thumbnail-row">
              {images.map((image, index) => (
                <div className="column" key={index}>
                  <img
                    className={`demo ${
                      currentSlideIndex === index + 1 ? "active" : ""
                    }`}
                    src={image.src}
                    alt={image.alt}
                    onClick={() => showSlide(index + 1)}
                  />
                </div>
              ))}
            </div> */}
          </div>
        </div>
      )}
      {/* pop up code */}
      <Footer />
    </>
  );
}
