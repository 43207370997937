import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import {
  faComment,
  faFileInvoice,
  faUndo,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { useNavigate } from "react-router-dom";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import generatePDF from "./PDFGeneration";
import logo from "../../assets/images/logo.png";
function PaymentSubscription() {
  const [alluserpayment, setalluserpayment] = useState([]);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const navigate = useNavigate();
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [invoiceData, setinvoiceData] = useState("");
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  const [records, setRecords] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      name: "Pay ID",
      selector: (row) => row.payment_id,
      sortable: true,
    },
    { name: "User", selector: (row) => row.username, sortable: true },
    { name: "Plan", selector: (row) => row.plan, sortable: true },
    {
      name: "Amount",
      selector: (row) => {
        const amount = parseFloat(row.amount); // Convert to number
        return !isNaN(amount) ? amount.toFixed(2) : "0.00"; // Check if it's a valid number
      },
      sortable: true,
    },
    { name: "Date", selector: (row) => moment(row.date).format("DD-MM-YYYY") },

    {
      name: "Refund",
      cell: (row) => (
        <a
          href="javascript:void(0)"
          className={`btn ${
            row.refund === "Succeeded" ? "btn-success" : "btn-primary"
          }`}
          style={{ fontSize: "10px" }}
        >
          {row.refund ? row.refund : "N/A"}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Refund Date",
      cell: (row) =>
        row.refund_date && row.refund_date !== ""
          ? moment(row.refund_date).format("DD-MM-YYYY")
          : "",
    },
    {
      name: "Status",
      cell: (row) => (
        <a
          href="javascript:void(0)"
          className={`btn ${
            row.status === "succeeded" ? "btn-success" : "btn-primary"
          }`}
          style={{ fontSize: "10px" }}
        >
          {row.status ? row.status : "N/A"}
        </a>
      ),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          {/* Payment Disputes Icon, only visible if plan is not 'Free' */}
          {row.plan !== "Free" && (
            <a className="dataedit_btn" title="Payment Disputes">
              <FontAwesomeIcon icon={faComment} />
            </a>
          )}

          {/* Invoice Send Icon */}
          {row.plan !== "Free" && (
            <a
              className="dataedit_btn"
              title="Invoice Send"
              onClick={() => handleSendInvoice(row)}
            >
              <FontAwesomeIcon icon={faFileInvoice} />
            </a>
          )}
          {row.plan !== "Free" && (
            <a
              className="dataedit_btn"
              title="Invoice View"
              onClick={() => handleSendInvoice(row)}
            >
              <FontAwesomeIcon icon={faEye} />
            </a>
          )}

          {/* Refund Icon, only visible if plan is not 'Free' */}
          {row.plan !== "Free" && row.refund !== "Succeeded" && (
            <a
              className="dataedit_btn"
              title="Refund"
              onClick={() => handleRefund(row)}
            >
              <FontAwesomeIcon icon={faUndo} />
            </a>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleSendInvoice = async (row) => {
    let formData = {
      id: row.id,
    };
    try {
      const res = await axios.post(apiUrl + "getinvoieData", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      setinvoiceData(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    const mainpdf = (
      <div
        style={{
          width: "100%",
          margin: "50px auto",
          backgroundColor: "#ffffff",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        {/* Header Section */}
        <table
          style={{
            width: "100%",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <tbody>
            <tr>
              <td>
                <img
                  src={require("../../assets/images/logo.png")}
                  alt="Logo"
                  style={{
                    maxWidth: "350px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "20px",
                  color: "rgb(17, 16, 16)",
                  textTransform: "capitalize",
                  borderBottom: "2px solid #eee",
                  paddingBottom: "10px",
                }}
              >
                Refund from Amourette
              </td>
            </tr>
          </tbody>
        </table>

        {/* Refunded and Date Issue Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "20px",
          }}
        >
          {/* Refunded Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                color: "rgb(95, 91, 91)",
                fontWeight: "bold",
              }}
            >
              Refunded
            </span>
            <span
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </span>
          </div>

          {/* Date Issue Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                color: "rgb(95, 91, 91)",
                fontWeight: "bold",
              }}
            >
              Date Issue
            </span>
            <span
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              {moment(invoiceData.refund_date).format("MMM D, YYYY")}
            </span>
          </div>
        </div>

        {/* Confirmation Text */}
        <div
          style={{
            textAlign: "justify", // Makes the text align evenly on both sides
            color: "rgb(17, 16, 16)",
            fontSize: "16px",
            lineHeight: "1.6", // Adjusts the spacing between lines for better readability
            marginTop: "20px",
            padding: "20px",
            borderBottom: "2px solid #eee",
          }}
        >
          This email is to confirm that your refund has been issued by
          <strong> Amourette</strong>. It can take approximately
          <strong> 10 days</strong> to appear on your statement. If it takes
          longer, please contact your bank for assistance.
        </div>

        {/* Summary Section */}
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f9f9f9", // Special background color
            borderRadius: "8px", // Optional: Rounded corners
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add some shadow
          }}
        >
          {/* Main Stats Section */}

          {/* Payment To and Total Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Payment To */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Payment To Amourette
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Total */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Total
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Total */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Refunded on{" "}
              {moment(invoiceData.refund_date).format("MMM D, YYYY")}
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Total */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Adjusted Total
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>
        </div>
      </div>
    );
    const mainpdfrecepit = (
      <div
        style={{
          width: "100%",
          margin: "50px auto",
          backgroundColor: "#ffffff",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        {/* Header Section */}
        <table
          style={{
            width: "100%",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <tbody>
            <tr>
              <td>
                <img
                  src={require("../../assets/images/logo.png")}
                  alt="Logo"
                  style={{
                    maxWidth: "350px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "20px",
                  color: "rgb(17, 16, 16)",
                  textTransform: "capitalize",
                  borderBottom: "2px solid #eee",
                  paddingBottom: "10px",
                }}
              >
                Receipt from Amourette
              </td>
            </tr>
          </tbody>
        </table>

        {/* Receipt and Date Issue Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "20px",
          }}
        >
          {/* Receipt Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                color: "rgb(95, 91, 91)",
                fontWeight: "bold",
              }}
            >
              Amount Paid
            </span>
            <span
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </span>
          </div>

          {/* Date Issue Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                color: "rgb(95, 91, 91)",
                fontWeight: "bold",
              }}
            >
              Date Paid
            </span>
            <span
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              {moment(invoiceData.start_date).format("MMM D, YYYY")}
            </span>
          </div>
        </div>

        {/* Confirmation Text */}

        {/* Summary Section */}
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f9f9f9", // Special background color
            borderRadius: "8px", // Optional: Rounded corners
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add some shadow
          }}
        >
          {/* Main Stats Section */}

          {/* Payment To and Total Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Payment To */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Payment To Amourette
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "15px 20px",
              backgroundColor: "#fff", // Light background for contrast
              borderRadius: "8px", // Optional: Rounded corners
            }}
          >
            {/* Total */}
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgb(95, 91, 91)",
              }}
            >
              Amount charged
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgb(95, 91, 91)",
              }}
            >
              ${invoiceData.amount}
            </div>
          </div>
        </div>
      </div>
    );
    if (invoiceData !== "") {
      if (invoiceData.refund === "Succeeded") {
        generatePDF(mainpdf);
      } else {
        generatePDF(mainpdfrecepit);
      }
    }
  }, [invoiceData]);
  const handleRefund = async (row) => {
    const paymentIntentId = row.payment_id;
    let formData = {
      paymentIntentId: paymentIntentId,
    };
    try {
      const res = await axios.post(apiUrl + "paymentrefund", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log(res.data);
      var respo = res.data.result;
      fetchData();
      if (res.data.success === true) {
        setPopsuccessMessage("Payment refunded successfully!");
        setShowPopSuccess(true);
        setShowPopError(false);
        setTimeout(() => {
          setPopsuccessMessage("");
          setShowPopSuccess(false);
        }, 3500);
      }
      if (res.data.success === false) {
        setPoperrorMessage(res.data.error);
        setShowPopSuccess(false);
        setShowPopError(true);
        setTimeout(() => {
          setPoperrorMessage("");
          setShowPopError(false);
        }, 3500);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallpayment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  // Define filtered data based on the search query

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          {showPopError && <ErrorPop message={errorPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Payment History</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PaymentSubscription;
