import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Headertwo from "../components/Headertwo";
import evenphoto from "../assets/images/pic.png";
import iconphoto from "../assets/images/me3.png";
import chat from "../assets/images/chatpost.png";
import heart from "../assets/images/sheart.png";
import postpic from "../assets/images/pic.png";
import { Createnewgrouppost } from "../components/Createnewgrouppost";
import { GroupPostComment } from "../components/GroupPostComment";
import mempic from "../assets/images/pic.png";
import { GroupInviteFriend } from "../components/GroupInviteFriend";
import { AlertDelete } from "../components/AlertDelete";
import axios from "axios";
export default function Singlegroup() {
  const navigate = useNavigate();
  var apiUrl = "https://backend.amourette.no/api/groups/";
  const [showPopError, setShowPopError] = useState(false);
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [isPopupOpen_newpost, setIsPopupOpen_newpost] = useState(false);
  const [isPopupOpen_delete, setisPopupOpen_delete] = useState(false);
  const [deleteMessage, setdeleteMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [LoginData, setLoginData] = useState(null);
  const [groupId, setgroupId] = useState("");
  const [groupdetail, setgroupdetail] = useState("");
  const [showhide, setshowhide] = useState(false);
  const [groupView, setgroupView] = useState(false);
  const [groupAcceptorNo, setgroupAcceptorNo] = useState(false);
  const [groupAccepted, setgroupAccepted] = useState(false);
  const [groupAcceptorNoCheck, setgroupAcceptorNoCheck] = useState(false);
  const [isInterested, setIsInterested] = useState(false);
  const [GroupDetailIntersted, setGroupDetailIntersted] = useState([]);
  const [isGrouppost, setGrouppost] = useState([]);
  const [allyourgroupsUser, setallyourgroupsUser] = useState([]);
  const [Isslug, setSlug] = useState("");
  const { slug } = useParams();
  const storedUsername = localStorage.getItem("userLogin");
  const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    setLoginData(userLogin);
  }, []);
  useEffect(() => {
    if (LoginData) {
      UsercheckAccept(); // Call UsercheckAccept only when LoginData is available
    }
    if (slug !== undefined) {
      setSlug(slug); // Ensure setSlug is defined and does not cause issues
      getDetails(slug);
    }
  }, [LoginData, groupView, slug]); // Dependency on LoginData
  useEffect(() => {
    if (LoginData && groupId) {
      get_GroupdetailallIntersted();
      getpostComment(groupId);
    }
  }, [LoginData, groupId]);

  useEffect(() => {
    if (groupId) {
      getuserGroupIntersted(groupId);
    }
  }, [groupId]);
  const getuserGroupIntersted = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      group_id: groupId,
      user_id: userLogin.id,
    };

    try {
      const res = await axios.post(
        apiUrl + "get_userGroupIntersted",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
      console.log("vv");
      console.log(respo);
      if (respo.length > 0) {
        setIsInterested(true); // User is now interested
      } else {
        setIsInterested(false); // User is now interested
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const getDetails = async (slug) => {
    let formData = {
      slug: slug,
    };

    try {
      const res = await axios.post(apiUrl + "getGroupDetailSlug", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.group;
      console.log(respo);
      console.log("vh");
      setgroupdetail(respo);

      if (res.data.group === "" || res.data.group === undefined) {
        navigate("/groups");
      } else {
        console.log(LoginData);
        if (LoginData && LoginData.id === res.data.group.user_id) {
          setshowhide(true);
        } else {
          setshowhide(false);
        }
      }
      setgroupId(respo.id);
      await UsercheckAccept();
      //getpostComment(groupId);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const UsercheckAccept = async () => {
    let formData = {
      user_id: LoginData.id,
      slug: slug,
    };
    //return;
    try {
      const res = await axios.post(apiUrl + "UsercheckAccept", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      if (respo.length === 0) {
        setgroupView(false);
        setgroupAcceptorNo(false);
        // navigate("/events");
      } else {
        if (respo[0].group_status === "Created by You") {
          console.log(respo[0].group_status);
          setgroupView(true);
          setgroupAcceptorNo(false);
          setgroupAcceptorNoCheck(false);
        }
        if (respo[0].invite_status === "No") {
          console.log("1");
          setgroupView(false);
          setgroupAcceptorNo(true);
        }
        if (respo[0].invite_status === "Yes") {
          console.log("2");
          setgroupView(true);
          setgroupAccepted(false);
          setgroupAcceptorNo(false);
          setgroupAcceptorNoCheck(true);
        }
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        //console.error("Error message:", err.message);
      }
    }
  };
  const get_GroupdetailallIntersted = async () => {
    let formData = {
      group_id: groupId,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(
        apiUrl + "getGroupdetailAllIntersted",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );

      setGroupDetailIntersted(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getallYourgroupsUser = async () => {
    let formData = {
      user_id: LoginData.id,
      group_id: groupId,
    };

    try {
      const res = await axios.post(apiUrl + "getallYourgroupsUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      setallyourgroupsUser(res.data.groups);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  const openPopup1 = (id) => {
    console.log(id);
    setIsPopupOpen1(true);
    setgroupId(id);
    getallYourgroupsUser();
    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };
  const handleInterested = async () => {
    let formData = {
      group_id: groupId,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "userGroupIntersted", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("hh");
      //getEventdetailallIntersted(groupId);
      if (res.data.status === "1") {
        setIsInterested(true); // User is now interested
      } else if (res.data.status === "2") {
        setIsInterested(false); // User is no longer interested
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleAccept = async () => {
    let groupAccepted = {
      user_id: LoginData.id,
      group_id: groupId,
    };

    try {
      const res = await axios.post(apiUrl + "groupAccepted", groupAccepted, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setShowPopError(true);
      setPoperrorMessage("Group invite updated successfully");
      UsercheckAccept();
      // get_GroupdetailallIntersted();
      setTimeout(() => {
        setShowPopError(false);
      }, 800);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handledeleteSentto = async () => {
    let deleteev = {
      user_id: LoginData.id,
      group_id: groupId,
    };

    try {
      const res = await axios.post(apiUrl + "DeleteInviteRequest", deleteev, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setShowPopError(true);
      setPoperrorMessage("Successfully deleted");
      navigate("/groups");
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const open_DeleteAlert = (id) => {
    setisPopupOpen_delete(true);
    setdeleteMessage("Are you sure? You want delete this group");
  };
  const closePopup_delete = () => {
    setisPopupOpen_delete(false);
  };
  const refreshpopup = async () => {
    deleteGroup();
  };
  const deleteGroup = async () => {
    let deleteev = {
      user_id: LoginData.id,
      group_id: groupId,
    };
    console.log(deleteev);
    try {
      const res = await axios.post(apiUrl + "userDeleteGroup", deleteev, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      navigate("/groups");
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const refreshFriendsList = () => {
    getallYourgroupsUser(groupId);
  };
  const getpostComment = async (idd) => {
    let formData = {
      group_id: idd,
      user_id: LoginData.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "get_postComment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("res.data");
      setGrouppost(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const Isgroupfav = async () => {
    getpostComment(groupId);
  };
  const refreshPostComment = () => {
    getpostComment(groupId);
    setIsPopupOpen_newpost(false);
  };
  const commentSectionRefresh = async () => {
    getpostComment(groupId);
  };
  const getpostCommentrefresh = async () => {
    getpostComment(groupId);
  };
  return (
    <>
      <Headertwo />

      <section className="singleevent d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-7">
              <div className="d-flex flex-column gap-4">
                <div className="eventtitle d-block pb-1">
                  <h1>{groupdetail.name}</h1>
                </div>
                <div className="d-flex flex-column gap-2">
                  {showhide && (
                    <div className="evname d-block">
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-shrink-0">
                            <div className="evephoto ofit rounded-circle overflow-hidden">
                              {groupdetail.image.match(
                                /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                              ) ? (
                                <img src={groupdetail.image} alt="img" />
                              ) : groupdetail.image.match(
                                  /\.(mp4|mov|avi)$/i
                                ) ? (
                                <video controls alt="video">
                                  <source
                                    src={groupdetail.image}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div
                              onClick={openPopup}
                              className="whatthink d-block"
                            >
                              <h4>What do you think?</h4>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end addphotothink">
                          <div
                            onClick={openPopup}
                            className="d-flex gap-2 align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="flex-grow-1">
                              <h4>Add photo</h4>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="iconphoto fulw">
                                <img src={iconphoto} alt="namepic" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {groupView ? (
                    <GroupPostComment
                      isGrouppost={isGrouppost}
                      LoginData={LoginData}
                      Isgroupfav={Isgroupfav}
                      commentSectionRefresh={commentSectionRefresh}
                      getpostCommentrefresh={getpostCommentrefresh}
                    />
                  ) : (
                    <p>No group view available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between posttopbtns">
                  {groupAcceptorNo && (
                    <button
                      type="button"
                      onClick={handleAccept}
                      className="btn prbtn"
                    >
                      Accept
                    </button>
                  )}
                  {groupAcceptorNoCheck && (
                    <button
                      type="button"
                      className={`btn prbtn ${isInterested ? "" : ""}`}
                    >
                      Accepted
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-between posttopbtns">
                  {!showhide && (
                    <button
                      type="button"
                      onClick={handleInterested}
                      className={`btn prbtn ${
                        isInterested ? "interested_btn" : ""
                      }`}
                    >
                      INTERESTED
                    </button>
                  )}
                  {groupAcceptorNo && (
                    <button
                      type="button"
                      onClick={handledeleteSentto}
                      className="btn prbtn"
                    >
                      Delete
                    </button>
                  )}

                  {showhide && (
                    <button
                      type="button"
                      onClick={() => openPopup1(groupId)}
                      className="btn sebtn"
                    >
                      INVITE A MEMBER
                    </button>
                  )}
                </div>
                {showhide && (
                  <div className="d-flex justify-content-between posttopbtns">
                    <button type="button" className="btn prbtn">
                      SAVE CHANCES
                    </button>
                    <button
                      type="button"
                      onClick={() => open_DeleteAlert(groupId)}
                      className="btn redbtn"
                    >
                      DELETE Group
                    </button>
                  </div>
                )}
                <div className="detailbox d-flex flex-column gap-2">
                  <h3>About</h3>
                  <p>{groupdetail.description}</p>
                  {/* <div className="d-block vmore">
                    <Link to="/">View More</Link>
                  </div> */}
                </div>
                {groupdetail.user_id === userLogin.id && (
                  <div className="detailbox d-flex flex-column gap-2">
                    <h3>Members interested </h3>
                    <div className="row g-2 g-md-4">
                      {GroupDetailIntersted &&
                      GroupDetailIntersted.length > 0 ? (
                        GroupDetailIntersted.map((member, index) => (
                          <div className="col-2 col-md-3" key={index}>
                            <div className="memperson ofit overflow-hidden rounded-3">
                              {member.profile_image ? ( // Display the image URL if available
                                <img
                                  className="w-100 h-100"
                                  src={member.profile_image} // Use the URL created from the file
                                  alt="Selected"
                                />
                              ) : (
                                <img
                                  className="w-100 h-100"
                                  src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          <p>No results found</p>
                        </div>
                      )}
                    </div>
                    {/* <div className="d-block vmore">
                    <Link to="/">View More</Link>
                  </div> */}
                  </div>
                )}
                <div className="detailbox d-flex flex-column gap-2">
                  <h3>Media</h3>
                  <div className="row g-2 g-md-4">
                    {isGrouppost.length > 0 ? (
                      isGrouppost.map((post, index) => (
                        <div className="col-sm-3 col-md-6" key={index}>
                          <div className="memperson ofit overflow-hidden rounded-3">
                            {post.file.match(
                              /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                            ) ? (
                              <img src={post.file} alt="img" />
                            ) : post.file.match(/\.(mp4|mov|avi)$/i) ? (
                              <video controls alt="video">
                                <source src={post.file} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No media available.</p>
                    )}
                  </div>
                  <div className="d-block vmore">
                    <Link to={`/media/${slug}`}>View More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GroupInviteFriend
        isOpen1={isPopupOpen1}
        onClose1={closePopup1}
        friends={allyourgroupsUser}
        LoginData={LoginData}
        groupId={groupId}
        groupdetail={groupdetail}
        onInvitesSent={refreshFriendsList}
      ></GroupInviteFriend>
      <Createnewgrouppost
        isOpen={isPopupOpen}
        onClose={closePopup}
        groupId={groupId}
        LoginData={LoginData}
        reSetPost={refreshPostComment}
      ></Createnewgrouppost>
      <AlertDelete
        isOpen1={isPopupOpen_delete}
        onClose1={closePopup_delete}
        message={deleteMessage}
        onReturnSent={refreshpopup}
      ></AlertDelete>
    </>
  );
}
