import React, { useState } from "react";
import axios from "axios";
import { BsCloudUpload } from "react-icons/bs";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
export const Createnewevent = ({ isOpen, onClose, onEventCreated }) => {
  // Initialize state for the checkbox only
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [makeImagePrivate, setMakeImagePrivate] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [eventData, setEventData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    time: "",
    location: "",
    description: "",
    image: null,
  });
  var apiUrl = "https://backend.amourette.no/";
  const [restr_message, setrestr_message] = useState(false);
  const today = new Date(); // Get today's date
  const day = String(today.getDate()).padStart(2, "0"); // Get day and ensure 2 digits
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Get month and ensure 2 digits (months are zero-indexed)
  const year = today.getFullYear(); // Get the full year

  const today_enddate = `${day}-${month}-${year}`;
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    // Check for restricted words only for text inputs
    if (type === "text" || type === "textarea") {
      const hasRestrictedWord = restrictedWords.some((word) =>
        value.toLowerCase().includes(word.toLowerCase())
      );

      if (hasRestrictedWord) {
        // Display a message or set an error state
        setrestr_message(true);
      } else {
        setrestr_message(false);
      }
    }

    // Update event data
    setEventData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setMakeImagePrivate(checked);
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    setspinner(true);
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("makeImagePrivate", makeImagePrivate);
      formData.append("user_id", userLogin.id);
      formData.append("fileType", "events");
      formData.append("image", selectedFile);
      for (const key in eventData) {
        formData.append(key, eventData[key]);
      }

      const response = await axios.post(apiUrl + "api/events", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setspinner(false);
      if (response.data.status === "2") {
        setShowPopError(true);
        setShowPopError(response.data.message);
        setTimeout(() => {
          setShowPopError(false);
          setShowPopError("");
          onClose();
        }, 1000);
      } else {
        setShowPopSuccess(true);
        setPopsuccessMessage(response.data.message);
        setTimeout(() => {
          setShowPopError(false);
          setShowPopSuccess(false);
          //navigate("/login");
          onEventCreated(response.data);
          onClose();
        }, 500);
        console.log("Event created:", response.data);
        setEventData({
          name: "",
          start_date: "",
          end_date: "",
          time: "",
          location: "",
          description: "",
          image: null,
        });
        setSelectedFile(null);
      }

      // Close the popup after submission
    } catch (error) {
      setspinner(false);
      setShowPopError(true);
      setPoperrorMessage("Some thing went wrong,Please try again:", error);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopError("");
        onClose();
      }, 1000);
    }
  };

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          {showPopError && <ErrorPop message={errorPopMessage} />}
          <div className="cneventup d-flex flex-column gap-4">
            <h3>Create new event</h3>

            <form onSubmit={handleSubmit}>
              <div className="row gy-4">
                <div className="col-md-4">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      required
                      name="image"
                      onChange={handleFileChange}
                      className="fileload"
                    />
                    <div className="upload_image">
                      {selectedFile && (
                        <img
                          className="w-100 h-100"
                          src={URL.createObjectURL(selectedFile)} // Create a URL for the selected image
                          alt="Selected"
                        />
                      )}
                    </div>
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>Upload image *</h3>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="Event name *"
                    name="name"
                    value={eventData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <div className="datetimeline rel">
                    <label className="stextlabel">Start date *</label>
                    <input
                      type="date"
                      name="start_date"
                      value={eventData.start_date}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="datetimeline rel">
                    <label className="stextlabel">End date *</label>
                    <input
                      type="date"
                      name="end_date"
                      value={eventData.end_date}
                      minLength={today_enddate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="datetimeline rel">
                    <label className="stextlabel">Time *</label>
                    <input
                      type="time"
                      name="time"
                      value={eventData.time}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="Location"
                    name="location"
                    value={eventData.location}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    name="description"
                    rows="5"
                    placeholder="Event description"
                    value={eventData.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="col-12">
                  <div className="listbox">
                    <label className="cbox">
                      Make Private
                      <input
                        type="checkbox"
                        name="makeImagePrivate"
                        checked={makeImagePrivate} // Use the state directly
                        onChange={handleCheckboxChange} // Use dedicated handler
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                {restr_message && (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    Your message contains restricted words. Please remove them
                  </span>
                )}
                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button type="submit">Create new event</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
