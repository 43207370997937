import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const apiUrl = "https://backend.amourette.no/api/admin/";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"]; // Necessary libraries for Google Maps API

export default function Locationbaseusers() {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]); // To store locations data
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAYcUdjNCv4L5ADBoSdmeP0Sfzfshsea4", // Use .env for API keys
    libraries,
  });

  useEffect(() => {
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, [navigate]);

  useEffect(() => {
    const getAllUserLocations = async () => {
      try {
        const res = await axios.post(
          apiUrl + "getalluserlocation",
          {},
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setTimeout(() => {
          setLocations(res.data.result);
        }, 3000);
      } catch (err) {
        console.error("Error fetching locations:", err.message);
      }
    };

    getAllUserLocations();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggleNavAdmin = () => {
    setIsOpen(!isOpen);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <div className="position-relative bg-white d-flex p-0">
      <Headeradmin />
      <div className={`content`}>
        <NavAdmin onClick={toggleNavAdmin} />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4 speed_txt">
            <div className="col-12">
              <div className="bg-light rounded h-100 p-4">
                <a
                  href="/admin/users"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  className="mb-4"
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "8px" }}
                  />{" "}
                  Back
                </a>

                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{ lat: 60.472, lng: 8.4689 }}
                  zoom={5}
                >
                  {locations.map(
                    (location) =>
                      location.latitude !== null &&
                      location.longitude !== null && (
                        <Marker
                          key={location.id}
                          position={{
                            lat: parseFloat(location.latitude),
                            lng: parseFloat(location.longitude),
                          }}
                          title={`User ID: ${location.region}`}
                          onClick={() => setSelectedLocation(location)}
                        />
                      )
                  )}

                  {selectedLocation && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(selectedLocation.latitude),
                        lng: parseFloat(selectedLocation.longitude),
                      }}
                      onCloseClick={() => setSelectedLocation(null)}
                    >
                      <div>
                        {selectedLocation.profile_image &&
                        selectedLocation.profile_image.trim() !== "" &&
                        selectedLocation.profile_image !== null ? (
                          <img
                            src={selectedLocation.profile_image}
                            alt="Profile"
                            width="60"
                            height="60"
                          />
                        ) : (
                          <p>No Profile Image</p>
                        )}
                        <h4>{selectedLocation.username}</h4>
                        <h5>
                          {selectedLocation.region} (
                          {selectedLocation.country_name})
                        </h5>
                        <p>City: {selectedLocation.city}</p>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
