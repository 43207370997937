import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faCalendarAlt,
  faUserPlus,
  faComment,
  faStar,
  faPenToSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
export default function UsersEvents() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [currentRecord, setCurrentRecord] = useState({
    name: "",
    id: "",
    start_date: "",
    end_date: "",
    time: "",
    location: "",
    description: "",
    image: null,
  });
  const [deleteidd, setdeleteidd] = useState("");
  const [records, setRecords] = useState([]);
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [spinner, setspinner] = useState(false);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);
  useEffect(() => {
    getuserlogslist();
  }, []);

  const getuserlogslist = async () => {
    let formData = {
      user_id: "",
    };

    try {
      const res = await axios.post(apiUrl + "getuserlogslist", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      //console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const columns = [
    {
      name: "message",
      selector: (row) => (
        <>
          <strong>{row.username}</strong> {row.description}
        </>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            href="javascript:void(0)"
            onClick={() => handledelete(row.id)}
            className="dataedit_btn text-danger"
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handledelete = async (id) => {
    setalertpopup(true);
    setdeleteidd(id);
    setmessageset("Are you sure? You want to delete this ");
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: deleteidd,
    };
    try {
      const res = await axios.post(apiUrl + "deletelogs", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getuserlogslist();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  //Location
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };

  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form action="javascript:void(0)" method="post">
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h6 className="mb-4">Logs Activity</h6>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Start of row */}
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredRecords}
                        pagination
                        highlightOnHover
                        noDataComponent="No records found."
                      />
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
