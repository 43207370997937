import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { useNavigate } from "react-router-dom";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import { faEye, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SecurityPrivacy() {
  const [alluserpayment, setalluserpayment] = useState([]);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const navigate = useNavigate();
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [showModalIp, setShowModalIp] = useState(false);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [Iplocation, setIplocation] = useState([]);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [MessageData, setMessageData] = useState([]);
  const [usernameset, setusername] = useState("");
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  const [records, setRecords] = useState([]);
  const [recordss, setRecordss] = useState([]);

  const columns = [
    { name: "User", selector: (row) => row.username, sortable: true },
    {
      name: "Online Status",
      selector: (row) => row.online_user,
      sortable: true,
    },
    { name: "IP Address", selector: (row) => row.IpAddress, sortable: true },
    {
      name: "Login Attempts",
      selector: (row) => row.total_logins,
      sortable: true,
    },
    {
      name: "Last Login",
      selector: (row) =>
        row.last_activity
          ? moment(row.last_activity).format("DD-MM-YYYY")
          : "N/A",
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            className="dataedit_btn"
            title="View Multiple Login Attempt"
            onClick={() => handleModalShowIp(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getuserdetailAll", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        setRecords(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    fetchData();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  // Define filtered data based on the search query
  const handleModalCloseIp = () => {
    setShowModalIp(false);
    setShowModalMessage(false);
  };
  const handleModalShowIp = async (row) => {
    setShowModalIp(true);

    let formData = {
      id: row.id,
    };
    try {
      const res = await axios.post(apiUrl + "getUserMultipleLogin", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log(res.data.result);
      setIplocation(res.data.result);
      setRecordss(res.data.result);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const columnss = [
    { name: "TimeZone", selector: (row) => row.timezone, sortable: true },
    {
      name: "Country",
      selector: (row) => row.country_name,
      sortable: true,
    },
    { name: "IP Address", selector: (row) => row.IpAddress, sortable: true },
    {
      name: "Lat",
      selector: (row) => row.latitude,
      sortable: true,
    },
    {
      name: "Long",
      selector: (row) => row.longitude,
      sortable: true,
    },
    {
      name: "Region",
      selector: (row) => row.region,
      sortable: true,
    },
    {
      name: "Days",
      selector: (row) => formateTime(row.date),
      sortable: true,
    },
  ];
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuerys, setSearchQuerys] = useState("");

  // Define filtered data based on the search query
  const filteredRecordss = recordss.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuerys.toLowerCase())
  );
  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          {showPopError && <ErrorPop message={errorPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">User Login Attempts</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalIp}
        className="edit_admin securitypage"
        onHide={handleModalCloseIp}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">View Login Attempt</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", height: "500px" }}>
          <div className="d-flex flex-md-row flex-column justify-content-between">
            <h6 className="mb-4"></h6>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchQuerys}
                onChange={(e) => setSearchQuerys(e.target.value)}
              />
            </div>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columnss}
              data={filteredRecordss}
              pagination
              highlightOnHover
              noDataComponent="No records found."
            />
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalCloseIp}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SecurityPrivacy;
