import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
import Select from "react-select";
export default function UsersFriendlist() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [UserDetail, setUserDetail] = useState("");
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      console.log("j");
      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  useEffect(() => {
    if (UserDetail !== "") {
      const intervalId = getuserfriendlist(() => {
        getuserfriendlist(); // Call your function
      }, 3500);

      // Clear the interval when the component unmounts or UserDetail changes
      return () => clearInterval(intervalId);
    }
  }, [UserDetail]);
  const getuserfriendlist = async () => {
    let formData = {
      user_id: UserDetail.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getuserfriendlist", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  setInterval(() => {
    getuserfriendlist();
  }, 3500);
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    ,
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            href={`/admin/usersfriendlist/${row.slug}`}
            className="dataedit_btn text-danger"
            title="View Friend List"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  //Location
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form action="javascript:void(0)" method="post">
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <Link
                      to="/admin/users"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                      className="mb-4"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      Back
                    </Link>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h6 className="mb-4">
                          Friends List ({UserDetail.username})
                        </h6>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Start of row */}
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredRecords}
                        pagination
                        highlightOnHover
                        noDataComponent="No records found."
                      />
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
