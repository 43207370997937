import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Headertwosetting from "../components/Headertwosetting";
import { Footer } from "../components/Footer";
import ms1 from "../assets/images/ms1.png";
import ms2 from "../assets/images/ms2.png";
import ms3 from "../assets/images/ms3.png";
import ms4 from "../assets/images/ms4.png";
import userpic from "../assets/images/pic.png";
import { UpgradeMembership } from "../components/UpgradeMembership";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Preference } from "../components/Preference";
import { Security } from "../components/Security";

import { Helpcenter } from "../components/Helpcenter";
import axios from "axios";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Setting() {
  const apiUrl = "https://backend.amourette.no/api/";
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userdata, setuserdata] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen11, setIsPopupOpen11] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };

  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const openPopup1 = () => {
    setIsPopupOpen1(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);

  const openPopup2 = () => {
    setIsPopupOpen2(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup2 = () => {
    setIsPopupOpen2(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };

  const openPopup11 = () => {
    setIsPopupOpen11(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup11 = () => {
    setIsPopupOpen11(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const setSelectedIndexcheck = (ch) => {
    if (ch === 1) {
      setIsPopupOpen11(true);
    }
    if (ch === 2) {
      setIsPopupOpen1(true);
    }
    if (ch === 3) {
      setIsPopupOpen2(true);
    }
    setSelectedIndex(ch);
  };
  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getUserDetailsetting", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setuserdata(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  return (
    <>
      <Headertwosetting />
      <section className="d-block membershipbox gapy">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Tabs
                className="member-tabs"
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndexcheck(index)}
              >
                <TabList>
                  <Tab>
                    {" "}
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms1} alt="userpic" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>{t("Membership_and_billing")}</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    {" "}
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms2} alt="userpic" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>{t("Preferences")}</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    {" "}
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms3} alt="userpic" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>{t("Security")}</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    {" "}
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms4} alt="userpic" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>{t("Help_center")}</h4>
                      </div>
                    </div>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="d-flex flex-column gap-4 mship">
                    <h1 className="shiptitle">{t("Your_membership")}</h1>
                    <div className="meuser w-100">
                      <div className="row flex-row-reverse g-4">
                        <div className="col-lg-4 col-xxl-3">
                          <div className="usermship ofit">
                            <img src={userdata.profile_image} alt="usrpic" />
                          </div>
                        </div>
                        <div className="col-lg-8 col-xxl-9">
                          <div className="d-flex flex-column gap-5 yourmship">
                            <h5>{userdata.username}</h5>
                            <div className="d-flex flex-column gap-2">
                              <h6>
                                {t("Membership_type")}: <b>{userdata.plan}</b>
                              </h6>
                              <h6>
                                {t("Member_since")}:{" "}
                                <b>
                                  {moment(userdata.start_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </b>
                              </h6>
                            </div>
                            <div className="d-flex flex-column flex-md-row gap-3 shipbtns">
                              <div>
                                <button
                                  onClick={openPopup}
                                  type="button"
                                  className="btn pribtn"
                                >
                                  {t("Upgrade")}
                                </button>
                              </div>
                              {/* <div>
                                <button type="button" className="btn celbtn">
                                  Cancel
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <Preference
                    isOpen={isPopupOpen11}
                    onClose={closePopup11}
                  ></Preference>
                </TabPanel>
                <TabPanel>
                  <Security
                    isOpen={isPopupOpen1}
                    onClose={closePopup1}
                  ></Security>
                </TabPanel>
                <TabPanel>
                  <Helpcenter
                    isOpen={isPopupOpen2}
                    onClose={closePopup2}
                  ></Helpcenter>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <UpgradeMembership
        isOpen={isPopupOpen}
        onClose={closePopup}
      ></UpgradeMembership>
    </>
  );
}

export default Setting;
