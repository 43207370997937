import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import ErrorPop from "../../components/Admin/ErrorPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";

import Select from "react-select";
export default function UsersSentmessage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [UserDetail, setUserDetail] = useState("");
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  const [MessageData, setMessageData] = useState([]);
  const [records, setRecords] = useState([]);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [messageIdToDelete, setmessageIdToDelete] = useState("");
  const [errormessage, seterrormessage] = useState(false);
  const [Viewmodalfile, setViewmodalfile] = useState(false);
  const [viewfiles, setviewfiles] = useState([]);
  const [modalEdit, setmodalEdit] = useState(false);
  const [modalEditfile, setmodalEditfile] = useState(false);
  const [msg, setmsg] = useState("Successfully deleted");
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [spinner, setspinner] = useState(false);

  const [Isfile, setFiles] = useState([]);
  const [error, seterror] = useState("");
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      if (respo === undefined) {
        navigate("/admin/users");
      }
      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    getUserSentMessage();
  }, [UserDetail, slug]);
  const getUserSentMessage = async () => {
    if (UserDetail.id !== undefined) {
      let formData = {
        user_id: UserDetail.id,
      };
      console.log(formData);
      try {
        const res = await axios.post(apiUrl + "getUserSentMessage", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        console.log("jkjk");
        setRecords(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    }
  };
  //Location
  const columns = [
    {
      name: "Sender",
      selector: (row) => row.sender_username,
      sortable: true,
    },
    {
      name: "Receiver",
      selector: (row) => row.recipient_username,
      sortable: true,
    },

    { name: "Message", selector: (row) => row.message, sortable: true },
    {
      name: "File",
      selector: (row) => {
        if (
          row.message &&
          row.message.trim() !== "" &&
          JSON.parse(row.file).length === 0
        ) {
          return "N/A";
        } else if (
          Array.isArray(JSON.parse(row.file)) &&
          JSON.parse(row.file).length > 0
        ) {
          return (
            <>
              <a
                className="dataedit_btn"
                title="View"
                onClick={() => handleViewfile(row)}
              >
                {JSON.parse(row.file).length}{" "}
              </a>
            </>
          );
        } else {
          return "N/A";
        }
      },
      sortable: true,
    },

    {
      name: "Time",
      selector: (row) => (row.date ? formateTime(row.date) : "N/A"),
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            className="dataedit_btn"
            title="Edit"
            onClick={() => handleEdit(row)}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </a>
          <a
            className="dataedit_btn text-danger"
            title="Delete"
            onClick={() => handleDelete(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleEdit = (row) => {
    if (row.message !== "" && row.message !== null) {
      setmodalEdit(true);
    } else {
      setmodalEditfile(true);
    }
    setCurrentRecord(row);
  };
  const handleDelete = (row) => {
    setmessageIdToDelete(row.id);
    setalertpopup(true);
    setmessageset("Are you sure? If you want to delete this message");
  };
  const handleViewfile = (row) => {
    setViewmodalfile(true);
    try {
      // If row.file is not an empty string, try to parse it as JSON
      if (row.file && row.file.trim() !== "") {
        const parsedFiles = JSON.parse(row.file);
        setviewfiles(parsedFiles);
      } else {
        setviewfiles([]);
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setviewfiles([]);
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: messageIdToDelete,
    };
    seterrormessage(true);
    try {
      const res = await axios.post(apiUrl + "deleteusersmessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getuserdetail();
      setTimeout(() => {
        seterrormessage(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const handleModalClose = () => {
    setViewmodalfile(false);
    setmodalEdit(false);
    setmodalEditfile(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  const getFileSrc = (fileUrl) => {
    // List of allowed file extensions for online files
    const allowedExtensions = [".png", ".jpg", ".jpeg", ".gif"];

    // Check if the file URL ends with any of the allowed extensions
    const isAllowedExtension = allowedExtensions.some((ext) =>
      fileUrl.endsWith(ext)
    );

    // If the file URL has an allowed extension, return the URL, else return the local image
    return isAllowedExtension
      ? fileUrl
      : require("../../assets/images/images.png");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = {
      id: currentRecord.id,
      message: currentRecord.message,
    };
    try {
      const res = await axios.post(apiUrl + "chatupdate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setmsg("Successfully updated");
      var respo = res.data;
      setmodalEdit(false);
      seterrormessage(true);
      getUserSentMessage();
      setTimeout(() => {
        seterrormessage(false);
        setmsg("Successfully deleted");
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleSubmitfile = async () => {
    const formData = new FormData();
    setspinner(true);
    formData.append("fileType", "chats");
    formData.append("id", currentRecord.id);

    Isfile.forEach((file) => {
      formData.append("files", file); // Use the key 'files' for your backend to handle multiple files
    });

    try {
      const res = await axios.post(apiUrl + "chatFileupdate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data", // Correct content type for file uploads
        },
      });
      setmsg("Successfully updated");
      setspinner(false);
      var respo = res.data;
      setmodalEditfile(false);
      seterrormessage(true);
      getUserSentMessage();
      setTimeout(() => {
        seterrormessage(false);
        setmsg("Successfully deleted");
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Response error:", err.response);
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("General error:", err.message);
      }
    }

    // Reset message and files after sending
    //setMessage("");
    setFiles([]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };
  const MAX_FILE_SIZE_MB = 5;
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const errorFiles = [];

    selectedFiles.forEach((file) => {
      if (file.size / 1024 / 1024 <= MAX_FILE_SIZE_MB) {
        validFiles.push(file);
      } else {
        errorFiles.push(file);
      }
    });

    if (errorFiles.length > 0) {
      seterror(
        `Some files are larger than ${MAX_FILE_SIZE_MB} MB and were not added.`
      );
    } else {
      seterror("");
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          {showPopError && <ErrorPop message={errorPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">
                      User Send Message ({UserDetail.username})
                    </h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                  {errormessage && <span className="text-success">{msg}</span>}
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={Viewmodalfile}
        className="edit_admin"
        onHide={handleModalClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">View File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              {viewfiles.length > 0 ? (
                viewfiles.map((file, index) => (
                  <div key={index} className="col-md-4">
                    <a
                      href={file}
                      download
                      title="Download/View"
                      target="_blank"
                      className="view_fileimgae"
                    >
                      <img
                        className="w-100 h-100 object-fit-cover"
                        src={getFileSrc(file)} // Use the file URL from the array
                        alt={`file-${index}`} // Unique alt for each image
                      />
                    </a>
                  </div>
                ))
              ) : (
                <div>No files available.</div> // Optional: A message when no files exist
              )}
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalEdit}
        className="edit_admin"
        onHide={handleModalClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Edit Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
            action="javascript:void(0)"
            method="post"
          >
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={currentRecord.message}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalEditfile}
        className="edit_admin"
        onHide={handleModalClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Edit Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmitfile}
            action="javascrit:void(0)"
            method="post"
          >
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Choose File
              </label>

              <input
                type="file"
                required
                className="form-control"
                id="message"
                name="file"
                multiple
                onChange={handleFileChange}
              />
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              {spinner && (
                <div className="spinner-border text-success " role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
