import React, { useState, useEffect } from "react";
import axios from "axios";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
export const Security = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState("");
  const [showModalTwofactor, setshowModalTwofactor] = useState(false);
  const [messages, setmessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [userdata, setuserdata] = useState("");
  const handleModalClose = async () => {
    setshowModalTwofactor(false);
  };
  const apiUrl = "https://backend.amourette.no/api/members/";
  useEffect(() => {
    getUserDetail();
  }, []);
  const getUserDetail = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getUserDetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.row[0];
      console.log(respo);
      setStep(respo.two_fA);
      setuserdata(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      otp: e.target.otp.value,
      id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "checkOTP", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      console.log(respo);
      if (respo.status === "2") {
        setShowPopError(true);
        setPoperrorMessage(respo.message);
        setTimeout(() => {
          setShowPopError("");
        }, 1000);
      } else {
        setShowPopSuccess(true);
        setPopsuccessMessage(respo.message);
        setTimeout(() => {
          setPopsuccessMessage("");
        }, 1500);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleclick = async (vl) => {
    setStep(vl);
    if (vl === "Yes") {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrl + "sendOTP", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data;
        console.log(respo);
        if (respo.status === "2") {
          setShowPopError(true);
          setPoperrorMessage(respo.message);
          setTimeout(() => {
            setShowPopError("");
          }, 1000);
        } else {
          setShowPopSuccess(true);
          setPopsuccessMessage(respo.message);
          setTimeout(() => {
            setPopsuccessMessage("");
          }, 1500);
        }
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
      setshowModalTwofactor(true);
    } else {
      setshowModalTwofactor(false);
    }
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="d-flex flex-column gap-4 mship">
        <h1 className="shiptitle">Security</h1>

        <div className="d-flex flex-column gap-3">
          <div className="blockv d-flex flex-column gap-4">
            <h3>We Offer a level Of additional security to your accounts.</h3>
            <div className="d-flex flex-column gap-3">
              <h4>Security question</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur eget condirnentum dolor. Proin consequati justo eget
                commodo ullamcorper, dui velit effcitur justo, nec hendrerit sem
                est at quam. Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes
                  <input type="radio" name="Security" />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No
                  <input type="radio" name="Security" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>Two-factor authentication</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur eget condirnentum dolor. Proin consequati justo eget
                commodo ullamcorper, dui velit effcitur justo, nec hendrerit sem
                est at quam. Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes
                  <input
                    type="radio"
                    name="factor"
                    value="Yes"
                    checked={step === "Yes"}
                    onChange={() => handleclick("Yes")}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No
                  <input
                    type="radio"
                    name="factor"
                    value="No"
                    checked={step === "No"}
                    onChange={() => handleclick("No")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>Personal data</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur eget condirnentum dolor. Proin consequati justo eget
                commodo ullamcorper, dui velit effcitur justo, nec hendrerit sem
                est at quam. Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="d-block shipbtns">
                <div className="col-md-4">
                  <button type="button" className="btn celbtn">
                    REQUEST AN EXPORT
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>Personal data (Share)</h4>
              <p>
                We value your privacy! Please let us know if you would like to
                share your personal data with us
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes (I agree to share my personal data)
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No (I do not wish to share my personal data.)
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalTwofactor}
        className="edit_admin"
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Enter Otp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            {showPopSuccess && (
              <span className="text-success">{successPopMessage}</span>
            )}
            {showPopError && (
              <span className="text-danger">{errorPopMessage}</span>
            )}
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                OTP
              </label>
              <input
                type="text"
                required
                className="form-control"
                id="username"
                name="otp"
                placeholder="Enter Otp"
              />
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
