import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import { Createnewdate } from "../components/Createnewdate";
import { Editnewdate } from "../components/Editnewdate";
import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { AlertDelete } from "../components/AlertDelete";
export default function Speeddate() {
  var apiUrl = "https://backend.amourette.no/api/profile/";
  const [activeIndex, setActiveIndex] = useState(0);
  const [isallspeedDate, setallspeedDate] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [filters, setFilters] = useState([]);
  const [editDelete, seteditDelete] = useState(false);
  const [deleteMessage, setdeleteMessage] = useState("");
  const [isPopupOpen_delete, setisPopupOpen_delete] = useState(false);
  const [speedId, setspeedId] = useState("");
  const [speedIdedit, setspeedIdedit] = useState("");
  const [speedDateEdit, setspeedDateEdit] = useState("");
  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };
  const openPopup1 = () => {
    setIsPopupOpen1(true);
    document.body.style.overflowY = "hidden";
  };
  const closePopup1 = () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  useEffect(() => {
    getAlldates();
  }, []);
  const getAlldates = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getdates", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;

      setallspeedDate(respo.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const setSearchTerm = async (e) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    setsearchTerm(e);
    let formData = {
      search: e,
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getdatesSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      setallspeedDate(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const refreshpage = async () => {
    getAlldates();
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

      let formData = {
        search: filters,
        user_id: userLogin ? userLogin.id : null, // Handle the case when userLogin is null
      };

      try {
        const res = await axios.post(
          apiUrl + "getdatesSearchfilter",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          }
        );

        // Assuming the response data is structured as `res.data.results`
        const respo = res.data.results;
        console.log("l");
        console.log(respo);

        // Update the state with the fetched data
        setallspeedDate(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response error:", err.response);
        } else if (err.request) {
          console.error("Request error:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };

    // Call the async function inside useEffect
    fetchData();
  }, [filters]);
  const handlesearchSpeeddate = (e) => {
    const value = e.target.value;

    // If the checkbox is checked, add the value to the filters array
    if (e.target.checked) {
      setFilters((prevFilters) => [...prevFilters, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the filters array
      setFilters((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
  };
  const handlebuttontoggal = (index) => {
    seteditDelete((prevIndex) => (prevIndex === index ? null : index));
  };
  const handlebuttonDelete = async (id) => {
    setspeedId(id);
    setisPopupOpen_delete(true);
    setdeleteMessage("Are you sure? You want delete this");
  };
  const closePopup_delete = () => {
    setisPopupOpen_delete(false);
  };
  const handlespeedPostDelete = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      id: speedId,
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "handlepostDelete", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setisPopupOpen_delete(false);
      getAlldates();
    } catch (err) {
      console.error("Error posting comment:", err);
    }
  };

  const refreshpopup = async () => {
    handlespeedPostDelete();
  };
  const handlebuttonEdit = async (id) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      user_id: userLogin.id,
      id: id,
    };
    setspeedIdedit(id);
    try {
      const res = await axios.post(apiUrl + "getdatesedit", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setspeedDateEdit(respo.result);
      openPopup1();
      console.log(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      <Headertwo />
      <AlertDelete
        isOpen1={isPopupOpen_delete}
        onClose1={closePopup_delete}
        message={deleteMessage}
        onReturnSent={refreshpopup}
      ></AlertDelete>
      <section className="d-block searchmain gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("Speeddate")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onInput={(e) => setSearchTerm(e.target.value)}
                      placeholder={t("Search_speeddate")}
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allspeeddate"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 0 ? "" : ""
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("All_dates")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/speeddate"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : "active"
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Your_posted_dates")}</h3>
                    </div>
                  </Link>

                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      {t("Create_new_date")}
                    </button>
                  </div>
                  <hr className="linehr" />
                  <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>{t("Filters")}</h6>
                    <div className="d-flex flex-column gap-2">
                      <div className="listbox d-flex flex-column gap-3">
                        <label className="cbox">
                          {t("Men")}
                          <input
                            type="checkbox"
                            onChange={handlesearchSpeeddate}
                            checked={filters.includes("Male")}
                            name="filter"
                            value="Male"
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Women")}
                          <input
                            type="checkbox"
                            onChange={handlesearchSpeeddate}
                            checked={filters.includes("Female")}
                            name="filter"
                            value="Female"
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Couples")}
                          <input
                            type="checkbox"
                            onChange={handlesearchSpeeddate}
                            checked={filters.includes("Couple")}
                            name="filter"
                            value="Couple"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                {isallspeedDate.length === 0 ? (
                  <div className="col-12 text-center">
                    <h2>{t("No_date_found")}</h2>
                  </div>
                ) : (
                  isallspeedDate.map((item, index) => (
                    <div key={index} className="col-sm-6 col-xl-3">
                      <div className="position-relative search_content">
                        <div className="d-block searchimg h-100 position-relative">
                          <img
                            className="w-100 h-100 object-fit-cover"
                            src={item.image}
                            alt="img"
                          />
                        </div>
                        <div className="d-flex align-items-start gap-2 searchgender_main">
                          <div className="d-block searchwomen flex-shrink-0">
                            {item.profile_type === "Single profile" &&
                            item.gender === "Male" ? (
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/male.png")}
                                alt="Male"
                              />
                            ) : item.profile_type === "Single profile" &&
                              item.gender === "Female" ? (
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/woman.png")}
                                alt="Female"
                              />
                            ) : item.profile_type === "Couples profile" ? (
                              <>
                                <img
                                  className="w-100 h-100"
                                  src={require("../assets/images/male.png")}
                                  alt="Male"
                                />
                                <img
                                  className="w-100 h-100"
                                  src={require("../assets/images/woman.png")}
                                  alt="Female"
                                />
                              </>
                            ) : null}{" "}
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/male.png")}
                              alt="Male"
                            />
                          </div>
                          <div className="d-flex flex-column gap-1 ">
                            <button
                              title="Delete"
                              type="button"
                              onClick={() => handlebuttontoggal(index)}
                              className="btn redbtn "
                              style={{
                                top: "10px", // Adjust this value to control vertical placement
                                right: "10px", // Adjust this value to control horizontal placement
                                padding: "0px",
                                textAlign: "center",
                                minWidth: "0px",
                                color: "white",
                                zIndex: "10", // Ensures it stays above the image
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-dots-vertical"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                              </svg>
                            </button>
                            {editDelete === index && (
                              <div className="editDeleteVisible d-flex flex-column gap-1 ">
                                <>
                                  <button
                                    title="Edit"
                                    type="button"
                                    onClick={() => handlebuttonEdit(item.id)}
                                    className="btn redbtn"
                                    style={{
                                      top: "10px", // Adjust this value to control vertical placement
                                      right: "10px", // Adjust this value to control horizontal placement
                                      padding: "0px",
                                      textAlign: "center",
                                      minWidth: "0px",
                                      zIndex: "10", // Ensures it stays above the image
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                      <path d="M13.5 6.5l4 4" />
                                    </svg>
                                  </button>
                                  <button
                                    title="Delete"
                                    type="button"
                                    className="btn redbtn"
                                    onClick={() => handlebuttonDelete(item.id)}
                                    style={{
                                      top: "10px", // Adjust this value to control vertical placement
                                      right: "10px", // Adjust this value to control horizontal placement
                                      padding: "0px",
                                      textAlign: "center",
                                      minWidth: "0px",

                                      zIndex: "10", // Ensures it stays above the image
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon icon-tabler icons-tabler-outline icon-tabler-trash"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M4 7l16 0" />
                                      <path d="M10 11l0 6" />
                                      <path d="M14 11l0 6" />
                                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                  </button>
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-between px-3 searchusere">
                          <div className="d-flex flex-column gap-0">
                            <h4>{item.username}</h4>{" "}
                            <p>{calculateAge(item.birthday_date)}</p>
                            <div className="d-flex align-items-center gap-1">
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Createnewdate
        isOpen={isPopupOpen}
        onClose={closePopup}
        refreshpage={refreshpage}
      ></Createnewdate>

      <Editnewdate
        isOpen={isPopupOpen1}
        onClose={closePopup1}
        speedDateEdit={speedDateEdit}
        refreshpage={refreshpage}
        speedIdedit={speedIdedit}
      ></Editnewdate>
      <Footer />
    </>
  );
}
