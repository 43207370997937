import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faCalendarAlt,
  faUserPlus,
  faComment,
  faStar,
  faThumbsUp,
  faPenToSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path

export default function UsersEventsPost() {
  const { slug } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [alertpopupcomment, setalertpopupcomment] = useState(false);
  const [messageshow, setmessageshow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    name: "",
    id: "",
    start_date: "",
    end_date: "",
    time: "",
    location: "",
    description: "",
    image: null,
  });
  const [postLikes, setpostLikes] = useState([]);
  const [showModalLike, setShowModalLike] = useState(false);
  const [messageshowup, setmessageshowup] = useState(false);
  const [editcommentId, seteditcommentId] = useState("");
  const [editcommentdesc, seteditcommentdesc] = useState("");
  const [showModalCommentEdit, setShowModalCommentEdit] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [eventpostid, seteventpostid] = useState("");
  const [eventpostComments, seteventpostComments] = useState([]);
  const [showModalComment, setShowModalComment] = useState(false);
  const [alertpopup, setalertpopup] = useState(false);
  const [eventIdToDelete, seteventIdToDelete] = useState("");
  const [UserEventDetail, setUserEventDetail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [records, setRecords] = useState([]);
  const [UserDetail, setUserDetail] = useState("");
  const [messageset, setmessageset] = useState("");
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const apiURL = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    getusergroupdetail();
  }, []);
  const getusergroupdetail = async () => {
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getusergroupdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserEventDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    if (UserDetail !== "") {
      // const intervalId = setInterval(() => {
      //   getusergrouppostlist(); // Call your function
      // }, 500);

      // Clear the interval when the component unmounts or UserDetail changes
      //return () => clearInterval(intervalId);
      getusergrouppostlist();
    }
  }, [UserDetail]);
  const getusergrouppostlist = async () => {
    let formData = {
      user_id: UserDetail.id,
      group_id: id,
    };
    //console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getusergrouppostlist", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Total Comments",
      selector: (row) => row.total_comments,
      sortable: true,
    },
    ,
    {
      name: "Total Likes",
      selector: (row) => row.total_likes,
      sortable: true,
      className: "age",
    },
    {
      name: "time",
      selector: (row) => formateTime(row.date),
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            href="javascript:void(0)"
            className="dataedit_btn text-primary"
            title="View/Edit Comments"
            onClick={() => handleComment(row.id)}
          >
            <FontAwesomeIcon icon={faComment} />
          </a>
          <a
            onClick={() => handleLike(row)}
            href="javascript:void(0)"
            className="dataedit_btn text-primary"
            title="View Likes"
          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </a>

          <a
            href="#"
            onClick={() => handledeleteevent(row)}
            className="dataedit_btn text-danger"
            title="Delete Group Post"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const handleLike = async (row) => {
    setShowModalLike(true);
    let formData = {
      id: row.id,
    };
    try {
      const res = await axios.post(apiUrl + "getallgrouppostLikes", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      setpostLikes(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleComment = async (id) => {
    setShowModalComment(true);
    let formData = {
      id: id,
    };
    seteventpostid(id);
    try {
      const res = await axios.post(apiUrl + "getallgroupComments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      seteventpostComments(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handledeleteevent = async (row) => {
    seteventIdToDelete(row.id);
    setalertpopup(true); // Close the popup
    setmessageset("Are you sure? You want to delete this post");
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: eventIdToDelete,
      group_id: UserEventDetail.id,
    };
    try {
      const res = await axios.post(apiUrl + "deletegroupspost", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getusergrouppostlist();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  //Location
  const handleModalCloseComment = () => {
    setShowModalComment(false);
    setShowModalCommentEdit(false);
    setShowModalLike(false);
  };
  const handledeletecomment = async (idd) => {
    setalertpopupcomment(true);
    setdeleteid(idd);
    setShowModalComment(false);
    setmessageset("Are you sure? You want to delete this comments");
  };
  const setalertpopups = async () => {
    setalertpopupcomment(false);
    setShowModalComment(true);
  };
  const onReturnSentComment = async () => {
    setmessageshow(true);
    let formData = {
      id: deleteid,
      group_id: id,
    };
    setalertpopupcomment(false);
    setShowModalComment(true);
    try {
      const res = await axios.post(apiUrl + "deletegroupcomment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      handleComment(eventpostid);
      getusergrouppostlist();
      setTimeout(() => {
        setmessageshow(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const handlecommentedit = async (comm) => {
    setShowModalComment(false);
    seteditcommentId(comm.id);
    seteditcommentdesc(comm.description);
    setShowModalCommentEdit(true);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    var desc = e.target.name.value;
    let formData = {
      id: editcommentId,
      group_id: id,
      description: desc,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "editgroupcomment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setmessageshowup(true);
      handleComment(eventpostid);
      setTimeout(() => {
        setmessageshowup(false);
        setShowModalComment(true);
        setShowModalCommentEdit(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };

  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {alertpopupcomment && (
            <AlertDelete
              isOpen1={alertpopupcomment}
              onClose1={() => setalertpopups(false)}
              message={messageset}
              onReturnSent={onReturnSentComment}
            />
          )}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form action="javascript:void(0)" method="post">
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <Link
                      to={`/admin/usergroups/${slug}/`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                      className="mb-4"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      Back
                    </Link>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between gap-2">
                        <h6 className="mb-4">
                          User Name ({UserDetail.username})
                        </h6>
                        <h6 className="mb-4">
                          Group Post List(
                          {UserEventDetail.name})
                        </h6>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Start of row */}
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredRecords}
                        pagination
                        highlightOnHover
                        noDataComponent="No records found."
                      />
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalComment}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ overflowY: "scroll", height: "500px" }}>
            {messageshow && (
              <span className="text-success">Successfully deleted</span>
            )}
            <ul className="comment_userlist scroll_bar">
              {eventpostComments.length === 0 ? (
                <li>No comments found</li>
              ) : (
                eventpostComments.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4>{com.username}</h4>
                          <h5 style={{ fontSize: "12px" }}>
                            {formateTime(com.date)}
                          </h5>
                        </div>
                        <p className="scroll_bar">{com.description}</p>
                      </div>
                      <div className="d-flex align-items-end ml-auto gap-2">
                        <a
                          href="#"
                          title="Edit Comment"
                          onClick={() => handlecommentedit(com)}
                          className="text-primary"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </a>
                        <a
                          href="#"
                          title="Delete Comment"
                          onClick={() => handledeletecomment(com.id)}
                          className="text-danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalCommentEdit}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">Edit Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            action="javascript:void(0)"
            method="post"
            onSubmit={handleUpdate}
          >
            {messageshowup && (
              <span className="text-success">Successfully updated</span>
            )}
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Comment <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                required
                className="form-control"
                defaultValue={editcommentdesc}
              />
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalLike}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Likes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ overflowY: "scroll", height: "500px" }}>
            <ul className="comment_userlist scroll_bar">
              {postLikes.length === 0 ? (
                <li>No likes found</li>
              ) : (
                postLikes.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <h4>{com.username}</h4>
                          <h5>{formateTime(com.date)}</h5>
                        </div>
                        <p className="scroll_bar">
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
