import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function NavAdmin({ onClick }) {
  var apiUrl2 = "https://backend.amourette.no/api/profile/";
  var apiUrl3 = "https://backend.amourette.no/api/members/";

  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleopenhide = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleLogout = () => {
    localStorage.removeItem("adminLogin");
    window.location.href = "/admin/login";
  };
  return (
    <>
      <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
        {/* <a href="index.html" className="navbar-brand d-flex d-lg-none me-4">
          <h2 className="text-primary mb-0">
            <i className="fa fa-hashtag"></i>
          </h2>
        </a> */}
        <a href="#" className="sidebar-toggler flex-shrink-0" onClick={onClick}>
          <i className="fa fa-bars"></i>
        </a>

        <div className="navbar-nav align-items-center ms-auto">
          <div className="nav-item dropdown">
            <a
              href="#"
              onClick={handleopenhide}
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                className="rounded-circle me-lg-2"
                src="https://amouretteno.s3.us-east-1.amazonaws.com/chats/1736337388784_user.jpg"
                alt=""
                style={{ width: "40px", height: "40px" }}
              />
              <span className="d-none d-lg-inline-flex">Admin</span>
            </a>
            <div
              className={`dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0 ${
                isOpen ? "show" : ""
              }`}
            >
              <Link to="#" onClick={handleLogout} className="dropdown-item">
                Log Out
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavAdmin;
