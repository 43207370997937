import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";

export default function Sitespeed() {
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch Site Speed Data (Example with Google PageSpeed Insights API)
  const fetchSiteSpeedData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://www.googleapis.com/pagespeedonline/v5/runPagespeed", // Google API URL
        {
          params: {
            url: "https://amourette.no/", // The site to analyze
            key: "YOUR_API_KEY", // Replace with your Google API Key
            strategy: "desktop", // Choose between 'desktop' or 'mobile'
          },
        }
      );
      const data = response.data;
      const metrics = [
        {
          metric: "First Contentful Paint",
          value:
            data.lighthouseResult.audits["first-contentful-paint"].displayValue,
        },
        {
          metric: "Speed Index",
          value: data.lighthouseResult.audits["speed-index"].displayValue,
        },
        {
          metric: "Largest Contentful Paint",
          value:
            data.lighthouseResult.audits["largest-contentful-paint"]
              .displayValue,
        },
        {
          metric: "Cumulative Layout Shift",
          value:
            data.lighthouseResult.audits["cumulative-layout-shift"]
              .displayValue,
        },
        {
          metric: "Time to Interactive",
          value: data.lighthouseResult.audits["interactive"].displayValue,
        },
      ];
      setSiteData(metrics);
    } catch (error) {
      console.error("Error fetching site speed data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteSpeedData();
  }, []);
  useEffect(() => {
    const getcheckSiteSpeed = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getcheckSiteSpeed", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getcheckSiteSpeed();
  }, []);
  // Define DataTable Columns
  const columns = [
    {
      name: "Metric",
      selector: (row) => row.metric,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      sortable: true,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Site Speed & Performance</h6>
                  </div>

                  {/* Data Table */}
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    <DataTable
                      title="Site Performance Metrics"
                      columns={columns}
                      data={siteData}
                      pagination
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
