import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";

import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import ev3 from "../assets/images/ev3.png";
import groupphoto from "../assets/images/pic.png";
import user from "../assets/images/pic.png";

import Headertwo from "../components/Headertwo";

import { Createnewgroup } from "../components/Createnewgroup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Groups() {
  var apiUrl = "https://backend.amourette.no/api/groups/";
  var apiUrl2 = "https://backend.amourette.no/api/members/";
  // State to track the active button
  const [AllfriendId, setAllfriendId] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button
  const [LoginData, setLoginData] = useState("");
  const [groupData, setgroupData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [populargroupData, setpopulargroupData] = useState([]);
  const handleButtonClick = async (index) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    setActiveIndex(index); // Set the clicked button as active
    if (index === 2) {
      const userIdsWithSelf = [...AllfriendId, userLogin.id];
      let formData = { user_id: userIdsWithSelf };

      try {
        const res = await axios.post(apiUrl + "getgroupdiscover", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        setgroupData(res.data.results);
        console.log("Response data:", res.data.results);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response data:", err.response.data);
          console.error("Response status:", err.response.status);
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    } else {
      getUsergroups("DESC");
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };

  const [isAscending, setIsAscending] = useState(true);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    setLoginData(userLogin);
    getmostpopularGroups();
  }, []);
  useEffect(() => {
    if (LoginData !== undefined) {
      getUsergroups("DESC");
    }
  }, [LoginData]);
  const handleSortToggle = () => {
    setIsAscending((prevState) => !prevState);
    if (isAscending === true) {
      getUsergroups("ASC");
    } else {
      getUsergroups("DESC");
    }
    console.log(isAscending);
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    getAllfriends();
  }, []);
  const getAllfriends = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      const friendUserIds = respo.map((friend) => friend.id);
      console.log(friendUserIds);
      console.log("fg");
      setAllfriendId(friendUserIds);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getmostpopularGroups = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getmostpopularGroups", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      setpopulargroupData(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getUsergroups = async (order) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      orderBy: order,
    };
    try {
      const res = await axios.post(apiUrl + "getgroup", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setgroupData(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleChange = async (event) => {
    setSearchTerm(event.target.value);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      search: event.target.value,
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getgroupSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setgroupData(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  }
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  // Example usage

  return (
    <>
      <Headertwo />
      <section className="alleventsbox d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("RightMenu_Groups")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleChange}
                      placeholder={t("Search_Group")}
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allgroup"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 0 ? "" : ""
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("All_Groups")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/groups"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : "active"
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Your_Groups")}</h3>
                    </div>
                  </Link>
                  <button
                    type="button"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(2)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev3} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Discover_new")}</h3>
                    </div>
                  </button>
                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      {t("Create_new_group")}
                    </button>
                  </div>
                  <hr className="linehr" />
                  <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>{t("Most_popular_group")}</h6>
                    <div className="d-flex flex-column gap-2 groupmy grouplistleft scroll_bar">
                      {populargroupData.length === 0 ? (
                        <h4>{t("LeftMenu_Nogroupfounds")}</h4>
                      ) : (
                        populargroupData.map((group, index) => (
                          <Link
                            key={index} // Use a unique key for each item
                            to={`/group/${group.slug}`} // Assuming you want to navigate to a specific group's page
                            className="d-flex gap-3 align-items-center listoficons"
                          >
                            <div className="flex-shrink-0">
                              <div className="userpic ofit rounded-3 overflow-hidden">
                                <img src={group.image} alt={group.name} />{" "}
                                {/* Assuming each group has an image */}
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h4>{group.name}</h4>{" "}
                              {/* Displaying group name */}
                              <p>{group.description}</p>{" "}
                              {/* Displaying group description */}
                            </div>
                          </Link>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={handleSortToggle}
                      className="sortbtn"
                    >
                      Sort by{" "}
                      {isAscending ? <FaSortAmountUp /> : <FaSortAmountDown />}
                    </button>
                  </div>
                </div>
                {groupData.length === 0 ? (
                  <div className="col-12 text-center">
                    <h2>{t("LeftMenu_Nogroupfounds")}</h2>
                  </div>
                ) : (
                  groupData.map((group, index) => (
                    <div className="col-md-4" key={index}>
                      <Link
                        to={`/group/${group.slug}`}
                        className="groupblck d-flex gap-2"
                      >
                        <div className="flex-grow-1 ps-3 py-2">
                          <div className="d-flex flex-column h-100 gap-1">
                            <h2>{group.name}</h2>
                            <h3>{formatDate(group.date)}</h3>
                            <h4>
                              {group.total_members} {t("LeftMenu_Members")}
                            </h4>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="grpic ofit">
                            {group.image.match(
                              /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                            ) ? (
                              <img src={group.image} alt="img" />
                            ) : group.image.match(/\.(mp4|mov|avi)$/i) ? (
                              <video controls alt="video">
                                <source src={group.image} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Createnewgroup
        isOpen={isPopupOpen}
        onClose={closePopup}
        LoginData={LoginData}
      ></Createnewgroup>
    </>
  );
}
export default Groups;
