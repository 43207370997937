import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Routes

import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Editprofile from "./pages/Editprofile";
import Membership from "./pages/Membership";
import Search from "./pages/Search";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import AllEvents from "./pages/AllEvents";
import Singleevent from "./pages/Singleevent";
import SingleSearch from "./pages/SingleSearch";
import Logout from "./pages/Logout";
import AllFriends from "./pages/AllFriends";
import Friendsearch from "./pages/Friendsearch";
import FriendUser from "./pages/FriendUser";
import Gallery from "./pages/Gallery";
import Allgallery from "./pages/Allgallery";
import Groups from "./pages/Groups";
import AllGroup from "./pages/AllGroup";
import Singlegroup from "./pages/Singlegroup";
import ReceivedMessages from "./pages/ReceivedMessages";
import SendMessages from "./pages/SendMessages";
import ReceivedMessagesChat from "./pages/ReceivedMessagesChat";
import AllSpeeddate from "./pages/AllSpeeddate";
import Speeddate from "./pages/Speeddate";
import AllForums from "./pages/AllForums";
import SingleForums from "./pages/SingleForums";
import Forums from "./pages/Forums";
import Setting from "./pages/Setting";
import Helpsupport from "./pages/Helpsupport";
import Payment from "./pages/Payment";
import Groupimages from "./pages/Groupimages";

//Admin

import AdminLogin from "./pages/Admin/Login";
import AdminUsers from "./pages/Admin/Users";

import ContentManagementAdmin from "./pages/Admin/ContentManagement";
import PaymentSubscriptionAdmin from "./pages/Admin/PaymentSubscription";
import SecurityPrivacyAdmin from "./pages/Admin/SecurityPrivacy";
import Media from "./pages/Admin/Media";
import Group from "./pages/Admin/Groups";

import AdminForum from "./pages/Admin/Forum";
import Messaging from "./pages/Admin/Messaging";
import Timeline from "./pages/Timeline";
import AdminDashboard from "./pages/Admin/Dashboard";
import ReportViolation from "./pages/Admin/ReportViolation";
import AdminUsersProfile from "./pages/Admin/UsersProfile";
import AdminUsersDetail from "./pages/Admin/UsersDetail";
import Sitespeed from "./pages/Admin/Sitespeed";
import AdminPrivacyUpdate from "./pages/Admin/Privacyupdate";
import AdminUserfriendList from "./pages/Admin/UsersFriendlist";
import AdminUsersEvents from "./pages/Admin/UsersEvents";
import AdminUsersGroups from "./pages/Admin/UsersGroups";
import AdminViewgroupPost from "./pages/Admin/ViewgroupPost";
import AdminUsersSentmessage from "./pages/Admin/UsersSentmessage";
import AdminRole from "./pages/Admin/AdminRole";
import AdminLocationbaseusers from "./pages/Admin/Locationbaseusers";
import AdminSendNotification from "./pages/Admin/SendNotification";
import AdminUserEventsPost from "./pages/Admin/UsersEventsPost";
import AdminUserGroupsPost from "./pages/Admin/UsersGroupsPost";
import AdminUserGallery from "./pages/Admin/UsersGallery";
import AdminUserForum from "./pages/Admin/UsersForum";
import AdminLogsActivity from "./pages/Admin/LogsActivity";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/editprofile" element={<Editprofile />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/search" element={<Search />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/events" element={<Events />} />
          <Route path="/allevents" element={<AllEvents />} />
          <Route path="/singleevent/:slug" element={<Singleevent />} />
          <Route path="/SingleSearch/:id" element={<SingleSearch />} />
          <Route path="/friends" element={<AllFriends />} />
          <Route path="/friendsearch" element={<Friendsearch />} />
          <Route path="/friend/:id" element={<FriendUser />} />
          <Route path="/gallery/" element={<Gallery />} />
          <Route path="/allgallery/" element={<Allgallery />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/allgroup" element={<AllGroup />} />
          <Route path="/group/:slug" element={<Singlegroup />} />
          <Route path="/receivedmessages" element={<ReceivedMessages />} />
          <Route path="/sendmessages" element={<SendMessages />} />
          <Route
            path="/receivedchatmessage/:slug"
            element={<ReceivedMessagesChat />}
          />
          <Route path="/allspeeddate" element={<AllSpeeddate />} />
          <Route path="/speeddate" element={<Speeddate />} />
          <Route path="/allforums" element={<AllForums />} />
          <Route path="/forums" element={<Forums />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/singleforums/:slug" element={<SingleForums />} />
          <Route path="/helpsupport" element={<Helpsupport />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/media/:slug" element={<Groupimages />} />

          <Route path="/admin/users" element={<AdminUsers />} />
          <Route
            path="/admin/contentmanagement"
            element={<ContentManagementAdmin />}
          />
          <Route
            path="/admin/paymenthistory"
            element={<PaymentSubscriptionAdmin />}
          />
          <Route
            path="/admin/securityprivacy"
            element={<SecurityPrivacyAdmin />}
          />
          <Route path="/admin/media" element={<Media />} />
          <Route path="/admin/groups" element={<Group />} />
          <Route path="/admin/forum" element={<AdminForum />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/messaging" element={<Messaging />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/reportviolation" element={<ReportViolation />} />

          <Route
            path="/admin/usersdetails/:slug"
            element={<AdminUsersDetail />}
          />
          <Route
            path="/admin/usersprofile/:slug"
            element={<AdminUsersProfile />}
          />
          <Route path="/admin/sitespeed" element={<Sitespeed />} />
          <Route path="/admin/privacyupdate" element={<AdminPrivacyUpdate />} />
          <Route
            path="/admin/userfriendlist/:slug"
            element={<AdminUserfriendList />}
          />
          <Route
            path="/admin/userevents/:slug"
            element={<AdminUsersEvents />}
          />
          <Route
            path="/admin/usergroups/:slug"
            element={<AdminUsersGroups />}
          />
          <Route
            path="/admin/groups/viewgrouppost/:slug"
            element={<AdminViewgroupPost />}
          />
          <Route
            path="/admin/usersentmessage/:slug"
            element={<AdminUsersSentmessage />}
          />
          <Route path="/admin/adminlist" element={<AdminRole />} />
          <Route
            path="/admin/locationbaseuser"
            element={<AdminLocationbaseusers />}
          />
          <Route
            path="/admin/sendnotification"
            element={<AdminSendNotification />}
          />
          <Route
            path="/admin/userevents/:slug/:id"
            element={<AdminUserEventsPost />}
          />
          <Route
            path="/admin/usergroups/:slug/:id"
            element={<AdminUserGroupsPost />}
          />
          <Route
            path="/admin/usergallery/:slug"
            element={<AdminUserGallery />}
          />
          <Route path="/admin/userforum/:slug" element={<AdminUserForum />} />
          <Route path="/admin/logsactivity" element={<AdminLogsActivity />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
