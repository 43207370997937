import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faCalendarAlt,
  faUserPlus,
  faComment,
  faThumbsUp,
  faStar,
  faPenToSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path

export default function UsersForum() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [messageupdate, setmessageupdate] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalLike, setShowModalLike] = useState(false);
  const [postLikes, setpostLikes] = useState([]);
  const [alertpopup, setalertpopup] = useState(false);
  const [galleryIDDelete, setgalleryIDDelete] = useState("");
  const [editcommentId, seteditcommentId] = useState("");
  const [editcommentdesc, seteditcommentdesc] = useState("");
  const [alertpopupcomment, setalertpopupcomment] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [category, setCategory] = useState(""); // State for category
  const [currentRecord, setCurrentRecord] = useState({
    name: "",
    id: "",
    start_date: "",
    end_date: "",
    time: "",
    location: "",
    description: "",
    image: null,
  });
  const [messageset, setmessageset] = useState("");
  const [messageshowup, setmessageshowup] = useState(false);
  const [showModalCommentEdit, setShowModalCommentEdit] = useState(false);
  const [gallerypostComments, setgallerypostComments] = useState([]);
  const [messageshow, setmessageshow] = useState(false);
  const [showModalComment, setShowModalComment] = useState("");
  const [galleryid, setgalleryid] = useState("");
  const [records, setRecords] = useState([]);
  const [UserDetail, setUserDetail] = useState("");
  const [spinner, setspinner] = useState(false);
  const [UserGallery, setUserGallery] = useState("");
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getuserdetail();
  }, []);
  const getuserdetail = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getuserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      if (respo === undefined) {
        navigate("/admin/users");
      }

      setUserDetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    getuserforum();
  }, [UserDetail]);

  const getuserforum = async () => {
    if (UserDetail !== null && UserDetail !== undefined) {
      let formData = {
        user_id: UserDetail.id,
      };

      try {
        const res = await axios.post(apiUrl + "getuserforum", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.results;
        if (respo === undefined) {
          navigate("/admin/users");
        }

        setRecords(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Total Comments",
      selector: (row) => row.total_comments,
      sortable: true,
    },

    {
      name: "Date",
      selector: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            href="javascript:void(0)"
            className="dataedit_btn text-primary"
            title="View/Edit Comments"
            onClick={() => handleComment(row.id)}
          >
            <FontAwesomeIcon icon={faComment} />
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => handleeditevent(row)}
            className="dataedit_btn text-primary"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </a>

          <a
            href="#"
            onClick={() => handledeleteforum(row)}
            className="dataedit_btn text-danger"
            title="Delete Event"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleeditevent = async (row) => {
    getuserdetail();
    setShowModalEdit(true);
    setCurrentRecord(row);
    setCategory(row.category);
    setTimeout(() => {}, 100);
  };
  const handledeleteforum = async (row) => {
    setgalleryIDDelete(row.id);
    setalertpopup(true); // Close the popup
    setmessageset("Are you sure? You want to delete this forum");
  };
  const handleComment = async (id) => {
    setShowModalComment(true);
    let formData = {
      id: id,
    };
    setgalleryid(id);
    try {
      const res = await axios.post(apiUrl + "getforumcomments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.results;
      setgallerypostComments(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  //Location
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };

  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handlecommentedit = async (comm) => {
    setShowModalComment(false);
    seteditcommentId(comm.id);
    seteditcommentdesc(comm.description);
    setShowModalCommentEdit(true);
  };
  const handledeletecomment = async (idd) => {
    setalertpopupcomment(true);
    setdeleteid(idd);
    setShowModalComment(false);
    setmessageset("Are you sure? You want to delete this comments");
  };
  const handleModalCloseComment = () => {
    setShowModalComment(false);
    setShowModalCommentEdit(false);
    setShowModalLike(false);
  };
  const setalertpopups = async () => {
    setalertpopupcomment(false);
    setShowModalComment(true);
  };
  const onReturnSentComment = async () => {
    setmessageshow(true);
    let formData = {
      id: deleteid,
      user_id: UserDetail.id,
      gallery_id: galleryid,
    };
    setalertpopupcomment(false);
    setShowModalComment(true);
    try {
      const res = await axios.post(apiUrl + "deleteforumcomment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      handleComment(galleryid);

      setTimeout(() => {
        setmessageshow(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    var desc = e.target.name.value;
    let formData = {
      id: editcommentId,
      gallery_id: galleryid,
      user_id: UserDetail.id,
      description: desc,
    };
    try {
      const res = await axios.post(apiUrl + "editforumcomment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setmessageshowup(true);
      handleComment(galleryid);
      setTimeout(() => {
        setmessageshowup(false);
        setShowModalComment(true);
        setShowModalCommentEdit(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: galleryIDDelete,
      user_id: UserDetail.id,
    };
    try {
      const res = await axios.post(apiUrl + "deleteforum", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getuserforum();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
    }
  };
  const handleModalCloseJoin = () => {
    setShowModalEdit(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("name", currentRecord.name);
      formData.append("description", currentRecord.description);
      formData.append("category", category);
      formData.append("fileType", "forum");
      formData.append("image", selectedFile);
      formData.append("id", currentRecord.id);
      console.log(currentRecord);
      console.log(category);
      console.log(selectedFile);
      const response = await axios.post(apiUrl + "adminforumUpdate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setspinner(false);
      setmessageupdate(true);
      getuserforum();
      setTimeout(() => {
        setmessageupdate(false);
        setShowModalEdit(false);
      }, 1000);

      // Close the popup after submission
    } catch (error) {}
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value); // Update category state
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {alertpopupcomment && (
            <AlertDelete
              isOpen1={alertpopupcomment}
              onClose1={() => setalertpopups(false)}
              message={messageset}
              onReturnSent={onReturnSentComment}
            />
          )}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form action="javascript:void(0)" method="post">
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <Link
                      to="/admin/users"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                      className="mb-4"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ marginRight: "8px" }}
                      />{" "}
                      Back
                    </Link>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-between">
                        <h6 className="mb-4">
                          Forum List ({UserDetail.username})
                        </h6>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Start of row */}
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={filteredRecords}
                        pagination
                        highlightOnHover
                        noDataComponent="No records found."
                      />
                    </div>
                    {/* End of row */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalComment}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ overflowY: "scroll", height: "500px" }}>
            {messageshow && (
              <span className="text-success">Successfully deleted</span>
            )}
            <ul className="comment_userlist scroll_bar">
              {gallerypostComments.length === 0 ? (
                <li>No comments found</li>
              ) : (
                gallerypostComments.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4>{com.username}</h4>
                          <h5 style={{ fontSize: "12px" }}>
                            {formateTime(com.date)}
                          </h5>
                        </div>
                        <p className="scroll_bar">{com.description}</p>
                      </div>
                      <div className="d-flex align-items-end ml-auto gap-2">
                        <a
                          href="#"
                          title="Edit Comment"
                          onClick={() => handlecommentedit(com)}
                          className="text-primary"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </a>
                        <a
                          href="#"
                          title="Delete Comment"
                          onClick={() => handledeletecomment(com.id)}
                          className="text-danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalCommentEdit}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">Edit Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            action="javascript:void(0)"
            method="post"
            onSubmit={handleUpdate}
          >
            {messageshowup && (
              <span className="text-success">Successfully updated</span>
            )}
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Comment <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                required
                className="form-control"
                defaultValue={editcommentdesc}
              />
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalLike}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Likes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ overflowY: "scroll", height: "500px" }}>
            <ul className="comment_userlist scroll_bar">
              {postLikes.length === 0 ? (
                <li>No likes found</li>
              ) : (
                postLikes.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <h4>{com.username}</h4>
                          <h5>{formateTime(com.date)}</h5>
                        </div>
                        <p className="scroll_bar">
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalEdit}
        className="edit_admin"
        onHide={handleModalCloseJoin}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">
            Edit Forum ({currentRecord.name})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {messageupdate && (
            <span className="text-success mb-4">Updated successfully</span>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="uploadfile rel bgload">
                <input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  className="fileload"
                />
                <input type="hidden" name="id" value={currentRecord.id} />
                <div className="upload_image">
                  {selectedFile ? (
                    <img
                      className="w-100 h-100"
                      src={URL.createObjectURL(selectedFile)}
                      alt="Selected"
                    />
                  ) : (
                    currentRecord.image && (
                      <img
                        className="w-100 h-100"
                        src={currentRecord.image} // Use the existing image URL from the row
                        alt="Current"
                      />
                    )
                  )}
                </div>
                <div className="inload" style={{ height: "100px" }}>
                  <div>
                    <BsCloudUpload style={{ fontSize: "24px" }} />
                    <h3 className="text-dark" style={{ fontSize: "14px" }}>
                      Upload image
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentRecord.name || ""} // Ensure it works if the value is initially undefined
                onChange={handleInputChange}
              />
            </div>
            <div className="col-12">
              <select
                name="catgeory"
                id=""
                value={category}
                onChange={handleCategoryChange}
              >
                <option value="">--Select--</option>
                <option value="Drinks">Drinks</option>
                <option value="Fetish">Fetish</option>
                <option value="Meetups">Meetups</option>
                <option value="Love">Love</option>
                <option value="Party">Party</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                name="description"
                className="form-control"
                value={currentRecord.description || ""} // Bind value
                onChange={handleInputChange}
              ></textarea>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseJoin}>
                Close
              </Button>

              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Update
              </Button>
              {spinner && (
                <div
                  className="spinner-border text-success date-load"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
