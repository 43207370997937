import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

import Select from "react-select";
import Headertwo from "../components/Headertwo";
import { Uploadprivatealbum } from "../components/Uploadprivatealbum";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { FiPlus } from "react-icons/fi";
import { BsCloudUpload } from "react-icons/bs";

import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";

import PrivateImageRequest from "../components/PrivateImageRequest";

import de1 from "../assets/images/de1.png";
import de2 from "../assets/images/de2.png";
import de3 from "../assets/images/de3.png";
import de4 from "../assets/images/de4.png";
import de5 from "../assets/images/de5.png";
import de6 from "../assets/images/de6.png";
import de7 from "../assets/images/de7.png";
import de8 from "../assets/images/de8.png";
import de9 from "../assets/images/de9.png";
import de10 from "../assets/images/de10.png";
import de11 from "../assets/images/de11.png";
import de12 from "../assets/images/de12.png";
import de13 from "../assets/images/de13.png";
import de14 from "../assets/images/connected_with.png";
import de15 from "../assets/images/gender.png";
import de16 from "../assets/images/intersted_in.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Editprofile() {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isImagePrivate, setIsImagePrivate] = useState(false);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [LoginData, setLoginData] = useState(null);
  const [profilevalue, setprofilevalue] = useState("");
  const [genders, setgenders] = useState("");
  const [Singel, setSingel] = useState("");
  const [InterstedIn, setInterstedIn] = useState("");
  const [nationality, setNationality] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [selectedSubRegion, setSelectedSubRegion] = React.useState("");

  const [sexual_orientation, setsexual_orientation] = useState([]);
  const [relationship_status, setrelationship_status] = useState("");

  const [smokerValue, setsmokerValue] = useState("");
  const [tattosValue, settattosValue] = useState("");

  const [fetishValue, setfetishValue] = useState("");
  const [search_looking_for, setsearch_looking_for] = useState("");
  const [drinkerValue, setdrinkerValue] = useState("");
  const [body_piercingsValue, setbody_piercingsValue] = useState("");
  const [degree, setDegree] = useState("");
  const [heightFeet, setHeightFeet] = useState(profilevalue.height_feet || "");
  const [heightInches, setHeightInches] = useState(
    profilevalue.height_inches || ""
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedFile_1, setSelectedFile_1] = useState(null);
  const [errorMessage_1, setErrorMessage_1] = useState("");

  const [selectedFile_2, setSelectedFile_2] = useState(null);
  const [errorMessage_2, setErrorMessage_2] = useState("");

  const [selectedFile_3, setSelectedFile_3] = useState(null);
  const [errorMessage_3, setErrorMessage_3] = useState("");

  const [selectedFile_4, setSelectedFile_4] = useState(null);
  const [errorMessage_4, setErrorMessage_4] = useState("");

  const [imageUrl, setImageUrl] = useState("");

  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [imageUrl4, setImageUrl4] = useState("");
  const [isMaleChecked, setIsMaleChecked] = useState(false);
  const [maleOptions, setMaleOptions] = useState({
    straight: false,
    "bi-sexual": false,
    "bi-curious": false,
    gay: false,
    bull: false,
  });
  const [isCoupleChecked, setIsCoupleChecked] = useState(false);
  const [coupleOptions, setCoupleOptions] = useState({
    fullswap: false,
    softswap: false,
    cuckold: false,
    exhibitionist: false,
    voyeur: false,
    hotwife: false,
  });
  const [isFemaleChecked, setIsFemaleChecked] = useState(false);
  const [femaleOptions, setFemaleOptions] = useState({
    Straight: false,
    "Bi-sexual": false,
    "Bi-curious": false,
    lesbian: false,
    Hotwife: false,
  });
  const [sexuality, setSexuality] = useState([]);
  var apiURL = "https://backend.amourette.no/api/";
  useEffect(() => {
    const anyOptionChecked = Object.values(coupleOptions).some(
      (isChecked) => isChecked
    );
    setIsCoupleChecked(anyOptionChecked);
  }, [coupleOptions]);
  useEffect(() => {
    const anyOptionChecked = Object.values(femaleOptions).some(
      (isChecked) => isChecked
    );
    setIsFemaleChecked(anyOptionChecked);
  }, [femaleOptions]);
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin == null) {
      navigate("/login");
    } else {
      setLoginData(userLogin);
      // Call getProfile only if userLogin is available
      getprofile(userLogin.email);
    }
  }, []);

  const handleFeetChange = (event) => {
    setHeightFeet(event.target.value);
  };
  const handlefetishChange = (event) => {
    setfetishValue(event.target.value);
  };
  const handlebody_piercingsChange = (event) => {
    setbody_piercingsValue(event.target.value);
  };
  const handledrinkerChange = (event) => {
    setdrinkerValue(event.target.value);
  };
  const handletattosChange = (event) => {
    settattosValue(event.target.value);
  };
  const handlesmokerChange = (event) => {
    setsmokerValue(event.target.value);
  };
  const handleDegreeChange = (event) => {
    setDegree(event.target.value);
  };

  const handleInchesChange = (event) => {
    setHeightInches(event.target.value);
  };
  const handleSingelChange = (event) => {
    setSingel(event.target.value); // Update state with the selected value
  };
  const handleInterstedInChange = (event) => {
    setInterstedIn(event.target.value); // Update state with the selected value
  };
  const handleNationalityChange = (event) => {
    setNationality(event.target.value); // Update state with the selected value
  };
  const handleBodyTypeChange = (event) => {
    setBodyType(event.target.value); // Update state with the selected value
  };

  const handleRelationStatusChange = (event) => {
    setrelationship_status(event.target.value); // Update state with the selected value
  };

  const handleSearchLookingforChange = (event) => {
    setsearch_looking_for(event.target.value); // Update state with the selected value
  };
  const handleCheckChange = (event) => {
    const { checked } = event.target; // Get the checked status of the checkbox
    if (checked === true) {
      console.log(checked);
      setIsImagePrivate(true); // Update the state
    } else {
      setIsImagePrivate(false); // Update the state
    }

    // Append the checked status to formData
  };

  const handleSexualOrientationChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setsexual_orientation((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setsexual_orientation((prev) =>
        prev.filter((orientation) => orientation !== value)
      );
    }
  };

  const handlegendersChange = (event) => {
    setgenders(event.target.value); // Update state with the selected value
    setSexuality([]);
  };
  const maleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Gay", label: "Gay" },
    { value: "Bull", label: "Bull" },
  ];

  const femaleOptions_select = [
    { value: "Straight", label: "Straight" },
    { value: "Bi-sexual", label: "Bi-sexual" },
    { value: "Bi-curious", label: "Bi-curious" },
    { value: "Lesbian", label: "Lesbian" },
    { value: "Hotwife", label: "Hotwife" },
  ];

  const coupleOptions_select = [
    { value: "Full swap", label: "Full swap" },
    { value: "Soft swap", label: "Soft swap" },
    { value: "Cuckold", label: "Cuckold" },
    { value: "Exhibitionist", label: "Exhibitionist" },
    { value: "Voyeur", label: "Voyeur" },
    { value: "Still Exploring", label: "Still Exploring" },
    { value: "HotWife", label: "HotWife" },
  ];
  const setSexualitys = (event) => {
    setSexuality(event);
  };
  const handleSexualityChange = (selectedOptions) => {
    setSexuality(selectedOptions); // Handle the selected multiple options
  };
  const getprofile = async (email) => {
    let formData = {
      email: email,
    };
    try {
      const res = await axios.post(apiURL + "getProfile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res.data);
      console.log("het");
      setprofilevalue(res.data);
      if (res.data.makeImagePrivate === 1) {
        setIsImagePrivate(true);
      } else {
        setIsImagePrivate(false);
      }

      setNationality(res.data.nationality);
      setBodyType(res.data.bodytype);
      setDegree(res.data.degree);
      setdrinkerValue(res.data.drinker);
      setfetishValue(res.data.fetish);
      setbody_piercingsValue(res.data.body_piercings);
      settattosValue(res.data.tattos);
      setsmokerValue(res.data.smoker);
      setsearch_looking_for(res.data.search_looking_for);
      setrelationship_status(res.data.relationship_status);
      setHeightFeet(res.data.height_feet);
      setHeightInches(res.data.height_inches);
      if (
        res.data.sexual_orientation !== null &&
        res.data.sexual_orientation !== ""
      ) {
        const parsedSexualOrientation = JSON.parse(res.data.sexual_orientation);

        // Now, you can update the state with the parsed array
        setsexual_orientation(parsedSexualOrientation);
      }
      setImageUrl(res.data.profile_image);
      setgenders(res.data.gender);
      if (res.data.sexuality !== null && res.data.sexuality !== "") {
        const inputString = JSON.parse(res.data.sexuality);
        const optionss = inputString.map((item) => ({
          value: item,
          label: item,
        }));
        // Now, you can update the state with the parsed array
        setSexuality(optionss);
      }

      setSingel(res.data.connectwith);
      setImageUrl1(res.data.profile_image_1);
      setImageUrl2(res.data.profile_image_2);
      setImageUrl3(res.data.profile_image_3);
      setImageUrl4(res.data.profile_image_4);
      setInterstedIn(res.data.interstedin);
      console.log(res.data);
      if (res.data.location !== "null") {
        setSelectedRegion(res.data.location);
      }

      if (res.data.town !== null && res.data.town !== "") {
        const parsedtown = JSON.parse(res.data.town);

        // Now, you can update the state with the parsed array
        setSelectedTown(parsedtown);
      }
      if (res.data.subregion !== "null") {
        setSelectedSubRegion(res.data.subregion);
      }

      const malee = res.data.male;

      if (typeof malee === "string") {
        try {
          // Attempt to parse the string into an array
          const maleArray = JSON.parse(malee);
          console.log("Male options array:", maleArray); // Log the parsed array

          setMaleOptions((prevState) => {
            const updatedMaleOptions = { ...prevState };
            maleArray.forEach((option) => {
              if (updatedMaleOptions.hasOwnProperty(option)) {
                updatedMaleOptions[option] = true;
              }
            });
            return updatedMaleOptions;
          });
        } catch (error) {
          console.error("Error parsing 'male' field:", error);
        }
      } else {
        console.log("male is not a string, it's:", typeof malee);
      }
      const femalee = res.data.female;
      if (typeof femalee === "string") {
        try {
          // Attempt to parse the string into an array
          const femaleArray = JSON.parse(femalee);
          console.log("Female options array:", femaleArray); // Log the parsed array

          setFemaleOptions((prevState) => {
            const updatedFeMaleOptions = { ...prevState };
            femaleArray.forEach((option) => {
              if (updatedFeMaleOptions.hasOwnProperty(option)) {
                updatedFeMaleOptions[option] = true;
              }
            });
            return updatedFeMaleOptions;
          });
        } catch (error) {
          console.error("Error parsing 'male' field:", error);
        }
      } else {
        console.log("male is not a string, it's:", typeof malee);
      }
      const couplee = res.data.couple;
      if (typeof couplee === "string") {
        try {
          // Attempt to parse the string into an array
          const coupleArray = JSON.parse(couplee);
          console.log("Female options array:", coupleArray); // Log the parsed array

          setCoupleOptions((prevState) => {
            const updatedCoupleOptions = { ...prevState };
            coupleArray.forEach((option) => {
              if (updatedCoupleOptions.hasOwnProperty(option)) {
                updatedCoupleOptions[option] = true;
              }
            });
            return updatedCoupleOptions;
          });
        } catch (error) {
          console.error("Error parsing 'couplee' field:", error);
        }
      } else {
        console.log("couplee is not a string, it's:", typeof couplee);
      }
      console.log(maleOptions);
    } catch (err) {}
  };
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        setImageUrl(""); // Clear the image URL
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        setImageUrl(""); // Clear the image URL
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
      setImageUrl(URL.createObjectURL(file)); // Create a URL for the selected image
    }
  };

  const handleFileChange_1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage_1("Please upload a valid image file.");
        setSelectedFile_1(null);
        setImageUrl1("");
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage_1("File size must be less than 2 MB.");
        setSelectedFile_1(null);
        setImageUrl1("");
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage_1("");
      setSelectedFile_1(file);
      setImageUrl1(URL.createObjectURL(file)); // Create a URL for the selected image
    }
  };

  const handleFileChange_2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage_2("Please upload a valid image file.");
        setSelectedFile_2(null);
        setImageUrl2("");
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage_2("File size must be less than 2 MB.");
        setSelectedFile_2(null);
        setImageUrl2("");
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage_2("");
      setSelectedFile_2(file);
      setImageUrl2(URL.createObjectURL(file));
    }
  };
  const handleFileChange_3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage_3("Please upload a valid image file.");
        setSelectedFile_3(null);
        setImageUrl3("");
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage_3("File size must be less than 2 MB.");
        setSelectedFile_3(null);
        setImageUrl3("");
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage_3("");
      setSelectedFile_3(file);
      setImageUrl3(URL.createObjectURL(file));
    }
  };
  const handleFileChange_4 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage_4("Please upload a valid image file.");
        setSelectedFile_4(null);
        setImageUrl4("");
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage_4("File size must be less than 2 MB.");
        setSelectedFile_4(null);
        setImageUrl4("");
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage_4("");
      setSelectedFile_4(file);
      setImageUrl4(URL.createObjectURL(file));
    }
  };

  const handleFemaleChange = () => {
    const newStatus = !isFemaleChecked;
    setIsFemaleChecked(newStatus);

    // Set all nested options to match the new main checkbox state
    if (newStatus) {
      // If the main checkbox is checked, we set the options to true
      setFemaleOptions({
        Straight: true,
        "Bi-sexual": true,
        "Bi-curious": true,
        lesbian: true,
        Hotwife: true,
      });
    } else {
      // If the main checkbox is unchecked, we set the options to false
      setFemaleOptions({
        straight: false,
        "Bi-sexual": false,
        "Bi-curious": false,
        lesbian: false,
        hotwife: false,
      });
    }
  };

  const handleOptionChangeFemaless = (option) => {
    console.log("df - Triggered for option:", option); // Add the option to identify which one was clicked
    setFemaleOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };
  const handleOption_ChangeMale = (options) => {
    console.log("ht");
    setMaleOptions((prevOptionss) => ({
      ...prevOptionss,
      [options]: !prevOptionss[options],
    }));
  };
  const handleCoupleChange = () => {
    const newStatus = !isCoupleChecked;
    setIsCoupleChecked(newStatus);

    // Update all nested checkboxes based on the "Couple" checkbox
    setCoupleOptions({
      fullswap: newStatus,
      softswap: newStatus,
      cuckold: newStatus,
      exhibitionist: newStatus,
      voyeur: newStatus,
      hotwife: newStatus,
    });
  };

  const handleOptionChangeCouple = (option) => {
    setCoupleOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };
  useEffect(() => {
    const anyOptionChecked = Object.values(maleOptions).some(
      (isChecked) => isChecked
    );
    setIsMaleChecked(anyOptionChecked);
  }, [maleOptions]);

  const handleMaleChange = () => {
    const newStatus = !isMaleChecked;
    setIsMaleChecked(newStatus);

    // Set all nested options to match the new main checkbox state
    if (newStatus) {
      // If the main checkbox is checked, set the options to true
      setMaleOptions({
        straight: true,
        bisexual: true,
        bicurious: true,
        gay: true,
        bull: true,
      });
    } else {
      // If the main checkbox is unchecked, set the options to false
      setMaleOptions({
        straight: false,
        bisexual: false,
        bicurious: false,
        gay: false,
        bull: false,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var vl = event.target;
    const formData = new FormData();

    formData.append(
      "looking_for",
      vl.looking_for.value !== "" ? vl.looking_for.value : ""
    );

    // Append 'username' with a check
    formData.append(
      "username",
      vl.username.value !== "" ? vl.username.value : ""
    );

    // Append other fields with checks
    formData.append("location", selectedRegion !== "" ? selectedRegion : "");
    //formData.append("town", selectedTown !== "" ? selectedTown : "");
    formData.append(
      "preferences",
      vl.preferences.value !== "" ? vl.preferences.value : ""
    );
    formData.append(
      "nationality",
      vl.nationality.value !== "" ? vl.nationality.value : ""
    );
    formData.append(
      "bodytype",
      vl.bodytype.value !== "" ? vl.bodytype.value : ""
    );
    formData.append("height_feet", heightFeet !== "" ? heightFeet : "");
    formData.append("connectwith", Singel !== "" ? Singel : "");
    formData.append("interstedin", InterstedIn !== "" ? InterstedIn : "");
    formData.append("height_inches", heightInches !== "" ? heightInches : "");
    if (sexual_orientation.length > 0) {
      formData.append(
        "sexual_orientation",
        JSON.stringify(sexual_orientation) // Ensure it's saved as a JSON string
      );
    } else {
      formData.append("sexual_orientation", ""); // Append an empty string if no selection
    }
    formData.append(
      "subregion",
      selectedSubRegion !== "" ? selectedSubRegion : ""
    );
    if (selectedTown.length > 0) {
      formData.append(
        "town",
        JSON.stringify(selectedTown) // Ensure it's saved as a JSON string
      );
    } else {
      formData.append("town", ""); // Append an empty string if no selection
    }
    formData.append("genders", genders !== "" ? genders : "");
    formData.append(
      "relationship_status",
      relationship_status !== "" ? relationship_status : ""
    );
    formData.append(
      "search_looking_for",
      search_looking_for !== "" ? search_looking_for : ""
    );
    formData.append("degree", degree !== "" ? degree : "");
    formData.append("fileType", "profileImage");
    formData.append("drinker", drinkerValue !== "" ? drinkerValue : "");
    formData.append("smoker", smokerValue !== "" ? smokerValue : "");
    formData.append("tattos", tattosValue !== "" ? tattosValue : "");
    formData.append(
      "body_piercings",
      body_piercingsValue !== "" ? body_piercingsValue : ""
    );
    formData.append("fetish", fetishValue !== "" ? fetishValue : "");
    formData.append("token", LoginData.token);
    formData.append("email", LoginData.email);
    Object.keys(maleOptions).forEach((option) => {
      if (maleOptions[option]) {
        formData.append("male[]", option); // Append checked options as an array
      }
    });
    Object.keys(femaleOptions).forEach((option) => {
      if (femaleOptions[option]) {
        formData.append("female[]", option); // Append checked options as an array
      }
    });
    Object.keys(coupleOptions).forEach((option) => {
      if (coupleOptions[option]) {
        formData.append("couple[]", option); // Append checked options as an array
      }
    });
    sexuality.forEach((item) => {
      formData.append("sexuality", item.value); // Append only the "value" field
    });

    formData.append("profile_image", selectedFile);
    formData.append("profile_image_1", selectedFile_1);
    formData.append("profile_image_2", selectedFile_2);
    formData.append("profile_image_3", selectedFile_3);
    formData.append("profile_image_4", selectedFile_4);

    formData.append("makeImagePrivate", isImagePrivate ? 1 : 0);

    try {
      const res = await axios.post(apiURL + "updateProfile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      setShowPopSuccess(true);
      setPopsuccessMessage(res.data.message);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        navigate(`/friend/${profilevalue.id}`);
      }, 3500);

      // Handle success (e.g., redirect or show a success message)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage(err.response.data.message); // Store the error message in state
      } else {
        setShowPopSuccess(false);
        setShowPopError(true);
        setPoperrorMessage("An unknown error occurred.");
      }
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
      }, 3500);
    }
  };
  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };
  const openPopup1 = () => {
    setIsPopupOpen1(true);
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  const closePopup1 = () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };
  const locations = {
    "Eastern Norway (Østlandet)": {
      Viken: [
        "Drammen",
        "Bærum",
        "Asker",
        "Moss",
        "Fredrikstad",
        "Sarpsborg",
        "Halden",
        "Lillestrøm",
        "Jessheim",
        "Kongsberg",
      ],
      Innlandet: [
        "Hamar",
        "Lillehammer",
        "Gjøvik",
        "Elverum",
        "Kongsvinger",
        "Tynset",
        "Trysil",
        "Rena",
      ],
      "Vestfold and Telemark": [
        "Tønsberg",
        "Sandefjord",
        "Larvik",
        "Skien",
        "Porsgrunn",
        "Notodden",
        "Rjukan",
        "Kragerø",
        "Bø in Telemark",
      ],
      Oslo: ["Oslo"],
    },
    "Western Norway (Vestlandet)": {
      Hordaland: ["Bergen", "Voss", "Odda", "Stord", "Os", "Leirvik"],
      "Sogn og Fjordane": [
        "Førde",
        "Sogndal",
        "Florø",
        "Årdal",
        "Stryn",
        "Nordfjordeid",
      ],
      Rogaland: [
        "Stavanger",
        "Sandnes",
        "Haugesund",
        "Egersund",
        "Bryne",
        "Sauda",
        "Jørpeland",
      ],
      "Møre og Romsdal": [
        "Ålesund",
        "Molde",
        "Kristiansund",
        "Åndalsnes",
        "Sunndalsøra",
        "Volda",
        "Fosnavåg",
        "Ørsta",
      ],
    },
    Trøndelag: {
      Trøndelag: [
        "Trondheim",
        "Steinkjer",
        "Levanger",
        "Stjørdal",
        "Namsos",
        "Orkanger",
        "Røros",
        "Verdal",
        "Oppdal",
        "Malvik",
        "Melhus",
      ],
    },
    "Northern Norway (Nord-Norge)": {
      Nordland: [
        "Bodø",
        "Narvik",
        "Mo i Rana",
        "Svolvær (Lofoten)",
        "Leknes",
        "Sortland",
        "Mosjøen",
        "Brønnøysund",
        "Fauske",
      ],
      "Troms and Finnmark": [
        "Tromsø",
        "Alta",
        "Hammerfest",
        "Harstad",
        "Vadsø",
        "Kirkenes",
        "Skjervøy",
        "Nordkapp",
        "Lakselv",
        "Kautokeino",
      ],
    },
  };

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedTown, setSelectedTown] = useState([]);

  // Handle region selection
  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    setSelectedSubRegion("");
    setSelectedTown([]); // Reset town when region changes
  };
  const handleSubRegionChange = (e) => {
    setSelectedSubRegion(e.target.value);
    setSelectedTown([]);
  };

  // Handle town selection
  const handleTownChange = (event) => {
    const { value, checked } = event.target;

    setSelectedTown((prevSelectedTowns) => {
      if (checked) {
        // Add the town to the selected list
        return [...prevSelectedTowns, value];
      } else {
        // Remove the town from the selected list
        return prevSelectedTowns.filter((town) => town !== value);
      }
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  console.log("ll", genders);
  const min = 15; // Start value
  const max = 350; // End value
  const step = 1; // Step size

  // Create an array of numbers from min to max
  const optionsheight = Array.from(
    { length: (max - min) / step + 1 },
    (_, i) => min + i * step
  );
  const selectedSexualityOptions = sexuality.filter((option) =>
    sexuality.includes(option.value)
  );
  const closepopup11 = async () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };

  return (
    <>
      <Headertwo />
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      {showPopError && <ErrorPop message={errorPopMessage} />}
      <section className="d-block editblock gapy">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <form
                onSubmit={handleSubmit}
                action="javascript:void(0)"
                method="post"
              >
                <div className="row gy-4">
                  <div className="col-md-3">
                    <div className="row useruploadpic gy-4">
                      <div className="col-12">
                        <div className="uploadfile rel bgload mb-3">
                          <input
                            type="file"
                            name="profile_image"
                            accept="image/*" // Accept only image files
                            onChange={handleFileChange}
                            className="fileload"
                          />
                          <div className="upload_image">
                            {imageUrl ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={imageUrl} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              selectedFile && ( // Fallback to show the previously fetched image
                                <img
                                  className="w-100 h-100"
                                  src={selectedFile} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )
                            )}
                          </div>
                          <div className="inload">
                            <div>
                              <BsCloudUpload />
                              <h3>{t("Upload_image")}</h3>
                            </div>
                          </div>
                          {errorMessage && (
                            <p className="text-danger">{errorMessage}</p>
                          )}
                        </div>
                        <div className="listbox d-flex justify-content-center">
                          <label className="cbox">
                            {t("Make_Image_Private")}
                            <input
                              type="checkbox"
                              name="makeImagePrivate"
                              checked={isImagePrivate} // Bind checkbox to the state
                              onChange={handleCheckChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="uploadfile rel smallload">
                          <input
                            type="file"
                            name="profile_image_1"
                            id=""
                            accept="image/*" // Accept only image files
                            onChange={handleFileChange_1}
                            className="fileload"
                          />
                          <div className="upload_image">
                            {imageUrl1 ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={imageUrl1} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              selectedFile_1 && ( // Fallback to show the previously fetched image
                                <img
                                  className="w-100 h-100"
                                  src={selectedFile_1} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )
                            )}
                          </div>
                          <div className="inload">
                            <FiPlus />
                          </div>
                        </div>
                        {errorMessage_1 && (
                          <p className="text-danger">{errorMessage_1}</p>
                        )}
                      </div>
                      <div className="col-6">
                        <div className="uploadfile rel smallload">
                          <input
                            type="file"
                            name="profile_image_2"
                            accept="image/*" // Accept only image files
                            onChange={handleFileChange_2}
                            id=""
                            className="fileload"
                          />
                          <div className="upload_image">
                            {imageUrl2 ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={imageUrl2} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              selectedFile_2 && ( // Fallback to show the previously fetched image
                                <img
                                  className="w-100 h-100"
                                  src={selectedFile_2} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )
                            )}
                          </div>
                          <div className="inload">
                            <FiPlus />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="uploadfile rel smallload">
                          <input
                            type="file"
                            name="profile_image_3"
                            id=""
                            accept="image/*" // Accept only image files
                            onChange={handleFileChange_3}
                            className="fileload"
                          />
                          <div className="upload_image">
                            {imageUrl3 ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={imageUrl3} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              selectedFile_3 && ( // Fallback to show the previously fetched image
                                <img
                                  className="w-100 h-100"
                                  src={selectedFile_3} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )
                            )}
                          </div>
                          <div className="inload">
                            <FiPlus />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="uploadfile rel smallload">
                          <input
                            type="file"
                            name="profile_image_4"
                            id=""
                            accept="image/*" // Accept only image files
                            onChange={handleFileChange_4}
                            className="fileload"
                          />
                          <div className="upload_image">
                            {imageUrl4 ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={imageUrl4} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              selectedFile_4 && ( // Fallback to show the previously fetched image
                                <img
                                  className="w-100 h-100"
                                  src={selectedFile_4} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )
                            )}
                          </div>
                          <div className="inload">
                            <FiPlus />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="d-flex flex-column gap-3 formlook">
                      <div className="d-flex flex-column flex-md-row gap-3 justify-content-md-between edittitle">
                        <h3>{t("Edit_your_profile")}</h3>

                        <div className="d-flex justify-content-between gap-2">
                          <button
                            type="button"
                            onClick={openPopup1}
                            className="savebtn"
                          >
                            Upload Private Album
                          </button>
                          <button
                            type="button"
                            onClick={openPopup}
                            className="savebtn"
                          >
                            {t("View_Request")}
                          </button>
                          <button type="submit" className="savebtn">
                            {t("Save_Profile")}
                          </button>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label htmlFor="">I'm looking for</label>
                        <textarea
                          name="looking_for"
                          id=""
                          rows="5"
                          defaultValue={profilevalue.looking_for}
                          placeholder="Write something..."
                        ></textarea>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label htmlFor="">{t("Username")}</label>
                        <input
                          type="text"
                          defaultValue={profilevalue.username}
                          placeholder="Write something..."
                          name="username"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label htmlFor="">{t("Location")}</label>
                        <select
                          id="region"
                          name="region"
                          value={selectedRegion}
                          onChange={handleRegionChange}
                        >
                          <option value="">--Select Region--</option>
                          {Object.keys(locations).map((region) => (
                            <option key={region} value={region}>
                              {region}
                            </option>
                          ))}
                        </select>

                        {selectedRegion &&
                          typeof locations[selectedRegion] === "object" && (
                            <>
                              <label htmlFor="subregion">City</label>
                              <select
                                id="subregion"
                                name="subregion"
                                value={selectedSubRegion}
                                onChange={handleSubRegionChange}
                              >
                                <option value="">--Select City--</option>
                                {Object.keys(locations[selectedRegion]).map(
                                  (subRegion) => (
                                    <option key={subRegion} value={subRegion}>
                                      {subRegion}
                                    </option>
                                  )
                                )}
                              </select>
                            </>
                          )}
                        {selectedSubRegion &&
                          locations[selectedRegion][selectedSubRegion] && (
                            <>
                              <label htmlFor="town">Town</label>
                              <div id="town">
                                {locations[selectedRegion][
                                  selectedSubRegion
                                ].map((town, index) => (
                                  <div key={index} className="d-flex gap-2">
                                    <input
                                      type="checkbox"
                                      id={`town-${index}`}
                                      value={town}
                                      checked={selectedTown.includes(town)} // Check if the town is selected
                                      onChange={handleTownChange}
                                    />
                                    <label htmlFor={`town-${index}`}>
                                      {town}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label htmlFor="">Preferences</label>
                        <input
                          type="text"
                          defaultValue={profilevalue.preferences}
                          placeholder="Write something..."
                          name="preferences"
                          value={profilevalue.preferences}
                        />
                      </div>
                      {/* <ul className="d-flex flex-column gap-3 list-group-item">
                        <li className="listbox ">
                          <label
                            className="d-flex align-items-center gap-2 cbox"
                            htmlFor="couple"
                          >
                            <input
                              id="couple"
                              name="couple"
                              value="couple"
                              type="checkbox"
                              checked={isCoupleChecked}
                              onChange={handleCoupleChange}
                            />
                            <span className="checkmark"></span>
                            <p>{t("Couple")}</p>
                          </label>
                          <ul className="d-flex flex-column gap-1 mt-3 couple_list">
                            {Object.keys(coupleOptions).map((option) => (
                              <li className="couple_inner" key={option}>
                                <label
                                  className="d-flex align-items-center gap-2 cbox"
                                  htmlFor={option}
                                >
                                  <input
                                    id={option}
                                    name="couple"
                                    value={option}
                                    type="checkbox"
                                    checked={coupleOptions[option]}
                                    onChange={() =>
                                      handleOptionChangeCouple(option)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  <p>
                                    {option.charAt(0).toUpperCase() +
                                      option
                                        .slice(1)
                                        .replace(/([A-Z])/g, " $1")}
                                  </p>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="listbox ">
                          <label
                            className="d-flex align-items-center gap-2 cbox"
                            htmlFor="female"
                          >
                            <input
                              id="female"
                              name="female"
                              value="female"
                              type="checkbox"
                              checked={isFemaleChecked}
                              onChange={handleFemaleChange}
                            />
                            <span className="checkmark"></span>
                            <p>{t("Female")}</p>
                          </label>
                          <ul className="d-flex flex-column gap-1 mt-3 couple_list">
                            {Object.keys(femaleOptions).map((option) => (
                              <li className="couple_inner" key={option}>
                                <label
                                  className="d-flex align-items-center gap-2 cbox"
                                  htmlFor={option}
                                >
                                  <input
                                    id={option}
                                    name="female"
                                    value={option}
                                    type="checkbox"
                                    checked={femaleOptions[option]}
                                    onChange={() =>
                                      handleOptionChangeFemaless(option)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  <p>
                                    {option.charAt(0).toUpperCase() +
                                      option
                                        .slice(1)
                                        .replace(/([A-Z])/g, " $1")}
                                  </p>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="listbox ">
                          <label
                            className="d-flex align-items-center gap-2 cbox"
                            htmlFor="male"
                          >
                            <input
                              id="male"
                              name="male"
                              value="male"
                              type="checkbox"
                              checked={
                                maleOptions.straight ||
                                maleOptions.bisexual ||
                                maleOptions.bicurious ||
                                maleOptions.gay ||
                                maleOptions.bull
                              } // Check if any of the male options are true
                              onChange={handleMaleChange} // Optionally handle changes for "Male" checkbox
                            />
                            <span className="checkmark"></span>
                            <p>{t("Male")}</p>
                          </label>

                          <ul className="d-flex flex-column gap-1 mt-3 couple_list">
                            {Object.keys(maleOptions).map((option) => (
                              <li className="couple_inner" key={option}>
                                <label
                                  className="d-flex align-items-center gap-2 cbox"
                                  htmlFor={option}
                                >
                                  <input
                                    id={option}
                                    name="male"
                                    value={option}
                                    type="checkbox"
                                    checked={maleOptions[option]} // Reflect the checked state from maleOptions
                                    onChange={() =>
                                      handleOption_ChangeMale(option)
                                    } // Handle changes
                                  />
                                  <span className="checkmark"></span>
                                  <p>
                                    {option.charAt(0).toUpperCase() +
                                      option
                                        .slice(1)
                                        .replace(/([A-Z])/g, " $1")}
                                  </p>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul> */}
                      <Accordion>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de1} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Nationality")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label class="cbox">
                                {t("Caucasian")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Caucasian"
                                  checked={nationality === "Caucasian"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Hispanic")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Hispanic"
                                  checked={nationality === "Hispanic"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Asian")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Asian"
                                  checked={nationality === "Asian"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="cbox">
                                {t("African")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="African"
                                  checked={nationality === "African"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Indian")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Indian"
                                  checked={nationality === "Indian"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Middle_Eastern")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Middle Eastern"
                                  checked={nationality === "Middle Eastern"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Jewish")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Jewish"
                                  checked={nationality === "Jewish"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>

                              <label class="cbox">
                                {t("Other")}
                                <input
                                  type="radio"
                                  name="nationality"
                                  value="Other"
                                  checked={nationality === "Other"} // Check if this option is selected
                                  onChange={handleNationalityChange}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de2} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Bodytype")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Slim")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="slim"
                                  checked={bodyType === "slim"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Athletic")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="athletic"
                                  checked={bodyType === "athletic"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Average")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="average"
                                  checked={bodyType === "average"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Curvy")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="curvy"
                                  checked={bodyType === "curvy"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Full_figured")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="full-figured"
                                  checked={bodyType === "full-figured"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Plus_size")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="plus-size"
                                  checked={bodyType === "plus-size"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Muscular")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="muscular"
                                  checked={bodyType === "muscular"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Petite")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="petite"
                                  checked={bodyType === "petite"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Tall")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="tall"
                                  checked={bodyType === "tall"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Other")}
                                <input
                                  type="radio"
                                  name="bodytype"
                                  value="other"
                                  checked={bodyType === "other"} // Check if this option is selected
                                  onChange={handleBodyTypeChange} // Update state on change
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de3} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Height")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="d-flex gap-2 heightsel">
                              <select
                                id="height-feet"
                                onChange={handleFeetChange}
                                value={heightFeet}
                                name="height_feet"
                              >
                                <option value="">{t("Select_Feet")}</option>
                                {optionsheight.map((value) => (
                                  <option key={value} value={value}>
                                    {value} cm
                                  </option>
                                ))}
                              </select>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de4} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Sexual_orientation")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              {[
                                "Heterosexual",
                                "Homosexual",
                                "Bisexual",
                                "Pansexual",
                                "Asexual",
                                "Other",
                              ].map((label) => (
                                <label className="cbox" key={label}>
                                  {label}
                                  <input
                                    type="checkbox"
                                    name="sexual_orientation"
                                    value={label}
                                    checked={sexual_orientation.includes(label)}
                                    onChange={handleSexualOrientationChange}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              ))}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de15} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Genders")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Male")}
                                <input
                                  type="radio"
                                  name="genders"
                                  checked={genders === "Male"}
                                  onChange={handlegendersChange}
                                  value="Male"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Female")}
                                <input
                                  type="radio"
                                  name="genders"
                                  checked={genders === "Female"}
                                  onChange={handlegendersChange}
                                  value="Female"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans
                                <input
                                  type="radio"
                                  name="genders"
                                  checked={genders === "Trans"}
                                  onChange={handlegendersChange}
                                  value="Trans"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans (MTF)
                                <input
                                  type="radio"
                                  name="genders"
                                  checked={genders === "Trans (MTF)"}
                                  onChange={handlegendersChange}
                                  value="Trans (MTF)"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans (FTM)
                                <input
                                  type="radio"
                                  checked={genders === "Trans (FTM)"}
                                  onChange={handlegendersChange}
                                  name="genders"
                                  value="Trans (FTM)"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Couple")}
                                <input
                                  type="radio"
                                  checked={genders === "Couple"}
                                  onChange={handlegendersChange}
                                  name="genders"
                                  value="Couple"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Non_binary")}
                                <input
                                  type="radio"
                                  checked={genders === "Ikke-binær"}
                                  onChange={handlegendersChange}
                                  name="genders"
                                  value="Ikke-binær"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            {genders === "Male" && (
                              <Select
                                isMulti
                                options={maleOptions_select}
                                value={sexuality}
                                onChange={handleSexualityChange}
                                placeholder="Select Male"
                              />
                            )}

                            {genders === "Female" && (
                              <Select
                                isMulti
                                options={femaleOptions_select}
                                value={sexuality}
                                onChange={handleSexualityChange}
                                placeholder="Select Female"
                              />
                            )}

                            {genders === "Couple" && (
                              <Select
                                isMulti
                                options={coupleOptions_select}
                                value={sexuality}
                                onChange={handleSexualityChange}
                                placeholder="Select Couple"
                              />
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de14} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>Connect With</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                Singel
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Singel"}
                                  onChange={handleSingelChange}
                                  value="Singel"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Man")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Mann"}
                                  onChange={handleSingelChange}
                                  value="Mann"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Woman")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Kvinne"}
                                  onChange={handleSingelChange}
                                  value="Kvinne"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Trans"}
                                  onChange={handleSingelChange}
                                  value="Trans"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans (MTF)
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Trans (MTF)"}
                                  onChange={handleSingelChange}
                                  value="Trans (MTF)"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                Trans (FTM)
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Trans (FTM)"}
                                  onChange={handleSingelChange}
                                  value="Trans (FTM)"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Non_binary")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Ikke-binær"}
                                  onChange={handleSingelChange}
                                  value="Ikke-binær"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Heterocouple")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Heteropar"}
                                  onChange={handleSingelChange}
                                  value="Heteropar"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Gay_couple")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Homopar"}
                                  onChange={handleSingelChange}
                                  value="Homopar"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Reading_guide")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Lesbepar"}
                                  onChange={handleSingelChange}
                                  value="Lesbepar"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Flexi_pair")}
                                <input
                                  type="radio"
                                  name="connectwith"
                                  checked={Singel === "Flexipar"}
                                  onChange={handleSingelChange}
                                  value="Flexipar"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de16} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Intersted_In")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Discussion")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Diskusjon"}
                                  onChange={handleInterstedInChange}
                                  value="Diskusjon"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Inspiration")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Inspirasjon"}
                                  onChange={handleInterstedInChange}
                                  value="Inspirasjon"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Flirt")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Flørt"}
                                  onChange={handleInterstedInChange}
                                  value="Flørt"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Date")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Date"}
                                  onChange={handleInterstedInChange}
                                  value="Date"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Friendship")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Vennskap"}
                                  onChange={handleInterstedInChange}
                                  value="Vennskap"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Relationship")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Forhold"}
                                  onChange={handleInterstedInChange}
                                  value="Forhold"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Love")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Kjærlighet"}
                                  onChange={handleInterstedInChange}
                                  value="Kjærlighet"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Chat")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Chat"}
                                  onChange={handleInterstedInChange}
                                  value="Chat"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Online_contact_only")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Bare nettkontakt"}
                                  onChange={handleInterstedInChange}
                                  value="Bare nettkontakt"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Hit")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Treff"}
                                  onChange={handleInterstedInChange}
                                  value="Treff"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Social_gathering")}
                                <input
                                  type="radio"
                                  name="interstedin"
                                  checked={InterstedIn === "Sosialt samvær"}
                                  onChange={handleInterstedInChange}
                                  value="Sosialt samvær"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de5} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Relationship_status")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Single")}
                                <input
                                  type="radio"
                                  name="relationship_status"
                                  value="Single"
                                  checked={relationship_status === "Single"}
                                  onChange={handleRelationStatusChange}
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("In_a_Relationship")}
                                <input
                                  type="radio"
                                  checked={
                                    relationship_status === "InaRelationship"
                                  }
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="InaRelationship"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Engaged")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "engaged"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="engaged"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Married")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "Married"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Married"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("It_s_Complicated")}
                                <input
                                  type="radio"
                                  checked={
                                    relationship_status === "ItsComplicated"
                                  }
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="ItsComplicated"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Separated")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "Separated"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Separated"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Divorced")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "Divorced"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Divorced"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Widowed")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "Widowed"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Widowed"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Polyamorous")}
                                <input
                                  type="radio"
                                  checked={
                                    relationship_status === "Polyamorous"
                                  }
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Polyamorous"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Other")}
                                <input
                                  type="radio"
                                  checked={relationship_status === "Other"}
                                  onChange={handleRelationStatusChange}
                                  name="relationship_status"
                                  value="Other"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de6} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Looking_for")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Friends")}
                                <input
                                  type="radio"
                                  name="search_looking_for"
                                  value="Friends"
                                  checked={search_looking_for === "Friends"}
                                  onChange={handleSearchLookingforChange}
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Dating")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Dating"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Dating"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Parties")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Parties"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Parties"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Flirting")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Flirting"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Flirting"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Relationships")}
                                <input
                                  type="radio"
                                  checked={
                                    search_looking_for === "Relationships"
                                  }
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Relationships"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Fun")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Fun"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Fun"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Serious")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Serious"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Serious"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Relationships")}
                                <input
                                  type="radio"
                                  checked={
                                    search_looking_for === "Relationships"
                                  }
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Relationships"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Hookups")}
                                <input
                                  type="radio"
                                  checked={search_looking_for === "Hookups"}
                                  onChange={handleSearchLookingforChange}
                                  name="search_looking_for"
                                  value="Hookups"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de7} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Degree")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <select
                              id="degree"
                              onChange={handleDegreeChange}
                              value={degree}
                              name="degree"
                            >
                              <option value="">
                                {t("Select_your_degree")}
                              </option>
                              <option value="BachelorDegree">
                                {t("Bachelor_s_Degree")}
                              </option>
                              <option value="MasterDegree">
                                {t("Master_s_Degree")}
                              </option>
                              <option value="DoctorateDegree">
                                {t("Doctorate_s_Degree")}
                              </option>
                              <option value="other">{t("Other")}</option>
                            </select>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de8} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Drinker")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Yes")}
                                <input
                                  type="radio"
                                  name="drinker"
                                  checked={drinkerValue === "Yes"} // Set checked based on state
                                  onChange={handledrinkerChange}
                                  value="Yes"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("No")}
                                <input
                                  type="radio"
                                  name="drinker"
                                  checked={drinkerValue === "No"} // Set checked based on state
                                  onChange={handledrinkerChange}
                                  value="No"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de9} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Smoker")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Yes")}
                                <input
                                  type="radio"
                                  name="smoker"
                                  checked={smokerValue === "Yes"} // Set checked based on state
                                  onChange={handlesmokerChange}
                                  value="Yes"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("No")}
                                <input
                                  type="radio"
                                  checked={smokerValue === "No"} // Set checked based on state
                                  onChange={handlesmokerChange}
                                  name="smoker"
                                  value="No"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de10} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Tattoos")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Yes")}
                                <input
                                  type="radio"
                                  name="tattos"
                                  checked={tattosValue === "Yes"} // Set checked based on state
                                  onChange={handletattosChange}
                                  value="Yes"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("No")}
                                <input
                                  type="radio"
                                  checked={tattosValue === "No"} // Set checked based on state
                                  onChange={handletattosChange}
                                  name="tattos"
                                  value="No"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Few")}
                                <input
                                  type="radio"
                                  checked={tattosValue === "Few"} // Set checked based on state
                                  onChange={handletattosChange}
                                  name="tattos"
                                  value="Few"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de11} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Body_piercings")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("Yes")}
                                <input
                                  type="radio"
                                  name="body_piercings"
                                  checked={body_piercingsValue === "Yes"} // Set checked based on state
                                  onChange={handlebody_piercingsChange}
                                  value="Yes"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("No")}
                                <input
                                  type="radio"
                                  name="body_piercings"
                                  checked={body_piercingsValue === "No"} // Set checked based on state
                                  onChange={handlebody_piercingsChange}
                                  value="No"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Few")}
                                <input
                                  type="radio"
                                  name="body_piercings"
                                  checked={body_piercingsValue === "Few"} // Set checked based on state
                                  onChange={handlebody_piercingsChange}
                                  value="Few"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="d-flex gap-2 align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="acciconimg fulw">
                                    <img src={de12} alt="accimg" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h4>{t("Fetish")}</h4>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="listbox d-flex flex-wrap gap-3">
                              <label className="cbox">
                                {t("None")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "None"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="None"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                BDSM
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "BDSM"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="BDSM"
                                />
                                <span className="checkmark"></span>
                              </label>

                              <label className="cbox">
                                {t("Bondage")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Bondage"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Bondage"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Dominant")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Dominant"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Dominant"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Latex_&_Leather")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Latex & Leather"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Latex & Leather"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Roleplay")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Roleplay"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Roleplay"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Spanking")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Spanking"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Spanking"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Switch")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Switch"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Switch"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Submissive")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Submissive"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Submissive"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Lingerie")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Lingerie"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Lingerie"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("Wet")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Wet"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Wet"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="cbox">
                                {t("FriendSearchPage_SexualOther")}
                                <input
                                  type="checkbox"
                                  name="fetish"
                                  checked={fetishValue === "Other"} // Set checked based on state
                                  onChange={handlefetishChange}
                                  value="Other"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PrivateImageRequest
        isOpen={isPopupOpen}
        onClose={closePopup}
        LoginData={LoginData}
      />
      <Uploadprivatealbum
        isOpen={isPopupOpen1}
        onClose={closePopup1}
        LoginData={LoginData}
        closepopup11={closepopup11}
      ></Uploadprivatealbum>
    </>
  );
}

export default Editprofile;
