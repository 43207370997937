import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";

import {
  faUsers,
  faPhotoFilm,
  faUsersCog,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
export default function Users() {
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]); // Full list of records

  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [showModaljoinedgroup, setshowModaljoinedgroup] = useState(false);
  const [joinedgroupdata, setjoinedgroupdata] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);
  const tableRef = useRef(null); // Reference to the table

  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
      sortable: true,
      className: "age",
    },
    {
      name: "Membership Status",
      selector: (row) => {
        // Compare the end_date with the current date
        const endDate = moment(row.end_date);
        const currentDate = moment(); // Current date
        return endDate.isAfter(currentDate) ? "Active" : "Expired"; // If end_date is after current date, return "Active", otherwise "Expired"
      },
      sortable: true,
      className: "age",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <Link
            to={`/admin/userfriendlist/${row.slug}`}
            className="dataedit_btn text-info"
            title="View Friend List"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faUsers} />
          </Link>
          {/* <a
            href={`/admin/usersdetails/${row.slug}`}
            className="dataedit_btn text-success"
            title="View Forum"
          >
            <FontAwesomeIcon icon={faComments} />
          </a> */}
          <Link
            to={`/admin/userevents/${row.slug}`}
            className="dataedit_btn text-secondary"
            title="View Events"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Link>
          <Link
            to={`/admin/usergroups/${row.slug}`}
            className="dataedit_btn text-warning"
            title="View Groups"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faUsersCog} />
          </Link>
          <Link
            to={`/admin/usergallery/${row.slug}`}
            className="dataedit_btn text-primary"
            title="View Gallery"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faPhotoFilm} />
          </Link>
          <Link
            to={`/admin/userforum/${row.slug}`}
            className="dataedit_btn text-primary"
            title="View Forum"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faCommentDots} />
          </Link>
          <Link
            to={`/admin/usersdetails/${row.slug}`}
            className="dataedit_btn text-dark"
            title="View Other Details"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            className="dataedit_btn"
            title="Edit Status"
            style={{ fontSize: "16px" }}
            onClick={() => handleEdit(row)}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </Link>

          <Link
            to={`/admin/usersprofile/${row.slug}`}
            className="dataedit_btn text-success"
            title="View/Update Profile"
            style={{ fontSize: "16px" }}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </Link>

          <Link
            className="dataedit_btn "
            title="Message Sent"
            style={{ fontSize: "16px" }}
            to={`/admin/usersentmessage/${row.slug}`}
          >
            <FontAwesomeIcon icon={faComments} />
          </Link>
          <Link
            className="dataedit_btn text-success"
            title="Total Joined Groups"
            style={{ fontSize: "16px" }}
            onClick={() => handleJoinedGroup(row.id)}
          >
            <FontAwesomeIcon icon={faUsersCog} />
          </Link>
          <Link
            className="datadelete_btn text-danger"
            title="Delete"
            style={{ fontSize: "16px" }}
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getallusers();
  }, []);
  const getallusers = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallusers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
      setOriginalRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  // setInterval(() => {
  //   getallusers();
  // }, 2500);
  const handleJoinedGroup = async (id) => {
    setshowModaljoinedgroup(true);
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getJoinedGroups", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setjoinedgroupdata(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset(
      "Are you sure? If you proceed, all data will be permanently deleted"
    );
    setUserIdToDelete(id);
  };

  const handleEdit = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setshowModaljoinedgroup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };

  const handleSaveChanges = () => {
    setRecords(
      records.map((record) =>
        record.id === currentRecord.id ? currentRecord : record
      )
    );
    setShowModal(false);
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();

    if (lowerCaseSearch.trim() === "") {
      // Reset to full list when the input is empty
      setRecords(originalRecords);
    } else {
      const filteredData = originalRecords.filter(
        (row) =>
          row.username.toLowerCase().includes(lowerCaseSearch) ||
          row.email.toLowerCase().includes(lowerCaseSearch) ||
          row.status.toString().includes(lowerCaseSearch) ||
          row.start_date.toLowerCase().includes(lowerCaseSearch)
      );
      setRecords(filteredData);
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deleteusers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getallusers();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.status.value);

    let formData = {
      status: e.target.status.value,
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "editprofile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setShowModal(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(respo.message);
      getallusers();
      setTimeout(() => {
        setShowPopSuccess(false);
      }, 1000);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">User List</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
                <Modal
                  show={showModal}
                  className="edit_admin"
                  onHide={handleModalClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-white">
                      Edit status
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          disabled
                          name="username"
                          value={currentRecord.username}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                          value={currentRecord.status}
                          onChange={handleInputChange}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Banned">Banned</option>
                        </select>
                      </div>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          className="bg-success border-color-none"
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModaljoinedgroup}
        className="edit_admin"
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">Joined Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            {joinedgroupdata.length === 0 ? (
              <p>No result found</p>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Group Name</th>
                    <th>Image</th>
                    <th>Created By</th>
                    <th>Days</th>
                  </tr>
                </thead>
                <tbody>
                  {joinedgroupdata.map((com, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{com.name}</td>
                      <td>
                        <img
                          src={com.image}
                          width="50"
                          height="50"
                          alt="Image"
                        />
                      </td>
                      <td>{com.username}</td>
                      <td>{formateTime(com.date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
