import React, { useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import axios from "axios";
export const Helpcenter = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();
  const [contentabout, setContentabout] = useState("");
  const [contentprivacy, setContentprivacy] = useState("");
  const [contentcollection, setContentcollection] = useState("");
  const [contentcookies, setContentcookies] = useState("");
  const [contentsharing, setContentsharing] = useState("");
  const [contentdata, setContentdata] = useState("");
  const [contentcontact, setContentcontact] = useState("");
  const [contentsupport, setContentsupport] = useState("");
  const [contenttime, setContenttime] = useState("");
  const [contentright, setContentright] = useState("");
  const [contentsale, setContentsale] = useState("");
  const [contentpayment, setContentpayment] = useState("");
  const [contentmember, setContentmember] = useState("");
  const apiUrlAdmin = "https://backend.amourette.no/api/admin/";
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  useEffect(() => {
    getprivacydetail();
  }, []);
  const getprivacydetail = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrlAdmin + "getprivacydetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      setContentabout(respo.about_us);
      setContentprivacy(respo.privacy_policy);
      setContentcollection(respo.information_collection);
      setContentcookies(respo.cookies_text);
      setContentsharing(respo.information_sharing);
      setContentdata(respo.data_security);
      setContentcontact(respo.contact_information);
      setContentsupport(respo.support_and);
      setContenttime(respo.delivery_time);
      setContentright(respo.right_of_withdrawal);
      setContentsale(respo.sale_to);
      setContentpayment(respo.payment_solution);
      setContentmember(respo.membership);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="d-flex flex-column gap-4 mship">
        <h1 className="shiptitle">{t("Help_center")}</h1>
        <div className="d-flex flex-column gap-3">
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("About_Us")}</h3>
              <div dangerouslySetInnerHTML={{ __html: contentabout }} />
            </div>
            <div className="d-flex flex-column gap-2">
              <h3>{t("Privacy_Policy")}</h3>
              <div dangerouslySetInnerHTML={{ __html: contentprivacy }} />
              {/* <Link to="/" className="clicklink">
                Click to view
              </Link> */}
            </div>
            <div className="d-flex flex-column gap-2">
              <h3>{t("Information_Collection")}</h3>
              <div dangerouslySetInnerHTML={{ __html: contentcollection }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Cookies_and_Tracking_Technology")}:</h3>

              <div dangerouslySetInnerHTML={{ __html: contentcookies }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Information_Sharing")}:</h3>

              <div dangerouslySetInnerHTML={{ __html: contentsharing }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Data_Security")}:</h3>

              <div dangerouslySetInnerHTML={{ __html: contentdata }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Contact_Information_for_Privacy")}:</h3>

              <div dangerouslySetInnerHTML={{ __html: contentcontact }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Purchase_Terms")}</h3>
              <h3>{t("Company_Information")}</h3>
              <p>{t("Organization_number")}</p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Support_and_Availability")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentsupport }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Consumer_Rights")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentright }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Products_and_Costs")}:</h3>

              <p>{t("VIP_membership")}:</p>
              <ul>
                <li>
                  30 {t("days")}: 89 {t("ENOUGH")}
                </li>
                <li>
                  90 {t("days")}: 229 {t("ENOUGH")}
                </li>
                <li>
                  365 {t("days")}: 749 {t("ENOUGH")}
                </li>
              </ul>
              <p>{t("Memberships_automatically")}</p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Delivery_Time")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contenttime }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Membership_Termination")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentmember }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3> {t("Age_Limit")}</h3>

              <p>{t("Membership_and")}</p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Right_of_Withdrawal")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentright }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Sales_to_Minors")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentsale }} />
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Payment_Solutions")}</h3>

              <div dangerouslySetInnerHTML={{ __html: contentpayment }} />
            </div>
          </div>

          {/* <div className="d-block faqblock">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div> */}
        </div>
      </div>
    </>
  );
};
