import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import SuccessPop from "../components/SuccessPop";
export const Createnewgrouppost = ({
  isOpen,
  onClose,
  children,
  groupId,
  LoginData,
  reSetPost,
}) => {
  var apiUrl = "https://backend.amourette.no/api/groups/";
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [spinner, setspinner] = useState(false);
  const [restr_message, setrestr_message] = useState(false);
  const [fileType, setFileType] = useState("");
  if (!isOpen) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };
  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 2 MB
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image or video
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (!isImage && !isVideo) {
        setError("Please upload a valid image or video file.");
        setImage(null);
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setError("File size must be less than 5 MB.");
        setImage(null);
        return;
      }

      // Clear previous error messages
      setError("");
      setImage(file);
      setFileType(isImage ? "image" : "video");

      if (isVideo) {
        //const resizedVideoUrl = await resizeVideo(file);
        // setVideoUrl(resizedVideoUrl);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setspinner(true);
    const formData = new FormData();
    formData.append("description", description);
    formData.append("image", image);
    formData.append("group_id", groupId);
    formData.append("user_id", LoginData.id);
    formData.append("fileType", "groupspost");
    try {
      const res = await axios.post(apiUrl + "createGroupPost", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form", // Ensure the content type is JSON
        },
      });
      console.log(res.status);
      setspinner(false);
      if (res.status === 200) {
        setShowPopSuccess(true);
        setPopsuccessMessage("Post created successfully");
        console.log("Submission successful:", res.data);
        // Reset the form fields
        reSetPost();
        setTimeout(() => {
          setShowPopSuccess(false);
          setPopsuccessMessage("");
          onClose();
        }, 800);
        setDescription("");
        setImage(null);
        setError("");
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        // console.error("Request data:", err.request);
      } else {
        //console.error("Error message:", err.message);
      }
    }
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  const isButtonDisabled = (!description.trim() && !image) || restr_message;
  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop  d-flex flex-column gap-4">
            <h3>CREATE POST</h3>
            {restr_message && (
              <span className="text-danger" style={{ fontSize: "14px" }}>
                Your message contains restricted words. Please remove them
              </span>
            )}
            <form
              action="javascript:void(0)"
              method="post"
              onSubmit={handleSubmit}
            >
              <div className="row gy-3 align-items-end">
                <div className="col-12">
                  <textarea
                    rows="8"
                    placeholder="What do you think?"
                    value={description}
                    onChange={handleCommentChange}
                  ></textarea>
                </div>
                <div className="col-12">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      accept="image/*,video/*"
                      className="fileload"
                      name="image"
                      onChange={handleImageChange}
                    />
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>Upload image</h3>
                      </div>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={isButtonDisabled} // Disable button if comment is empty
                    style={{ opacity: isButtonDisabled ? 0.1 : 1 }}
                  >
                    POST
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
