import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Leftsidebar } from "../components/Leftsidebar";
import { Rightsidebar } from "../components/Rightsidebar";
import Dashboardnotification from "../components/Dashboardnotification";
import { SlOptions } from "react-icons/sl";
import userpic from "../assets/images/pic.png";
import img1 from "../assets/images/pic.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function Dashboard() {
  const navigate = useNavigate();
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const [postdetail, setPostdetail] = useState([]);
  const [AllfriendId, setAllfriendId] = useState([]);
  const [LoginData, setLoginData] = useState("");
  var apiUrl = "https://backend.amourette.no/api/members/";
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    console.log("test");
    if (userLogin === null) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    setLoginData(userLogin);
  }, []);

  useEffect(() => {
    const getAllfriendss = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        user_id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrl + "getAllfriends", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.results;
        const friendUserIds = respo.map((friend) => friend.id);
        setAllfriendId(friendUserIds);
        console.log(friendUserIds);
        const galleries = await getAllgallery(friendUserIds);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getAllfriendss();
  }, []);
  const getAllgallery = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const userIdsWithSelf = [...userIds, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      user_ids: userIdsString, // Sending as a string for the query
      user_id: userLogin.id, // Sending as a string for the query
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getdashboardpost", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      //console.log(respo);

      setPostdetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  console.log(postdetail);

  return (
    <>
      <Headertwo refreshNotifications={refreshNotifications} />
      <section className="dashbox d-block">
        <div className="container-lg">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-xl-2 sidefixedbox sticky-top h-100 d-none d-lg-block">
              <Leftsidebar />
            </div>

            <div className="col-lg-5 col-xl-6">
              <div className="d-flex flex-column gap-3">
                <h5 className="grouptitle mb-3">{t("Friends_activity")}</h5>
                {postdetail.length === 0 ? (
                  <div className="postblock d-flex flex-column rounded-4 overflow-hidden p-2">
                    <h2>{t("No_activity")}</h2>
                  </div>
                ) : (
                  // Access only the first item in the array
                  <div
                    className="postblock d-flex flex-column rounded-4 overflow-hidden"
                    key={0}
                  >
                    <div className="d-flex justify-content-between align-items-center px-3 py-1">
                      <div className="flex-grow-1">
                        <Link
                          to="javascript:void(0)"
                          className="d-flex gap-2 align-items-center"
                        >
                          <div className="flex-shrink-0">
                            <div className="urpic ofit rounded-circle overflow-hidden">
                              {postdetail && postdetail[0] ? (
                                postdetail[0].makeImagePrivate === 1 ? (
                                  <img
                                    src={require("../assets/images/thumb.jpg")} // Default image when image is private
                                    alt="userpic"
                                  />
                                ) : // Check if it's an image or video based on file extension
                                postdetail[0].uimage.match(
                                    /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                                  ) ? (
                                  <img src={postdetail[0].uimage} alt="img s" />
                                ) : postdetail[0].uimage.match(
                                    /\.(mp4|mov|avi)$/i
                                  ) ? (
                                  <video controls alt="video">
                                    <source
                                      src={postdetail[0].uimage}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : null
                              ) : (
                                <p></p> // In case postdetail is not available
                              )}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            {/* Conditionally render the text based on formattedTime */}
                            {formateTime(postdetail[0].date) === "Just now" ? (
                              <h4>
                                {postdetail[0].username} Just posted a new
                                photo.
                              </h4>
                            ) : (
                              <h4>{postdetail[0].username}</h4>
                            )}
                            <h5>{formateTime(postdetail[0].date)}</h5>
                          </div>
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        <button type="button" className="btnoption">
                          <SlOptions />
                        </button>
                      </div>
                    </div>
                    <Link
                      to="javascript:void(0)"
                      className="imgblock ofit rounded-3 overflow-hidden mt-2"
                    >
                      {postdetail[0].image.match(
                        /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                      ) ? (
                        <img src={postdetail[0].image} alt="img" />
                      ) : postdetail[0].image.match(/\.(mp4|mov|avi)$/i) ? (
                        <video controls alt="video">
                          <source src={postdetail[0].image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : null}
                    </Link>
                  </div>
                )}

                <Dashboardnotification refresh={refreshNotifications} />
                {postdetail.length === 0 ? (
                  <h2></h2>
                ) : (
                  postdetail.slice(1).map((item, index) => {
                    // Start from index 1
                    const formattedTime = formateTime(item.date); // Get the formatted time
                    return (
                      <div
                        className="postblock d-flex flex-column rounded-4 overflow-hidden"
                        key={index}
                      >
                        <div className="d-flex justify-content-between align-items-center  px-3 py-1">
                          <div className="flex-grow-1">
                            <Link
                              to="javascript:void(0)"
                              className="d-flex gap-2 align-items-center"
                            >
                              <div className="flex-shrink-0">
                                <div className="urpic ofit rounded-circle overflow-hidden">
                                  {item.makeImagePrivate === 1 ? (
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/thumb.jpg")} // Default image when image is private
                                      alt="userpic"
                                    />
                                  ) : (
                                    <img src={item.uimage} alt="userpic" />
                                  )}
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                {/* Conditionally render the text based on formattedTime */}
                                {formattedTime === "Just now" ? (
                                  <h4>
                                    {item.username} Just posted a new photo.
                                  </h4>
                                ) : (
                                  <h4>{item.username}</h4>
                                )}
                                <h5>{formattedTime}</h5>
                              </div>
                            </Link>
                          </div>
                          <div className="flex-shrink-0">
                            <button type="button" className="btnoption">
                              <SlOptions />
                            </button>
                          </div>
                        </div>
                        <Link
                          to="javascript:void(0)"
                          className="imgblock ofit rounded-3 overflow-hidden mt-2"
                        >
                          {item.image.match(
                            /\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i
                          ) ? (
                            <img
                              className="w-100 h-100"
                              src={item.image}
                              alt="img"
                            />
                          ) : item.image.match(/\.(mp4|mov|avi)$/i) ? (
                            <video className="w-100 h-100" controls alt="video">
                              <source src={item.image} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : null}
                        </Link>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sidefixedbox sticky-top h-100 d-none d-lg-block">
              <Rightsidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
