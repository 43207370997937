import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import Headertwosetting from "../components/Headertwosetting";
import { Footer } from "../components/Footer";
import ms1 from "../assets/images/ms1.png";
import ms2 from "../assets/images/ms2.png";
import ms3 from "../assets/images/ms3.png";
import ms4 from "../assets/images/ms4.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import { Preference } from "../components/Preference";
import { Security } from "../components/Security";

import { Helpcenter } from "../components/Helpcenter";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Initialize Stripe
const stripePromise = loadStripe(
  "pk_test_51ODoJFAQYHZn8ah9QB5HAmzPsnqc6xoUuKoUMfzjDZgKwl6zrfT4QNrL2bEVRj9NVHMAkJUobVAzGNKpv0lM5sFg00HRJXBT4V"
);

const PaymentForm = () => {
  const apiURL = "https://backend.amourette.no/api/members/";
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [paymentdetail, setpaymentdetail] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const storedpayment = localStorage.getItem("paydata");
    const paydata = JSON.parse(storedpayment);

    // Check if paydata is null and redirect to '/setting'
    if (paydata === null) {
      navigate("/setting");
    } else {
      setpaymentdetail(paydata);
    }
  }, []); // Empty dependency array to run only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setPoperrorMessage("Stripe is not loaded correctly.");
      setIsProcessing(false);
      setShowPopError(true);
      setTimeout(() => {
        setPoperrorMessage("");
        setShowPopError(false);
      }, 2500);
      return;
    }

    try {
      // Fetch client secret from your backend
      const storedpayment = localStorage.getItem("paydata");
      const paydata = JSON.parse(storedpayment);

      const formData = {
        amount: paydata.amount,
        days: paydata.days,
        currency: "NOK",
      }; // Amount in cents
      const response = await axios.post(
        apiURL + "create_payment_intent",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const clientSecret = response.data.clientSecret;

      if (!clientSecret) {
        setPoperrorMessage("Failed to retrieve client secret.");
        setIsProcessing(false);
        setShowPopError(true);
        setTimeout(() => {
          setPoperrorMessage("");
          setShowPopError(false);
        }, 2500);
        return;
      }

      // Confirm card payment
      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        console.error("Payment Error:", error);
        setPoperrorMessage(error.message);
        setShowPopSuccess(false);
        setShowPopError(true);
        setTimeout(() => {
          setPoperrorMessage("");
          setShowPopError(false);
        }, 2500);
      } else if (paymentIntent.status === "succeeded") {
        console.log("Payment Successful:", paymentIntent);
        setPopsuccessMessage("Payment has been successfully done!");
        paymentdatasave(paymentIntent);
        setShowPopSuccess(true);
        setShowPopError(false);
        setTimeout(() => {
          navigate("/setting");
          setPopsuccessMessage("");
          setShowPopSuccess(false);
        }, 2500);
      }
    } catch (err) {
      console.error("Error in Payment Process:", err);
      setPoperrorMessage("An error occurred while processing your payment.");
      setShowPopError(true);
      setTimeout(() => {
        setPoperrorMessage("");
        setShowPopError(false);
      }, 2500);
    } finally {
      setIsProcessing(false);
    }
  };

  const paymentdatasave = async (paymentIntent) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      detail: paymentIntent,
      paymentdetail: paymentdetail,
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiURL + "paymentdatasave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log("cj", respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <div className="payment-form row">
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      {showPopError && <ErrorPop message={errorPopMessage} />}
      <div className="col-12">
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column gap-3">
            <h4>Card Details</h4>
            <div className="d-flex flex-column gap-1">
              <label>Name</label>
              <input type="text" placeholder="Enter cardholder name" required />
            </div>
            <div className="d-flex flex-column gap-1">
              <label>Card Details</label>
              <CardElement
                options={{
                  style: {
                    base: {
                      color: "#fff",
                      fontSize: "16px",
                      iconColor: "#666ee8",
                      lineHeight: "24px",
                      backgroundColor: "transparent",
                      border: "1px solid #fff",
                      padding: "7px 10px",
                      borderRadius: "5px",
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                className="card-input"
              />
            </div>
            {paymentError && (
              <div className="error-message">{paymentError}</div>
            )}
            {paymentSuccess && (
              <div className="success-message">Payment successful!</div>
            )}
            <div className="d-flex justify-content-end align-items-end">
              <button type="submit" className="submit_btn">
                {isProcessing ? "Processing..." : paymentdetail.amount + " NOK"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

function Payment() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const setSelectedIndexcheck = (ch) => {
    if (ch === 0) {
      navigate("/setting");
    }
    if (ch === 1) {
      setIsPopupOpen(true);
    }
    if (ch === 2) {
      setIsPopupOpen1(true);
    }
    if (ch === 3) {
      setIsPopupOpen2(true);
    }
    setSelectedIndex(ch);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };

  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const openPopup1 = () => {
    setIsPopupOpen1(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);

  const openPopup2 = () => {
    setIsPopupOpen2(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup2 = () => {
    setIsPopupOpen2(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  return (
    <>
      <Headertwosetting />
      <section className="d-block membershipbox gapy">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Tabs
                className="member-tabs"
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndexcheck(index)}
              >
                <TabList>
                  <Tab>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms1} alt="Membership" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Membership and Billing</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms2} alt="Preferences" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Preferences</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms3} alt="Security" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Security</h4>
                      </div>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div className="iconmenu fulw">
                          <img src={ms4} alt="Help Center" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Help Center</h4>
                      </div>
                    </div>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="d-flex flex-column gap-4 mship">
                    <Elements stripe={stripePromise}>
                      <PaymentForm />
                    </Elements>
                  </div>
                </TabPanel>
                <TabPanel>
                  <Preference
                    isOpen={isPopupOpen}
                    onClose={closePopup}
                  ></Preference>
                </TabPanel>
                <TabPanel>
                  <Security
                    isOpen={isPopupOpen1}
                    onClose={closePopup1}
                  ></Security>
                </TabPanel>
                <TabPanel>
                  <Helpcenter
                    isOpen={isPopupOpen2}
                    onClose={closePopup2}
                  ></Helpcenter>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Payment;
