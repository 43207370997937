import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
export const Uploadprivatealbum = ({
  isOpen,
  onClose,
  children,
  closepopup11,
}) => {
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };
  const [spinner, setspinner] = useState(false);
  const [images, setImages] = useState(null);
  const [visibility, setVisibility] = useState("");
  const [addToAlbum, setAddToAlbum] = useState("Yes");
  const [rightsConfirmed, setRightsConfirmed] = useState("Yes");
  const [error, setError] = useState("");
  const [successmsg, setsuccessmsg] = useState("");
  var apiUrl = "https://backend.amourette.no/api/members/";
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    let validFiles = [];
    let fileError = "";

    Array.from(selectedFiles).forEach((file) => {
      if (file.size > maxFileSize) {
        fileError = `File "${file.name}" exceeds the 5 MB limit.`;
      } else {
        validFiles.push(file);
      }
    });

    if (fileError) {
      setError(fileError);
    } else {
      setError("");
      setImages(validFiles);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!images) {
      alert("Please select images to upload.");
      return;
    }
    setspinner(true);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const formData = new FormData();

    images.forEach((file) => {
      formData.append("images", file); // Use the key 'files' for your backend to handle multiple files
    });
    formData.append("visibility", visibility);
    formData.append("addToAlbum", addToAlbum);
    formData.append("rightsConfirmed", rightsConfirmed);
    formData.append("user_id", userLogin.id);
    formData.append("fileType", "");
    try {
      const res = await axios.post(apiUrl + "saveprivateAlbum", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      var respo = res.data;
      setsuccessmsg(respo.message);
      setTimeout(() => {
        setspinner(false);
        setsuccessmsg("");
        closepopup11();
        setVisibility("");
        setAddToAlbum("Yes");
        setRightsConfirmed("Yes");
        setImages(null);
      }, 3500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop  d-flex flex-column gap-4">
            <h3>UPLOAD ALBUM</h3>
            <form
              action="javascript:void(0)"
              method="post"
              onSubmit={handleSubmit}
            >
              <div className="row gy-3 align-items-end">
                <div className="col-md-12">
                  <div className="uploadfile rel bgload">
                    <p className="pb-2">
                      Select one or more images: (GIF connot be large than 5MB)
                    </p>
                    <input
                      type="file"
                      name="file"
                      required
                      multiple
                      onChange={handleFileChange}
                      className="fileload"
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="inload" style={{ height: "50px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 className="m-0" style={{ fontSize: "14px" }}>
                          Upload image
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex flex-column gap-2">
                    <p>How will see the images you upload?</p>
                    <div className="d-flex align-items-center gap-2">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-lock"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                          <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                          <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                        </svg>
                      </div>
                      <select
                        value={visibility}
                        onChange={(e) => setVisibility(e.target.value)}
                        className=""
                        required
                        name="visibility"
                      >
                        <option value="">--Select--</option>
                        <option value="Public_visible">All</option>
                        <option value="Friends_visible">
                          Friends visible only to approved friends.
                        </option>
                        <option value="Private_visible">
                          Private visible only to the uploader.
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column gap-2">
                    <p>Add the images to an album?</p>
                    <div className="d-flex align-items-center gap-2">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-library-photo"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 3m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                          <path d="M4.012 7.26a2.005 2.005 0 0 0 -1.012 1.737v10c0 1.1 .9 2 2 2h10c.75 0 1.158 -.385 1.5 -1" />
                          <path d="M17 7h.01" />
                          <path d="M7 13l3.644 -3.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644" />
                          <path d="M15 12l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l2.644 2.644" />
                        </svg>
                      </div>
                      <select
                        name="addToAlbum"
                        value={addToAlbum}
                        onChange={(e) => setAddToAlbum(e.target.value)}
                        required
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column gap-2 warning_text">
                    <div className="d-flex align-items-center gap-2">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 9v4" />
                          <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                          <path d="M12 16h.01" />
                        </svg>
                      </div>
                      <p>Responsibility for uploading images</p>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <p>
                        {" "}
                        <span>
                          This forum ONLY allows the upload of images that YOU
                          personally own and have full and legal rights to. It
                          is STRICTLY PROHIBITED to upload images that belong to
                          others, even if you have permission or provide a
                          source reference!
                        </span>
                        <br />
                        <span>
                          By uploading images to this forum, you confirm that
                          you are fully responsible for respecting copyright and
                          privacy laws related to the images you upload.
                          Violations of this rule will result in the immediate
                          removal of content and strictly enforced penalties in
                          accordance with our guidelines. We insist on
                          respecting others' rights and privacy. Violations of
                          this fundamental rule will not be tolerated.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column gap-2">
                    <p>
                      Do you have the rights to the images you are uploading?
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-copyright"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                          <path d="M14 9.75a3.016 3.016 0 0 0 -4.163 .173a2.993 2.993 0 0 0 0 4.154a3.016 3.016 0 0 0 4.163 .173" />
                        </svg>
                      </div>
                      <select
                        value={rightsConfirmed}
                        onChange={(e) => setRightsConfirmed(e.target.value)}
                        name="rightsConfirmed"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <span className="text-success">{successmsg}</span>
                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success send_load"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button type="submit">Upload</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
