import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
import { useNavigate } from "react-router-dom";
export default function Users() {
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]); // Full list of records

  const [showModal, setShowModal] = useState(false);
  const [checkk, setcheck] = useState("");
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [checkstatus, setcheckstatus] = useState(false);
  const [err_pass, seterr_pass] = useState("");
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [showModaljoinedgroup, setshowModaljoinedgroup] = useState(false);
  const [joinedgroupdata, setjoinedgroupdata] = useState([]);
  const [showModaladd, setshowModaladd] = useState(false);
  const [insertmessage, setinsertmessage] = useState("");
  const [mesg, setmesg] = useState("");
  const [errrec, seterrrec] = useState("");
  const [insertmessageerr, setinsertmessageerr] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);
  const tableRef = useRef(null); // Reference to the table

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "View Password",
      selector: (row) => row.viewpassword,
      sortable: true,
      className: "age",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            onClick={() => handleEdit(row)}
            className="dataedit_btn text-primary"
            style={{ fontSize: "16px" }}
            title="Edit"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </a>
          <a
            onClick={() => handleDelete(row.id)}
            className="dataedit_btn text-danger"
            style={{ fontSize: "16px" }}
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getalladmin();
  }, []);
  const getalladmin = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getalladmin", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
      setOriginalRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? If you want to delete this");
    setUserIdToDelete(id);
  };
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleEdit = (row) => {
    setshowModaladd(true);
    setCurrentRecord(row);
    setcheck(row.status);
    if (row.status === "Admin") {
      setcheckstatus(true);
    } else {
      setcheckstatus(false);
    }
    if (row.roles && row.roles.length > 0) {
      const roles = JSON.parse(row.roles);
      setSelectedRoles(roles); // Set selected roles correctly
    } else {
      setSelectedRoles([]); // Reset roles if none are provided
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setshowModaladd(false);
    setshowModaljoinedgroup(false);
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deleteadmin", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getalladmin();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Define filtered data based on the search query
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };
  const handleRoleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Add the value to the array if checked
      setSelectedRoles((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setSelectedRoles((prev) =>
        prev.filter((orientation) => orientation !== value)
      );
    }

    // Optional: Debugging to see the updated selectedRoles
    console.log("Selected Roles after change:", selectedRoles);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var valroles = [];
    console.log(checkk);
    console.log(selectedRoles);
    if (checkk === "Admin") {
      if (selectedRoles.length === 0) {
        seterrrec("Please choose at least one role.");
        return;
      } else {
        seterrrec("");
      }
    } else {
      seterrrec("");
    }

    if (
      !currentRecord.viewpassword ||
      !isValidPassword(currentRecord.viewpassword)
    ) {
      seterr_pass(
        "Password must be at least 8 characters long and include uppercase letters, lowercase letters, digits, and special characters."
      );
      return; // Set isValid to false if password is missing or invalid
    } else {
      seterr_pass("");
    }

    let formData = {
      name: currentRecord.name,
      email: currentRecord.email,
      password: currentRecord.viewpassword,
      status: checkk,
      id: currentRecord.id,
      roles: JSON.stringify(selectedRoles),
    };

    try {
      const res = await axios.post(apiUrl + "admincreate", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var ch = res.data;

      if (ch.status === "2") {
        setinsertmessage(false);
        setinsertmessageerr(true);
        setTimeout(() => {
          setinsertmessageerr(false);
        }, 2500);
      } else {
        if (currentRecord.id) {
          setmesg("Admin updated successfully");
        } else {
          setmesg("Admin created successfully");
        }
        setinsertmessageerr(false);
        getalladmin();
        setinsertmessage(true);
        setTimeout(() => {
          setinsertmessage(false);
          setshowModaladd(false);
        }, 2500);
        setCurrentRecord("");
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleaddAdmin = () => {
    setshowModaladd(true);
  };
  const isValidPassword = (password) => {
    // Check minimum length and strong password criteria
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasDigits &&
      hasSpecialChar
    );
  };
  const handleChangeSelect = (e) => {
    if (e.target.value !== "") {
      setcheck(e.target.value);
      if (e.target.value === "SuperAdmin") {
        setcheckstatus(false);
      } else {
        setcheckstatus(true);
      }
    } else {
      setcheckstatus(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="d-flex text-align-end mb-2">
                    <button
                      type="button"
                      onClick={() => handleaddAdmin()}
                      className="btn btn-primary ms-auto"
                    >
                      Add <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Admin List</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModaladd}
        className="edit_admin"
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {insertmessage && <span className="text-success">{mesg}</span>}
          {insertmessageerr && (
            <span className="text-danger">Email already exists</span>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input type="hidden" name="id" value={currentRecord.id} />
              <input
                type="text"
                className="form-control"
                id="name"
                required
                name="name"
                defaultValue={currentRecord.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                required
                name="email"
                defaultValue={currentRecord.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                required
                name="viewpassword"
                defaultValue={currentRecord.viewpassword}
                onChange={handleInputChange}
              />
              <span className="text-danger" style={{ fontSize: "14px" }}>
                {err_pass}
              </span>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Status <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                defaultValue={currentRecord.status}
                required
                onChange={(e) => handleChangeSelect(e)}
                name="status"
              >
                <option value="">--Select--</option>
                <option value="SuperAdmin">SuperAdmin</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            {checkstatus && (
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role <span className="text-danger">*</span>
                </label>
                <span className="text-danger">{errrec}</span>
                {[
                  "Users",
                  "Media",
                  "Groups",
                  "Forum",
                  "Payment History",
                  "Security Privacy",
                ].map((role) => (
                  <div className="form-check me-3" key={role}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="role"
                      value={role}
                      id={role}
                      checked={selectedRoles.includes(role)} // Ensuring that the checkbox reflects the selected state
                      onChange={handleRoleChange} // Handling the checkbox change
                    />
                    <label className="form-check-label" htmlFor={role}>
                      {role}
                    </label>
                  </div>
                ))}
              </div>
            )}

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="bg-success border-color-none"
              >
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
