import React, { useEffect, useState, useRef } from "react";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import { useNavigate } from "react-router-dom";
import userImage1 from "../../assets/admin/img/user.jpg";
import Chart from "chart.js/auto"; // Import Chart.js
import NavAdmin from "../../components/Admin/NavAdmin";
import axios from "axios";
import moment from "moment";
export default function Dashboard() {
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]); // Full list of records

  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [totalusers, settotalusers] = useState([]);
  const [totalgroups, settotalgroups] = useState([]);
  const [totalevents, settotalevents] = useState([]);
  const [totalforum, settotalforum] = useState([]);
  const [totalspeeddate, settotalspeeddate] = useState([]);
  const [SaleRevenuelable, setSaleRevenuelable] = useState([]);
  const [Saledata, setSale] = useState([]);
  const [Revenuedata, setRevenue] = useState([]);
  const [recentSale, setrecentSale] = useState([]);
  const [recentMessage, setrecentMessage] = useState([]);
  const [LatestUsers, setLatestUsers] = useState([]);
  const [saleRevenuelableyear, setSaleRevenuelableyear] = useState([]);
  const [saleYear, setSaleYear] = useState([]);
  const [revenueYear, setRevenueYear] = useState([]);
  const [genderper, setgenderper] = useState([]);
  const [activeUsers, setactiveUsers] = useState("");
  const [churnrate, setchurnrate] = useState("");
  const [lostuser, setlostuser] = useState("");
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  const canvasRef3 = useRef(null);
  const canvasRef4 = useRef(null);
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    const gettotalSaleRevenue = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalSaleRevenue", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;

        setSaleRevenuelable(respo.labels);
        if (respo.datasets.length > 0) {
          var datasetsale = respo.datasets[0];
          var datasetrev = respo.datasets[1];
          console.log(datasetsale.data);
          setSale(datasetsale.data);
          setRevenue(datasetrev.data);
        }
      } catch (err) {
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalSaleRevenue();
  }, []);
  useEffect(() => {
    const gettotalSaleRevenueYearly = async () => {
      let formData = {};
      try {
        const res = await axios.post(
          apiUrl + "gettotalSaleRevenueYearly",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          }
        );
        var respo = res.data.result;
        console.log("klklkkkk");
        console.log(respo);
        setSaleRevenuelableyear(respo.labels);
        if (respo.datasets.length > 0) {
          var datasetsale = respo.datasets[0];
          var datasetrev = respo.datasets[1];
          console.log(datasetsale.data);
          setSaleYear(datasetsale.data);
          setRevenueYear(datasetrev.data);
        }
      } catch (err) {
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalSaleRevenueYearly();
  }, []);
  useEffect(() => {
    const script66 = document.createElement("script");
    script66.src = "https://cdn.jsdelivr.net/npm/chart.js";
    script66.async = true;
    document.body.appendChild(script66);

    script66.onload = () => {
      const ctx1 = canvasRef.current.getContext("2d");
      const ctx2 = canvasRef2.current.getContext("2d");
      const ctx3 = canvasRef3.current.getContext("2d");

      // Optionally, destroy the existing chart if needed
      if (window.myChart1) {
        window.myChart1.destroy();
      }
      if (window.myChart2) {
        window.myChart2.destroy();
      }
      if (window.myChart3) {
        window.myChart3.destroy();
      }

      // Create the new chart for canvasRef (myChart1)
      window.myChart1 = new Chart(ctx1, {
        type: "bar",
        data: {
          labels: saleRevenuelableyear,
          datasets: [
            {
              label: "Sale",
              data: saleYear,
              backgroundColor: "rgba(0, 156, 255, .7)",
            },
            {
              label: "Revenue",
              data: revenueYear,
              backgroundColor: "rgba(0, 156, 255, .5)",
            },
          ],
        },
        options: {
          responsive: true,
        },
      });

      // Create the new chart for canvasRef2 (myChart2)
      window.myChart2 = new Chart(ctx2, {
        type: "bar",
        data: {
          labels: SaleRevenuelable,
          datasets: [
            {
              label: "Sale",
              data: Saledata,
              backgroundColor: "rgba(0, 156, 255, .7)",
            },
            {
              label: "Revenue",
              data: Revenuedata,
              backgroundColor: "rgba(0, 156, 255, .5)",
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
      window.myChart3 = new Chart(ctx3, {
        type: "doughnut",
        data: {
          labels: ["Male", "Female", "Others"], // Modify these labels as needed
          datasets: [
            {
              label: "Total",
              data: genderper, // Modify these values as needed
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    };

    return () => {
      document.body.removeChild(script66);
      // Clean up and destroy charts on component unmount
      if (window.myChart1) {
        window.myChart1.destroy();
      }
      if (window.myChart2) {
        window.myChart2.destroy();
      }
      if (window.myChart3) {
        window.myChart3.destroy();
      }
    };
  }, [Saledata, Revenuedata, genderper]);

  useEffect(() => {
    const gettotalusers = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getallusers", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;

        settotalusers(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalusers();
  }, []);
  useEffect(() => {
    const gettotalgender = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalgender", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        console.log("gender");
        setgenderper(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalgender();
  }, []);
  useEffect(() => {
    const gettotalgroups = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalgroups", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalgroups(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalgroups();
  }, []);
  useEffect(() => {
    const gettotalevents = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalevents", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalevents(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalevents();
  }, []);
  useEffect(() => {
    const gettotalforum = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalforum", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalforum(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalforum();
  }, []);
  useEffect(() => {
    const gettotalspeeddate = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "gettotalspeeddate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        settotalspeeddate(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    gettotalspeeddate();
  }, []);
  useEffect(() => {
    const getrecentSale = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getrecentSale", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;

        setrecentSale(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getrecentSale();
  }, []);
  useEffect(() => {
    const getrecentMessage = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getrecentMessage", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        setrecentMessage(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getrecentMessage();
  }, []);
  useEffect(() => {
    const getLatestUsers = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getLatestUsers", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        setLatestUsers(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getLatestUsers();
  }, []);
  useEffect(() => {
    const getactiveUsers = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getactiveUsers", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        //setactiveUsers(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getactiveUsers();
  }, []);
  useEffect(() => {
    const getchurnrate = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getchurnrate", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data;
        console.log(respo);
        console.log("churn");
        setactiveUsers(respo.activeuser);
        setchurnrate(respo.percentage);
        setlostuser(respo.lostuser);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getchurnrate();
  }, []);
  const formateTime = (dateString) => {
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const messageStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1, // Limits to two lines
    WebkitBoxOrient: "vertical",
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4 speed_txt">
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users fa-3x text-primary"></i>
                  <div className="ms-3">
                    <p className="mb-2">Total Users</p>
                    <h6 className="mb-0">{totalusers.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users-cog fa-3x text-primary"></i>
                  <div className="ms-3">
                    <p className="mb-2">Total Groups</p>
                    <h6 className="mb-0">{totalgroups.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-calendar-alt fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Events</p>
                    <h6 className="mb-0">{totalevents.length}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-comments fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Forum</p>
                    <h6 className="mb-0">{totalforum.length}</h6>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-heart fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Total Speed date</p>
                    <h6 className="mb-0">{totalspeeddate.length}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Active Users</p>
                    <h6 className="mb-0">{activeUsers}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-chart-line fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Churn Rates</p>
                    <h6 className="mb-0">{churnrate}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                  <i className="fa fa-users fa-3x text-primary"></i>{" "}
                  {/* Changed icon here */}
                  <div className="ms-3">
                    <p className="mb-2">Lost Users</p>
                    <h6 className="mb-0">{lostuser}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                      }).format(new Date())}{" "}
                      Sales
                    </h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef} id="worldwide-sales"></canvas>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Sale & Revenue</h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef2} id="sales-revenue"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-xl-6">
                <div className="bg-light text-center rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Gender</h6>
                    {/* <a href="">Show All</a> */}
                  </div>
                  <canvas ref={canvasRef3} id="doughnut-chart"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4 px-4">
            <div className="bg-light text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0">Recent Sale</h6>
                {/* <a href="">Show All</a> */}
              </div>
              <div className="table-responsive">
                <table className="table text-start align-middle table-bordered table-hover mb-0">
                  <thead>
                    <tr className="text-dark">
                      <th scope="col">Date</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentSale.length > 0 ? (
                      recentSale.map((sale, index) => (
                        <tr key={index}>
                          <td>{moment(sale.date).format("MMM DD, YYYY")}</td>
                          <td>{sale.payment_id}</td>
                          <td>{sale.username}</td>
                          <td>{sale.amount}</td>
                          <td>{sale.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No result found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-sm-12 col-md-6 col-xl-6">
                <div className="h-100 bg-light rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="mb-0">Messages</h6>
                  </div>
                  {recentMessage.length > 0 ? (
                    recentMessage.map((message, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center border-bottom py-3"
                      >
                        <img
                          className="rounded-circle flex-shrink-0"
                          src={message.profile_image}
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                        />
                        <div className="w-100 ms-3">
                          <div className="d-flex w-100 justify-content-between">
                            <h6 className="mb-0">{message.username}</h6>
                            <small>{formateTime(message.date)}</small>
                          </div>
                          <span style={messageStyle}>{message.message}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex align-items-center border-bottom py-3">
                      <p>No result found</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-xl-6">
                <div className="h-100 bg-light rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="mb-0">Latest Users</h6>
                  </div>
                  <div className="table-responsive">
                    <table className="table text-start align-middle table-bordered table-hover mb-0">
                      <thead>
                        <tr className="text-dark">
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">IP Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LatestUsers.length > 0 ? (
                          LatestUsers.map((sale, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  className="rounded-circle flex-shrink-0"
                                  src={sale.profile_image}
                                  alt="Image"
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </td>
                              <td>{sale.username}</td>
                              <td>{sale.email}</td>
                              <td>{sale.IpAddress}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No result found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4 px-4">
            <div className="bg-light rounded-top p-4">
              <div className="row">
                <div className="col-12 col-sm-6 text-center text-sm-start">
                  &copy; <a href="#">Amourette</a>, All Right Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
