import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function Logout() {
  const navigate = useNavigate();

  var apiURL = "https://backend.amourette.no/api/";
  useEffect(() => {
    // Check if the username key exists in session storage
    const logoutapi = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      if (storedUsername) {
        const userLogin = JSON.parse(storedUsername);
        const formData = { user_id: userLogin.id };

        try {
          const res = await axios.post(apiURL + "logoutapi", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          localStorage.removeItem("userLogin");
          sessionStorage.removeItem("userLogin");
          localStorage.removeItem("userLogin");
          navigate("/login");
        } catch (err) {
          console.error(
            "Error setting user online:",
            err.response ? err.response.data : err.message
          );
        }
      }
    };
    logoutapi();
  }, []);

  return <></>;
}

export default Logout;
