import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import Headertwo from "../components/Headertwo";
import evenphoto from "../assets/images/pic.png";
import iconphoto from "../assets/images/me3.png";
import SuccessPop from "../components/SuccessPop";
import heart from "../assets/images/sheart.png";
import postpic from "../assets/images/pic.png";
import mempic from "../assets/images/pic.png";
import icon1 from "../assets/images/det1.png";
import icon2 from "../assets/images/det2.png";
import icon3 from "../assets/images/det3.png";
import axios from "axios";
import { InviteEventsFriend } from "../components/InviteEventsFriend";
import { AlertDelete } from "../components/AlertDelete";
import { AlertSucess } from "../components/AlertSucess";
import { AlertError } from "../components/AlertError";
import ErrorPop from "../components/ErrorPop";
import { format, parseISO } from "date-fns";
import { Createneweventpost } from "../components/Createneweventpost";
import { EventPostComment } from "../components/EventPostComment";
export default function Singleevent() {
  const navigate = useNavigate();
  const [allevents, setallevents] = useState([]);
  const [eventId, seteventId] = useState("");
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpen_error, setisPopupOpen_error] = useState(false);
  const [isPopupOpen_sucess, setisPopupOpen_sucess] = useState(false);

  const [isPopupOpen_delete, setisPopupOpen_delete] = useState(false);
  const [allyoureventsUser, setallyoureventsUser] = useState([]);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [LoginData, setLoginData] = useState("");
  const [EventDetail, setEventDetail] = useState("");
  const [showPopError, setShowPopError] = useState(false);
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [deleteMessage, setdeleteMessage] = useState("");

  const [EventDetailIntersted, setEventDetailIntersted] = useState([]);
  const [showhide, setshowhide] = useState(false);

  const [isInterested, setIsInterested] = useState(false);

  const [isPopupOpen_newpost, setIsPopupOpen_newpost] = useState(false);
  const [eventAcceptorNo, seteventAcceptorNo] = useState(false);
  const [eventAccepted, seteventAccepted] = useState(false);
  const [eventAcceptorNoCheck, seteventAcceptorNoCheck] = useState(false);
  const [isEventpost, setEventpost] = useState([]);
  const [allEventsInterstedUser, setallEventsInterstedUser] = useState([]);
  const [eventView, seteventView] = useState(false);
  var apiUrl = "https://backend.amourette.no/api/";
  const { slug } = useParams();

  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    setLoginData(userLogin);
    if (slug === undefined) {
      seteventId("");
    } else {
      getDetails(slug);
      UsercheckAccept();
    }
  }, [eventId]);

  const UsercheckAccept = async () => {
    let formData = {
      user_id: LoginData.id,
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "UsercheckAccept", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log("ch");
      console.log(respo);
      if (respo.length === 0) {
        seteventView(false);
        seteventAcceptorNo(false);
        navigate("/events");
      } else {
        if (respo[0].event_status === "Created by You") {
          seteventView(true);
          seteventAcceptorNo(false);
          seteventAcceptorNoCheck(false);
        }
        if (respo[0].invite_status === "No") {
          seteventView(false);
          seteventAcceptorNo(true);
        }
        if (respo[0].invite_status === "Yes") {
          seteventView(true);
          seteventAccepted(false);
          seteventAcceptorNoCheck(true);
        }
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getDetails = async (slug) => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "get_EventDetailSlug", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.event;
      seteventId(respo.id);
      getEventdetail(respo.id);

      getEventdetailallIntersted(respo.id);
      get_EventIntersted(respo.id);
      getpostComment(respo.id);
      getEventInterstedUser();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const getEventInterstedUser = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      user_id: userLogin.id,
      event_id: eventId,
    };

    try {
      const res = await axios.post(apiUrl + "getEventInterstedUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(res.data.results);
      console.log("hhh");
      setallEventsInterstedUser(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getallYoureventsUser = async (id) => {
    let formData = {
      user_id: LoginData.id,
      event_id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getallYoureventsUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      setallyoureventsUser(res.data.events);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const getEventdetail = async () => {
    let formData = {
      event_id: eventId,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "get_EventDetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      if (res.data.event === "" || res.data.event === undefined) {
        navigate("/events");
      } else {
        if (LoginData && LoginData.id === res.data.event.user_id) {
          setshowhide(true);
        } else {
          setshowhide(false);
        }
      }
      setEventDetail(res.data.event);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Response error:", err.response);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const getEventdetailallIntersted = async (idd) => {
    let formData = {
      event_id: idd,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(
        apiUrl + "get_EventdetailAllIntersted",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      console.log("hh");
      console.log(res.data.results);
      setEventDetailIntersted(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const get_EventIntersted = async (idd) => {
    let formData = {
      event_id: idd,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "get_EventIntersted", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      if (res.data.status === "1") {
        setIsInterested(true); // User is now interested
      } else if (res.data.status === "2") {
        setIsInterested(false); // User is no longer interested
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const openPopup1 = (id) => {
    seteventId(id);
    getallYoureventsUser(id);
    setIsPopupOpen1(true);
    document.body.style.overflowY = "hidden";
  };
  const closePopup1 = () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };

  const open_DeleteAlert = (id) => {
    setisPopupOpen_delete(true);
    setdeleteMessage("Are you sure? You want delete this event");
  };
  const closePopup_delete = () => {
    setisPopupOpen_delete(false);
  };
  const closePopup_error = () => {
    setisPopupOpen_error(false);
  };
  const closePopup_sucess = () => {
    setisPopupOpen_sucess(false);
  };
  const refreshFriendsList = () => {
    getallYoureventsUser(eventId);
  };

  const openPopup_newpost = () => {
    setIsPopupOpen_newpost(true);
    document.body.style.overflowY = "hidden";
  };
  const closePopup_newpost = () => {
    setIsPopupOpen_newpost(false);
    document.body.style.overflowY = "auto";
  };

  const deleteEvent = async () => {
    let deleteev = {
      user_id: LoginData.id,
      event_id: eventId,
    };
    console.log(deleteev);
    try {
      const res = await axios.post(apiUrl + "userDeleteEvent", deleteev, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      navigate("/events");
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const refreshpopup = async () => {
    deleteEvent();
  };
  const formatDateRange = (startDate, endDate) => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    const startDay = format(start, "d");
    const endDay = format(end, "d");
    const month = format(start, "MMM");

    return `${startDay} ${month} - ${endDay} ${month}`;
  };

  const handleInterested = async () => {
    let formData = {
      event_id: eventId,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "userEventIntersted", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("hh");
      getEventdetailallIntersted(eventId);
      if (res.data.status === "1") {
        setIsInterested(true); // User is now interested
      } else if (res.data.status === "2") {
        setIsInterested(false); // User is no longer interested
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getpostComment = async (idd) => {
    let formData = {
      event_id: idd,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "get_postComment", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("res.data");
      setEventpost(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const refreshPostComment = () => {
    getpostComment(eventId);
    setIsPopupOpen_newpost(false);
  };
  const formateTime = (dateString) => {
    if (!dateString) return null;

    const postDate = new Date(dateString);
    const now = new Date();

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((now - postDate) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const Iseventfav = async () => {
    getpostComment(eventId);
  };
  const commentSectionRefresh = async () => {
    getpostComment(eventId);
  };
  const handledeleteSentto = async () => {
    let deleteev = {
      user_id: LoginData.id,
      event_id: eventId,
    };

    try {
      const res = await axios.post(apiUrl + "DeleteInviteRequest", deleteev, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setShowPopError(true);
      setPoperrorMessage("Successfully deleted");
      navigate("/events");
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleAccept = async () => {
    let eventAccepted = {
      user_id: LoginData.id,
      event_id: eventId,
    };

    try {
      const res = await axios.post(apiUrl + "eventAccepted", eventAccepted, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      setShowPopSuccess(true);
      setPopsuccessMessage("Event invite updated successfully");
      UsercheckAccept();
      setTimeout(() => {
        setShowPopSuccess(false);
        window.location.reload();
      }, 800);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  return (
    <>
      <Headertwo />
      {showPopError && <ErrorPop message={errorPopMessage} />}
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      <section className="singleevent d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-7">
              <div className="d-flex flex-column gap-4">
                <div className="eventtitle d-block pb-1">
                  <h1>{EventDetail.name}</h1>
                </div>
                <div className="d-flex flex-column gap-2">
                  {showhide && (
                    <div className="evname d-block">
                      <div className="d-flex flex-column gap-2">
                        <div
                          className="d-flex gap-2 align-items-center"
                          onClick={openPopup_newpost}
                        >
                          <div className="flex-shrink-0">
                            <div className="evephoto ofit rounded-circle overflow-hidden">
                              <img src={EventDetail.image} alt="evepic" />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div className="whatthink d-block">
                              <h4>What do you think?</h4>
                            </div>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-end addphotothink"
                          onClick={openPopup_newpost}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                              <h4>Add photo</h4>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="iconphoto fulw">
                                <img src={iconphoto} alt="namepic" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {eventView ? (
                    <EventPostComment
                      isEventpost={isEventpost}
                      LoginData={LoginData}
                      Iseventfav={Iseventfav}
                      commentSectionRefresh={commentSectionRefresh}
                    />
                  ) : (
                    <p>No event view available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between posttopbtns">
                  {eventAcceptorNo && (
                    <button
                      type="button"
                      onClick={handleAccept}
                      className={`btn prbtn ${isInterested ? "" : ""}`}
                    >
                      Accept
                    </button>
                  )}
                  {eventAcceptorNoCheck && (
                    <button
                      type="button"
                      className={`btn prbtn ${
                        isInterested ? "interested_btn" : ""
                      }`}
                    >
                      Accepted
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-between posttopbtns">
                  {!showhide && (
                    <button
                      type="button"
                      onClick={handleInterested}
                      className={`btn prbtn ${
                        isInterested ? "interested_btn" : ""
                      }`}
                    >
                      INTERESTED
                    </button>
                  )}
                  {eventAcceptorNo && (
                    <button
                      type="button"
                      onClick={handledeleteSentto}
                      className={`btn redbtn ${isInterested ? "" : ""}`}
                    >
                      Delete
                    </button>
                  )}

                  {showhide && (
                    <button
                      type="button"
                      onClick={() => openPopup1(eventId)}
                      className="btn sebtn"
                    >
                      INVITE A FRIEND
                    </button>
                  )}
                </div>
                {showhide && (
                  <div className="d-flex justify-content-between posttopbtns">
                    <button type="button" className="btn prbtn">
                      SAVE CHANCES
                    </button>
                    <button
                      type="button"
                      onClick={() => open_DeleteAlert(eventId)}
                      className="btn redbtn"
                    >
                      DELETE EVENT
                    </button>
                  </div>
                )}
                <div className="detailbox d-flex flex-column gap-2">
                  <h3>Details</h3>
                  <p>{EventDetail.description}</p>
                  <div className="d-flex flex-column gap-2 mt-2">
                    <div className="d-flex gap-3 align-items-center deticontext ">
                      <div className="flex-shrink-0">
                        <div className="pubimg fulw">
                          <img src={icon1} alt="pubimg" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5>
                          {EventDetail.makeImagePrivate === 0
                            ? "Public"
                            : "Private"}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex gap-3 align-items-center deticontext ">
                      <div className="flex-shrink-0">
                        <div className="pubimg fulw">
                          <img src={icon2} alt="pubimg" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5>{EventDetail.location}</h5>
                      </div>
                    </div>
                    <div className="d-flex gap-3 align-items-center deticontext ">
                      <div className="flex-shrink-0">
                        <div className="pubimg fulw">
                          <img src={icon3} alt="pubimg" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5>
                          {EventDetail.time} and{" "}
                          {EventDetail.start_date && EventDetail.end_date ? (
                            formatDateRange(
                              EventDetail.start_date,
                              EventDetail.end_date
                            )
                          ) : (
                            <span>Invalid date range</span>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailbox d-flex flex-column gap-2">
                  <h3>
                    Members interested{" "}
                    <span>{EventDetailIntersted.length}</span>
                  </h3>
                  <div className="row g-2 g-md-4">
                    {EventDetailIntersted && EventDetailIntersted.length > 0 ? (
                      EventDetailIntersted.map((member, index) => (
                        <div className="col-2 col-md-3" key={index}>
                          <div className="memperson ofit overflow-hidden rounded-3">
                            {member.profile_image ? ( // Display the image URL if available
                              <img
                                className="w-100 h-100"
                                src={member.profile_image} // Use the URL created from the file
                                alt="Selected"
                              />
                            ) : (
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                alt="Profile"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12">
                        <p>No results found</p>
                      </div>
                    )}
                  </div>
                  {/* <div className="d-block vmore">
                    <Link to="/">View More</Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <InviteEventsFriend
        isOpen1={isPopupOpen1}
        onClose1={closePopup1}
        friends={allyoureventsUser}
        LoginData={LoginData}
        eventId={eventId}
        onInvitesSent={refreshFriendsList}
      ></InviteEventsFriend>
      <AlertDelete
        isOpen1={isPopupOpen_delete}
        onClose1={closePopup_delete}
        message={deleteMessage}
        onReturnSent={refreshpopup}
      ></AlertDelete>
      <AlertError
        isOpen1={isPopupOpen_error}
        onClose1={closePopup_error}
      ></AlertError>
      <AlertSucess
        isOpen1={isPopupOpen_sucess}
        onClose1={closePopup_sucess}
      ></AlertSucess>
      <Createneweventpost
        isOpen={isPopupOpen_newpost}
        onClose={closePopup_newpost}
        eventId={eventId}
        LoginData={LoginData}
        reSetPost={refreshPostComment}
      ></Createneweventpost>
    </>
  );
}
