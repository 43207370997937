import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Importing the image
import userImage from "../assets/images/user_img.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

export default function AdminBar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(window.location.pathname); // Initialize with current path
  const [showSubmenu, setShowSubmenu] = useState(false); // State to toggle submenu visibility
  const [showDropdown, setShowDropdown] = useState(false); // Set to true for default open
  const navigate = useNavigate();
  useEffect(() => {
      // Check if the username key exists in session storage
      const storedUsername = localStorage.getItem("adminLogin");
      const userLogin = JSON.parse(storedUsername);
      console.log(userLogin);
      if (userLogin === null) {
        navigate("/admin/login");
      }
    }, []);
  useEffect(() => {
    // Automatically open the dropdown if we're on /admin/media
    if (
      location.pathname === "/admin/media" ||
      location.pathname === "/admin/groups" ||
      location.pathname === "/admin/forum" ||
      location.pathname === "/admin/messaging"
    ) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [location.pathname]);
  const handleNavClick = (link) => {
    setActiveLink(link);
  };
  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu); // Toggle submenu visibility
  };
  const [isStyled, SetisStyled] = useState(false);

  const OpenBox = () => {
    SetisStyled(!isStyled);
  };
  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };
  const handlelogout = () => {
    sessionStorage.removeItem("adminLogin");
    localStorage.removeItem("adminLogin");
    console.log("User logged out");
  
    // Slight delay to ensure local/session storage clears before navigating
    setTimeout(() => {
      navigate("/admin/login"); // Redirect after clearing storage
    }, 10);
  };
  return (
    <>
      <div
        className="nav_openres"
        style={{
          opacity: isStyled ? "0" : "1",
          zIndex: isStyled ? "1" : "9998",
          transition: "all 0.3s ease",
        }}
      >
        <button onClick={OpenBox}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div
        className="nav_closeres"
        style={{
          left: isStyled ? "245px" : "150px",
          opacity: isStyled ? "1" : "0",
          zIndex: isStyled ? "9998" : "1",
          transition: "all 0.3s ease",
        }}
      >
        <button onClick={OpenBox}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div
        className="d-block admin_bar"
        style={{
          width: isStyled ? "300px" : "",
          height: isStyled ? "100vh" : "",
          opacity: isStyled ? "1" : "",
          zIndex: isStyled ? "98" : "",
          transition: "all 0.3s ease",
        }}
      >
        <div className="d-flex flex-column gap-4">
          <div className="d-flex align-items-center gap-3 admin-top">
            <div className="d-block admin_profile">
              <img
                className="w-100 h-100"
                src={userImage}
                alt="Admin Profile"
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <h6>Admin</h6>
            </div>
          </div>
          <div className="d-flex flex-column gap-3 admin_nav">
            <Nav className="w-100 d-flex flex-column gap-2">
              <Nav.Link
                className={location.pathname === "/admin/users" ? "active" : ""}
                as={Link}
                to="/admin/users"
                onClick={() => handleNavClick("/users")}
                aria-label="User Management"
              >
                User Management
              </Nav.Link>
              <Dropdown
                autoClose={false}
                show={showDropdown}
                onToggle={handleToggle}
              >
                <Dropdown.Toggle
                  variant="link"
                  className={
                    location.pathname === "/admin/media" ||
                    location.pathname === "/admin/groups" ||
                    location.pathname === "/admin/forum" ||
                    location.pathname === "/admin/messaging"
                      ? "active"
                      : ""
                  }
                  id="dropdown-custom-components"
                >
                  Content Management
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to="/admin/media"
                    className={
                      location.pathname === "/admin/media"
                        ? "active submenuadmin"
                        : "submenuadmin"
                    }
                  >
                    Media
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/admin/groups"
                    className={
                      location.pathname === "/admin/groups"
                        ? "active submenuadmin"
                        : "submenuadmin"
                    }
                  >
                    Groups
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/admin/forum"
                    className={
                      location.pathname === "/admin/forum"
                        ? "active submenuadmin"
                        : "submenuadmin"
                    }
                  >
                    Forums
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/admin/messaging"
                    className={
                      location.pathname === "/admin/messaging"
                        ? "active submenuadmin"
                        : "submenuadmin"
                    }
                  >
                    Messaging & Communication
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link
                className={
                  activeLink === "/admin/paymentsubscription" ? "active" : ""
                }
                as={Link}
                to="/admin/paymentsubscription"
                onClick={() => handleNavClick("/paymentsubscription")}
                aria-label="Payment & Subscription"
              >
                Payment & Subscription
              </Nav.Link>
              <Nav.Link
                className={
                  activeLink === "/admin/securityprivacy" ? "active" : ""
                }
                as={Link}
                to="/admin/securityprivacy"
                onClick={() => handleNavClick("/securityprivacy")}
                aria-label="Security & Privacy"
              >
                Security & Privacy
              </Nav.Link>
              <Nav.Link
                className={
                  activeLink === "/moderationguidelines" ? "active" : ""
                }
                as={Link}
                to="/moderationguidelines"
                onClick={() => handleNavClick("/moderationguidelines")}
                aria-label="Content Moderation & Guidelines"
              >
                Content Moderation & Guidelines
              </Nav.Link>

              <Nav.Link
                className={activeLink === "/backup" ? "active" : ""}
                as={Link}
                to="/backup"
                onClick={() => handleNavClick("/backup")}
                aria-label="Backup & Site Recovery"
              >
                Backup & Site Recovery
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/legal" ? "active" : ""}
                as={Link}
                to="/legal"
                onClick={() => handleNavClick("/legal")}
                aria-label="Legal & Compliance"
              >
                Legal & Compliance
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/performance" ? "active" : ""}
                as={Link}
                to="/performance"
                onClick={() => handleNavClick("/performance")}
                aria-label="Performance Monitoring"
              >
                Performance Monitoring
              </Nav.Link>
              <Nav.Link
                className={activeLink === "/performance" ? "active" : ""}
                as={Link}
                to="#" onClick={() => handlelogout()}
                aria-label="Logout"
              >
                Logout
              </Nav.Link>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
}
