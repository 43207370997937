import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import {
  faEye,
  faPaperPlane,
  faEnvelope,
  faThumbsUp,
  faStar,
  faComment,
  faFile,
} from "@fortawesome/free-regular-svg-icons";
import { faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
export default function ViewgroupPost() {
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const { slug } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [currentRecord, setCurrentRecord] = useState("");
  const [messageshow, setmessageshow] = useState(false);
  const [groupData, setgroupData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModalComment, setShowModalComment] = useState(false);
  const [groupsComments, setgroupsComments] = useState([]);
  const [showModalLike, setShowModalLike] = useState(false);
  const [groupLikes, setgroupLikes] = useState([]);
  const [alertpopup, setalertpopup] = useState(false);
  const [alertpopupcomment, setalertpopupcomment] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [IdToDelete, setIdToDelete] = useState("");
  const [commmentId, setcommmentId] = useState("");
  const [alertpopuplike, setalertpopuplike] = useState(false);
  const [likeId, setlikeId] = useState("");
  const [showModalMedia, setShowModalMedia] = useState(false);
  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  useEffect(() => {
    getgroupData();
  }, []);
  const getgroupData = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getgroupData", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      const respo = res.data.results; // Default to an empty array if the result is undefined or null

      setgroupData(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    getgroupPostData();
  }, []);
  const getgroupPostData = async () => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "getgroupPostData", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      const respo = res.data.results || []; // Default to an empty array if the result is undefined or null
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: "Total Like",
      selector: (row) => row.total_likes,
      sortable: true,
      className: "age",
    },
    {
      name: "Total Comments",
      selector: (row) => row.total_comments,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <a
            className="dataedit_btn text-success"
            title="View Media"
            style={{ fontSize: "16px" }}
            onClick={() => handlemedia(row)}
          >
            <FontAwesomeIcon icon={faFile} />
          </a>
          <a
            onClick={() => handleComment(row.id)}
            className="dataedit_btn text-primary"
            title="View Comments"
            style={{ fontSize: "14px" }}
          >
            <FontAwesomeIcon icon={faComment} />
          </a>
          <a
            onClick={() => handleLike(row.id)}
            className="dataedit_btn text-primary"
            title="View Like"
            style={{ fontSize: "14px" }}
          >
            <FontAwesomeIcon icon={faStar} />
          </a>
          <a
            onClick={() => handledelete(row.post_id)}
            className="dataedit_btn text-danger"
            title="Delete Post"
            style={{ fontSize: "14px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handledelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? You want to delete this post");
    setIdToDelete(id);
  };
  const handlemedia = (row) => {
    setShowModalMedia(true);
    setCurrentRecord(row.image);
  };
  const filteredRecords = records.filter((record) =>
    Object.values(record)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const handleComment = async (id) => {
    setShowModalComment(true);
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getallgroupsComments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      setgroupsComments(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleModalCloseComment = () => {
    setShowModalComment(false);
    setShowModalLike(false);
  };
  const handleLike = async (id) => {
    setShowModalLike(true);
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getallgroupLikes", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      setgroupLikes(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: IdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deletegrouppost", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getgroupPostData();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const handledeletecomment = async (id) => {
    setcommmentId(id);
    setShowModalComment(false);
    setalertpopupcomment(true);
    setmessageset("Are you sure? You want to delete this comment");
  };
  const onReturnSentComment = async () => {
    setalertpopupcomment(false); // Close the popup
    setShowModalComment(true);
    setmessageshow(true);
    let formData = {
      id: commmentId,
    };
    try {
      const res = await axios.post(
        apiUrl + "deletegrouppostComment",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );

      handleComment(groupData.id);
      getgroupPostData();
      setTimeout(() => {
        setmessageshow(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const onReturnSentLike = async () => {
    setalertpopuplike(false); // Close the popup
    setShowModalLike(true);
    setmessageshow(true);
    let formData = {
      id: likeId,
    };
    try {
      const res = await axios.post(apiUrl + "deletegrouppostLike", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });

      handleLike(groupData.id);
      getgroupPostData();
      setTimeout(() => {
        setmessageshow(false);
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const setalertpopupcomments = async () => {
    setalertpopupcomment(false); // Close the popup
    setShowModalComment(true);
  };
  const setalertpopuplikes = async () => {
    setalertpopuplike(false); // Close the popup
    setShowModalLike(true);
  };
  const handledeletelike = async (id) => {
    setlikeId(id);
    setShowModalLike(false);
    setalertpopuplike(true);
    setmessageset("Are you sure? You want to delete this favourite");
  };
  const handleModalCloseMedia = () => {
    setShowModalMedia(false);
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {alertpopupcomment && (
            <AlertDelete
              isOpen1={alertpopupcomment}
              onClose1={() => setalertpopupcomments(false)}
              message={messageset}
              onReturnSent={onReturnSentComment}
            />
          )}
          {alertpopuplike && (
            <AlertDelete
              isOpen1={alertpopuplike}
              onClose1={() => setalertpopuplikes(false)}
              message={messageset}
              onReturnSent={onReturnSentLike}
            />
          )}
          <NavAdmin onClick={toggleNavAdmin} />

          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <div className="col-12">
                <div className="bg-light rounded h-100 p-4">
                  <a
                    href="/admin/groups"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                    className="mb-4"
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ marginRight: "8px" }}
                    />{" "}
                    Back
                  </a>
                  <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h6 className="mb-4">Group Post ({groupData.name})</h6>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredRecords}
                      pagination
                      highlightOnHover
                      noDataComponent="No records found."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalComment}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {messageshow && (
              <span className="text-success">Successfully deleted</span>
            )}
            <ul className="comment_userlist scroll_bar">
              {groupsComments.length === 0 ? (
                <li>No comments found</li>
              ) : (
                groupsComments.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4>{com.username}</h4>
                          <h5 style={{ fontSize: "12px" }}>
                            {formateTime(com.date)}
                          </h5>
                        </div>
                        <p className="scroll_bar">{com.description}</p>
                      </div>
                      <div className="d-flex align-items-end ml-auto">
                        <a
                          href="#"
                          onClick={() => handledeletecomment(com.id)}
                          className="text-danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalLike}
        className="edit_admin"
        onHide={handleModalCloseComment}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-white ">View Likes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ul className="comment_userlist scroll_bar">
              {groupLikes.length === 0 ? (
                <li>No likes found</li>
              ) : (
                groupLikes.map((com) => (
                  <li>
                    <div className="d-flex align-items-start gap-3">
                      <div className="d-block user_img">
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={com.profile_image}
                          alt="user_img"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4>{com.username}</h4>
                          <h5 style={{ fontSize: "12px" }}>
                            {formateTime(com.date)}
                          </h5>
                        </div>
                        <p className="scroll_bar">
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </p>
                      </div>
                      {/* <div className="d-flex align-items-end ml-auto">
                        <a
                          href="#"
                          onClick={() => handledeletelike(com.id)}
                          className="text-danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </div> */}
                    </div>
                  </li>
                ))
              )}
            </ul>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseComment}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalMedia}
        className="edit_admin "
        onHide={handleModalCloseMedia}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Post Media</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <form>
            <div className="group_upload mb-3">
              <img src={currentRecord} className="w-100 h-100" />
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCloseMedia}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
