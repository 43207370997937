import React, { useEffect, useState } from "react";

import axios from "axios";

import Headeradmin from "../../components/Admin/HeaderAdmin";
import NavAdmin from "../../components/Admin/NavAdmin";
import { useNavigate } from "react-router-dom";
import SuccessPop from "../../components/Admin/SuccessPop";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function Privacyupdate() {
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [contentabout, setContentabout] = useState("");
  const [contentprivacy, setContentprivacy] = useState("");
  const [contentcollection, setContentcollection] = useState("");
  const [contentcookies, setContentcookies] = useState("");
  const [contentsharing, setContentsharing] = useState("");
  const [contentdata, setContentdata] = useState("");
  const [contentcontact, setContentcontact] = useState("");
  const [contentsupport, setContentsupport] = useState("");
  const [contenttime, setContenttime] = useState("");
  const [contentright, setContentright] = useState("");
  const [contentsale, setContentsale] = useState("");
  const [contentpayment, setContentpayment] = useState("");
  const [contentmember, setContentmember] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  useEffect(() => {
    getprivacydetail();
  }, []);
  const getprivacydetail = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getprivacydetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      setContentabout(respo.about_us);
      setContentprivacy(respo.privacy_policy);
      setContentcollection(respo.information_collection);
      setContentcookies(respo.cookies_text);
      setContentsharing(respo.information_sharing);
      setContentdata(respo.data_security);
      setContentcontact(respo.contact_information);
      setContentsupport(respo.support_and);
      setContenttime(respo.delivery_time);
      setContentright(respo.right_of_withdrawal);
      setContentsale(respo.sale_to);
      setContentpayment(respo.payment_solution);
      setContentmember(respo.membership);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleChange = (value) => {
    setContent(value);
  };
  const handleChangeabout = (value) => {
    setContentabout(value);
  };
  const handleChangeprivacy = (value) => {
    setContentprivacy(value);
  };
  const handleChangecollection = (value) => {
    setContentcollection(value);
  };
  const handleChangecookies = (value) => {
    setContentcookies(value);
  };
  const handleChangesharing = (value) => {
    setContentsharing(value);
  };
  const handleChangedata = (value) => {
    setContentdata(value);
  };
  const handleChangecontact = (value) => {
    setContentcontact(value);
  };
  const handleChangesupport = (value) => {
    setContentsupport(value);
  };
  const handleChangetime = (value) => {
    setContenttime(value);
  };
  const handleChangeright = (value) => {
    setContentright(value);
  };
  const handleChangesale = (value) => {
    setContentsale(value);
  };
  const handleChangepayment = (value) => {
    setContentpayment(value);
  };
  const handleChangemember = (value) => {
    setContentmember(value);
  };
  const handleSubmit = async () => {
    let formdata = {
      about_us: contentabout,
      privacy_policy: contentprivacy,
      information_collection: contentcollection,
      cookies_text: contentcookies,
      information_sharing: contentsharing,
      data_security: contentdata,
      contact_information: contentcontact,
      support_and: contentsupport,
      delivery_time: contenttime,
      membership: contentmember,
      right_of_withdrawal: contentright,
      sale_to: contentsale,
      payment_solution: contentpayment,
    };
    try {
      const res = await axios.post(
        apiUrl + "privacyinformationSave",
        formdata,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.result;
      setShowPopSuccess(true);
      setPopsuccessMessage("Updated successfully");
      setTimeout(() => {
        setShowPopSuccess(false);
      }, 2500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavAdmin = () => {
    setIsOpen(!isOpen); // Toggle the state between true and false
  };
  return (
    <>
      <div class="position-relative bg-white d-flex p-0">
        <Headeradmin isOpen={isOpen} />
        <div className={`content ${isOpen ? "open" : ""}`}>
          <NavAdmin onClick={toggleNavAdmin} />
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
              <form
                onSubmit={handleSubmit}
                action="javascript:void(0)"
                method="post"
              >
                <div className="col-12">
                  <div className="bg-light rounded h-100 p-4">
                    <div className="d-flex justify-content-between">
                      <h6 className="mb-4">Privacy Update</h6>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-10 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            About Us
                          </label>
                          <ReactQuill
                            value={contentabout}
                            onChange={handleChangeabout}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Privacy Policy
                          </label>
                          <ReactQuill
                            value={contentprivacy}
                            onChange={handleChangeprivacy}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Information Collection
                          </label>
                          <ReactQuill
                            value={contentcollection}
                            onChange={handleChangecollection}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Cookies and Tracking Technology Information
                          </label>
                          <ReactQuill
                            value={contentcookies}
                            onChange={handleChangecookies}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Information Sharing
                          </label>
                          <ReactQuill
                            value={contentsharing}
                            onChange={handleChangesharing}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Data Security Information
                          </label>
                          <ReactQuill
                            value={contentdata}
                            onChange={handleChangedata}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Contact Information
                          </label>
                          <ReactQuill
                            value={contentcontact}
                            onChange={handleChangecontact}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Support and Availability
                          </label>
                          <ReactQuill
                            value={contentsupport}
                            onChange={handleChangesupport}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Delivery Time
                          </label>
                          <ReactQuill
                            value={contenttime}
                            onChange={handleChangetime}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Membership Termination
                          </label>
                          <ReactQuill
                            value={contentmember}
                            onChange={handleChangemember}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Right of Withdrawal
                          </label>
                          <ReactQuill
                            value={contentright}
                            onChange={handleChangeright}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Sales to Minors
                          </label>
                          <ReactQuill
                            value={contentsale}
                            onChange={handleChangesale}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                      <div className="col-10 mt-4 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Payment Solutions
                          </label>
                          <ReactQuill
                            value={contentpayment}
                            onChange={handleChangepayment}
                            style={{ height: "200px" }}
                            theme="snow" // You can also use 'bubble' for a different style
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Privacyupdate;
