import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import userImage1 from "../../assets/admin/img/user.jpg";

import { useNavigate } from "react-router-dom";
function HeaderAdmin({ isOpen }) {
  var apiUrl2 = "https://backend.amourette.no/api/profile/";
  var apiUrl3 = "https://backend.amourette.no/api/members/";
  const apiUrl = "https://backend.amourette.no/api/admin/";
  const location = useLocation();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [contentmangementShow, setcontentmangementShow] = useState(false);
  const [usersShow, setusersShow] = useState(false);
  const [securityShow, setsecurityShow] = useState(false);
  const [settingShow, setsettingShow] = useState(false);
  const [AdminLoginData, setAdminLoginData] = useState([]);
  useEffect(() => {
    const checkAndAppend = (elementType, elementAttributes) => {
      let existingElement;
      if (elementType === "link") {
        existingElement = document.querySelector(
          `link[href="${elementAttributes.href}"]`
        );
      } else if (elementType === "script") {
        existingElement = document.querySelector(
          `script[src="${elementAttributes.src}"]`
        );
      }

      if (!existingElement) {
        const element = document.createElement(elementType);
        Object.keys(elementAttributes).forEach((key) => {
          element.setAttribute(key, elementAttributes[key]);
        });
        document.head.appendChild(element);
      }
    };

    // Add stylesheets
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "/assets/admin/lib/owlcarousel/assets/owl.carousel.min.css",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "/assets/admin/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "/assets/admin/css/bootstrap.min.css",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: `/assets/admin/css/style.css?version=${new Date().getTime()}`,
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com",
    });
    checkAndAppend("link", {
      rel: "stylesheet",
      href: "https://fonts.gstatic.com",
    });

    // Add scripts
    checkAndAppend("script", {
      src: "https://code.jquery.com/jquery-3.4.1.min.js",
      async: true,
    });
    checkAndAppend("script", {
      src: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js",
      async: true,
    });
    checkAndAppend("script", {
      src: "/assets/admin/lib/chart/chart.min.js",
      async: true,
    });
    checkAndAppend("script", {
      src: "/assets/admin/lib/easing/easing.min.js",
      async: true,
    });
    checkAndAppend("script", {
      src: "/assets/admin/lib/waypoints/waypoints.min.js",
      async: true,
    });
    checkAndAppend("script", {
      src: "/assets/admin/lib/owlcarousel/owl.carousel.min.js",
      async: true,
    });

    return () => {
      // Clean up scripts and stylesheets when the component is unmounted
      const linksToRemove = [
        "https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap",
        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css",
        "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css",
        "/assets/admin/lib/owlcarousel/assets/owl.carousel.min.css",
        "/assets/admin/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css",
        "/assets/admin/css/bootstrap.min.css",
        `/assets/admin/css/style.css?version=${new Date().getTime()}`,
        "https://fonts.googleapis.com",
        "https://fonts.gstatic.com",
      ];

      linksToRemove.forEach((href) => {
        const link = document.querySelector(`link[href="${href}"]`);
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });

      const scriptsToRemove = [
        "https://code.jquery.com/jquery-3.4.1.min.js",
        "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js",
        "/assets/admin/lib/chart/chart.min.js",
        "/assets/admin/lib/easing/easing.min.js",
        "/assets/admin/lib/waypoints/waypoints.min.js",
        "/assets/admin/lib/owlcarousel/owl.carousel.min.js",
      ];

      scriptsToRemove.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script && script.parentNode) {
          script.parentNode.removeChild(script);
        }
      });
    };
  }, []);
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    const getadminLogindetail = async () => {
      let formData = {
        id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrl + "getadminLogindetail", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        setAdminLoginData(res.data.result);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getadminLogindetail();
  }, [AdminLoginData]);

  const handleContentmangement = () => {
    setcontentmangementShow(!contentmangementShow);

    //window.location.href = "/admin/media";
  };
  const handleusers = () => {
    setusersShow(!usersShow);
    //setTimeout(() => {
    // window.location.href = "/admin/users";
    //}, 100);
  };
  useEffect(() => {
    if (
      location.pathname === "/admin/media" ||
      location.pathname === "/admin/groups" ||
      location.pathname === "/admin/forum" ||
      location.pathname.startsWith("/admin/groups/viewgrouppost/")
    ) {
      setcontentmangementShow(true);
    }
    if (
      location.pathname === "/admin/users" ||
      location.pathname === "/admin/reportviolation" ||
      location.pathname.startsWith("/admin/usersprofile/") ||
      location.pathname.startsWith("/admin/usersfriendlist/") ||
      location.pathname.startsWith("/admin/userevents/") ||
      location.pathname.startsWith("/admin/usergroups/") ||
      location.pathname.startsWith("/admin/userfriendlist/") ||
      location.pathname.startsWith("/admin/usersdetails/") ||
      location.pathname.startsWith("/admin/usersentmessage/") ||
      location.pathname.startsWith("/admin/usergallery/") ||
      location.pathname.startsWith("/admin/userforum/")
    ) {
      setusersShow(true);
    }
    if (
      location.pathname === "/admin/securityprivacy" ||
      location.pathname === "/admin/privacyupdate" ||
      location.pathname.startsWith("/admin/usersentmessage/")
    ) {
      setsecurityShow(true);
    }
  }, [location]);
  const isActive =
    location.pathname === "/admin/media" ||
    location.pathname === "/admin/groups" ||
    location.pathname === "/admin/forum" ||
    location.pathname.startsWith("/admin/groups/viewgrouppost/");
  const isActiveuser =
    location.pathname === "/admin/users" ||
    location.pathname.startsWith("/admin/usersprofile/") ||
    location.pathname.startsWith("/admin/usersfriendlist/") ||
    location.pathname === "/admin/reportviolation" ||
    location.pathname.startsWith("/admin/userevents/") ||
    location.pathname.startsWith("/admin/usergroups/") ||
    location.pathname.startsWith("/admin/userfriendlist/") ||
    location.pathname.startsWith("/admin/usersdetails/") ||
    location.pathname.startsWith("/admin/usersentmessage/") ||
    location.pathname.startsWith("/admin/usergallery/") ||
    location.pathname.startsWith("/admin/userforum/");
  const handleLogout = () => {
    localStorage.removeItem("adminLogin");
    window.location.href = "/admin/login";
  };
  const handlesecurity = () => {
    //window.location.href = "/admin/securityprivacy";
    setsecurityShow(!securityShow);
  };
  const handlesetting = () => {
    setsettingShow(!settingShow);
  };
  const isActivesecurity =
    location.pathname === "/admin/securityprivacy" ||
    location.pathname === "/admin/privacyupdate";
  const isActivesetting =
    location.pathname === "/admin/setting" ||
    location.pathname === "/admin/logsactivity";
  return (
    <>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
      <div className={`sidebar pe-4 pb-3 scroll_bar ${isOpen ? "open" : ""}`}>
        <nav className="navbar bg-light navbar-light">
          <div className="d-flex flex-column gap-0 pt-3">
            <a href="/admin/dashboard" className="navbar-brand mx-4 mb-3">
              <h3 className="text-primary">ADMIN</h3>
            </a>
            <div className="d-flex align-items-center ms-4 mb-4">
              <div className="position-relative">
                <img
                  className="rounded-circle"
                  src="https://amouretteno.s3.us-east-1.amazonaws.com/chats/1736337388784_user.jpg"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
              </div>
              <div className="ms-3">
                <h6 className="mb-0">{AdminLoginData.name}</h6>
              </div>
            </div>
          </div>
          <div className="navbar-nav w-100">
            <Link
              to="/admin/dashboard"
              className={`nav-item nav-link ${
                location.pathname === "/admin/dashboard" ? "active" : ""
              }`}
            >
              <i className="fa fa-tachometer-alt me-2"></i>Dashboard
            </Link>
            {(AdminLoginData.status === "SuperAdmin" ||
              AdminLoginData.roles?.includes("Users")) && (
              <div className="nav-item dropdown">
                <Link
                  to="/admin/users"
                  className={`nav-link dropdown-toggle ${
                    isActiveuser ? "active" : ""
                  }`}
                  data-bs-toggle="dropdown"
                  onClick={handleusers}
                >
                  <i className="fa fa-users me-2"></i>Users
                </Link>
                {usersShow && (
                  <div className="dropdown-menu d-flex flex-column gap-1 bg-transparent border-0 show active">
                    <Link
                      to="/admin/users"
                      className={`dropdown-item ${
                        location.pathname === "/admin/users" ||
                        location.pathname.startsWith("/admin/usersprofile/") ||
                        location.pathname.startsWith(
                          "/admin/usersfriendlist/"
                        ) ||
                        location.pathname.startsWith("/admin/userevents/") ||
                        location.pathname.startsWith("/admin/usergroups/") ||
                        location.pathname.startsWith(
                          "/admin/userfriendlist/"
                        ) ||
                        location.pathname.startsWith("/admin/usersdetails/") ||
                        location.pathname.startsWith(
                          "/admin/usersentmessage/"
                        ) ||
                        location.pathname.startsWith("/admin/usergallery/") ||
                        location.pathname.startsWith("/admin/userforum/")
                          ? "active"
                          : ""
                      }`}
                    >
                      All Users
                    </Link>
                    <Link
                      to="/admin/reportviolation"
                      className={`dropdown-item ${
                        location.pathname === "/admin/reportviolation"
                          ? "active"
                          : ""
                      }`}
                    >
                      Report & Violation
                    </Link>
                  </div>
                )}
              </div>
            )}
            {(AdminLoginData.status === "SuperAdmin" ||
              AdminLoginData.roles?.includes("Media") ||
              AdminLoginData.roles?.includes("Groups") ||
              AdminLoginData.roles?.includes("Forum")) && (
              <div className="nav-item dropdown">
                <Link
                  to="/admin/media"
                  onClick={handleContentmangement}
                  className={`nav-link dropdown-toggle ${
                    isActive ? "active" : ""
                  }`}
                  data-bs-toggle="dropdown"
                >
                  <i className="fa fa-laptop me-2"></i>Content Management
                </Link>
                {contentmangementShow && (
                  <div className="dropdown-menu d-flex flex-column gap-1 bg-transparent border-0 show active">
                    {(AdminLoginData.status === "SuperAdmin" ||
                      AdminLoginData.roles?.includes("Media")) && (
                      <Link
                        to="/admin/media"
                        className={`dropdown-item ${
                          location.pathname === "/admin/media" ? "active" : ""
                        }`}
                      >
                        Media
                      </Link>
                    )}
                    {(AdminLoginData.status === "SuperAdmin" ||
                      AdminLoginData.roles?.includes("Groups")) && (
                      <Link
                        to="/admin/groups"
                        className={`dropdown-item ${
                          location.pathname === "/admin/groups" ||
                          location.pathname.startsWith(
                            "/admin/groups/viewgrouppost/"
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        Groups
                      </Link>
                    )}
                    {(AdminLoginData.status === "SuperAdmin" ||
                      AdminLoginData.roles?.includes("Forum")) && (
                      <Link
                        to="/admin/forum"
                        className={`dropdown-item ${
                          location.pathname === "/admin/forum" ? "active" : ""
                        }`}
                      >
                        Forum
                      </Link>
                    )}
                  </div>
                )}
              </div>
            )}
            {(AdminLoginData.status === "SuperAdmin" ||
              AdminLoginData.roles?.includes("Payment History")) && (
              <Link
                to="/admin/paymenthistory"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/paymenthistory" ? "active" : ""
                }`}
              >
                <i className="fa fa-credit-card me-2"></i> Payment History
              </Link>
            )}
            <Link
              to="/admin/locationbaseuser"
              className={`nav-item nav-link ${
                location.pathname === "/admin/locationbaseuser" ? "active" : ""
              }`}
            >
              <i className="fa fa-credit-card me-2"></i> Geographic or
              demographic
            </Link>
            {(AdminLoginData.status === "SuperAdmin" ||
              AdminLoginData.roles?.includes("Security Privacy")) && (
              <div className="nav-item dropdown">
                <Link
                  to="/admin/securityprivacy"
                  className={`nav-link dropdown-toggle ${
                    isActivesecurity ? "active" : ""
                  }`}
                  data-bs-toggle="dropdown"
                  onClick={handlesecurity}
                >
                  <i className="fas fa-lock me-2"></i>Security & Privacy
                </Link>
                {securityShow && (
                  <div className="dropdown-menu d-flex flex-column gap-1 bg-transparent border-0 show active">
                    <Link
                      to="/admin/securityprivacy"
                      className={`dropdown-item ${
                        location.pathname === "/admin/securityprivacy"
                          ? "active"
                          : ""
                      }`}
                    >
                      User Login
                    </Link>
                    <Link
                      to="/admin/privacyupdate"
                      className={`dropdown-item ${
                        location.pathname === "/admin/privacyupdate"
                          ? "active"
                          : ""
                      }`}
                    >
                      Privacy
                    </Link>
                  </div>
                )}
              </div>
            )}
            <Link
              to="/admin/sitespeed"
              className={`nav-item nav-link ${
                location.pathname === "/admin/sitespeed" ? "active" : ""
              }`}
            >
              <i className="fas fa-tachometer-alt me-2"></i> Site Speed
            </Link>
            {AdminLoginData.status === "SuperAdmin" && (
              <Link
                to="/admin/adminlist"
                className={`nav-item nav-link ${
                  location.pathname === "/admin/adminlist" ? "active" : ""
                }`}
              >
                <i className="fa fa-chart-line me-2"></i> Admin
              </Link>
            )}

            <div className="nav-item dropdown">
              <Link
                to="/admin/sendnotification"
                className={`nav-link dropdown-toggle ${
                  isActivesetting ? "active" : ""
                }`}
                data-bs-toggle="dropdown"
                onClick={handlesetting}
              >
                <i className="fas fa-cog  me-2"></i>Setting
              </Link>
              {settingShow && (
                <div className="dropdown-menu d-flex flex-column gap-1 bg-transparent border-0 show active">
                  <Link
                    to="/admin/sendnotification"
                    className={`dropdown-item ${
                      location.pathname === "/admin/sendnotification"
                        ? "active"
                        : ""
                    }`}
                  >
                    Send Notification
                  </Link>
                  <Link
                    to="/admin/logsactivity"
                    className={`dropdown-item ${
                      location.pathname === "/admin/logsactivity"
                        ? "active"
                        : ""
                    }`}
                  >
                    Logs
                  </Link>
                </div>
              )}
            </div>

            <Link to="#" onClick={handleLogout} className="nav-item nav-link">
              <i className="fa fa-sign-out-alt me-2"></i>
              Logout
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
}

export default HeaderAdmin;
