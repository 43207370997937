import React, { useState, useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import imageCompression from "browser-image-compression";

export const Createnewgroup = ({ isOpen, onClose, children, LoginData }) => {
  var apiUrl = "https://backend.amourette.no/api/groups/";
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [makeImageUse, setMakeImageUse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setspinner] = useState(false);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [fileType, setFileType] = useState("");
  const [videoUrl, setVideoUrl] = useState(""); // for resized video
  const [restr_message, setrestr_message] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  if (!isOpen) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };

  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 2 MB
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image or video
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      if (!isImage && !isVideo) {
        setErrorMessage("Please upload a valid image or video file.");
        setSelectedFile(null);
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 5 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear previous error messages
      setErrorMessage("");
      setSelectedFile(file);
      setFileType(isImage ? "image" : "video");

      if (isVideo) {
        //const resizedVideoUrl = await resizeVideo(file);
        // setVideoUrl(resizedVideoUrl);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Create a FormData object to handle file upload
    setspinner(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("image", selectedFile);
      formData.append("user_id", LoginData.id);
      formData.append("fileType", "groups");
      formData.append("makeImageUse", makeImageUse);

      const response = await axios.post(apiUrl + "groupsave", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setspinner(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(response.data.message);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        window.location.reload();
        onClose();
      }, 800);
      console.log("Event created:", response.data);
      setImage(null);
      setName("");
      setDescription("");
      setMakeImageUse(false);
      setSelectedFile(null);
      // Close the popup after submission
    } catch (error) {
      setspinner(false);
      setShowPopError(true);
      setTimeout(() => {
        setShowPopError(false);
        onClose();
      }, 800);
      setPoperrorMessage("Error creating esssvent:", error);
    }
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const handleInputChangedescription = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    // Check for restricted words
    const hasRestrictedWord = restrictedWords.some((word) =>
      inputValue.toLowerCase().includes(word.toLowerCase())
    );

    if (hasRestrictedWord) {
      setrestr_message(true);
    } else {
      setrestr_message(false);
    }
  };
  const restrictedWords = [
    "arse",
    "arsehead",
    "arsehole",
    "ass",
    "ass hole",
    "asshole",
    "bastard",
    "bitch",
    "bloody",
    "bollocks",
    "brotherfucker",
    "bugger",
    "bullshit",
    "child-fucker",
    "Christ on a bike",
    "Christ on a cracker",
    "cock",
    "cocksucker",
    "crap",
    "cunt",
    "dammit",
    "damn",
    "damned",
    "damn it",
    "dick",
    "dick-head",
    "dickhead",
    "dumb ass",
    "dumb-ass",
    "dumbass",
    "dyke",
    "faggot",
    "father-fucker",
    "fatherfucker",
    "fuck",
    "fucker",
    "fucking",
    "god dammit",
    "goddammit",
    "God damn",
    "god damn",
    "goddamn",
    "Goddamn",
    "goddamned",
    "goddamnit",
    "godsdamn",
    "holy shit",
    "horseshit",
    "in shit",
    "jackarse",
    "jack-ass",
    "jackass",
    "Jesus Christ",
    "Jesus fuck",
    "Jesus Harold Christ",
    "Jesus H. Christ",
    "Jesus, Mary and Joseph",
    "Jesus wept",
    "kike",
    "mother fucker",
    "mother-fucker",
    "motherfucker",
    "nigga",
    "nigra",
    "pigfucker",
    "piss",
    "prick",
    "pussy",
    "shit",
    "shit ass",
    "shite",
    "sibling fucker",
    "sisterfuck",
    "sisterfucker",
    "slut",
    "son of a bitch",
    "son of a whore",
    "spastic",
    "sweet Jesus",
    "twat",
    "wanker",
  ];
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        {showPopError && <ErrorPop message={errorPopMessage} />}
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="cneventup  d-flex flex-column gap-4">
            <h3>{t("Create_new_group")}</h3>
            <form
              onSubmit={handleSubmit}
              action="javascript:void(0)"
              method="post"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      required
                      name="image"
                      onChange={handleFileChange}
                      className="fileload"
                    />
                    <div className="upload_image">
                      {fileType === "image" && selectedFile && (
                        <img
                          className="w-100 h-100"
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected Image"
                        />
                      )}

                      {/* Show video preview */}
                      {fileType === "video" && selectedFile && (
                        <video className="w-100 h-100" controls>
                          <source
                            src={videoUrl || URL.createObjectURL(selectedFile)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>
                          Upload Image or Video *
                        </h3>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    placeholder={`${t("Group_name")} *`}
                    name="name"
                    required
                    value={name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    rows="5"
                    placeholder={`${t("Group_description")} *`}
                    name="description"
                    value={description}
                    onChange={handleInputChangedescription}
                  ></textarea>
                </div>
                {restr_message && (
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    Your message contains restricted words. Please remove them
                  </span>
                )}
                <div className="col-12">
                  <div className="listbox">
                    <label className="cbox">
                      {t("Make_private")}
                      <input
                        type="checkbox"
                        checked={makeImageUse}
                        onChange={(e) => setMakeImageUse(e.target.checked)}
                        name="makeImagePrivate"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {spinner && (
                    <div className="spinner-border text-success" role="status">
                      <span className="visually-hidden">{t("Loading")}...</span>
                    </div>
                  )}
                  <button type="submit">{t("Create_new_group")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
